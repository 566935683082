<svelte:options strictprops={false} />

<script>
  import { tick, getContext } from "svelte";
  import get from "lodash/get";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import { Datagrid } from "datagrid";
  import { DimText } from "dimtext";
  import { createItem as defaultItem } from "@local/lamina-core";
  import { objectify, hashify, bucketArrayMultiple, bucketArray, hashifyRanges } from "overline";

  /* Extensions */
  import eb from "src/extensions/event-bus.js";
  import makeMultiItem from "src/extensions/multi-item.js";
  import {
    sortableList,
    addToSortableList,
    removeFromSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import orderCategory from "@local/extensions/collections/order-category.js";
  import groupItems from "@local/extensions/collections/group-items.js";
  import flattenGroups from "@local/extensions/collections/flatten-groups.js";
  import countCategories from "@local/extensions/collections/count-categories.js";

  /* Components */
  import ResizablePanes from "src/lib/ResizablePanes.svelte";
  import ItemViewgrid from "src/lib/drawing/ItemViewgrid.svelte";
  import { Modal } from "svelte-utilities";
  import FlipIcon from "src/assets/icons/flip.svg";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import JobProperties from "src/lib/sidebar/JobProperties.svelte";
  import ItemProperties from "src/lib/sidebar/ItemProperties.svelte";
  import LocationThumbnailViewer from "src/lib/sidebar/LocationThumbnailViewer.svelte";
  import TypeProperties from "src/lib/sidebar/TypeProperties.svelte";
  import LayerProperties from "src/lib/sidebar/LayerProperties.svelte";
  import CollectionItemList from "src/lib/sidebar/CollectionItemList.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import SortOptions from "src/lib/SortOptions.svelte";
  import SelectedActions from "src/lib/SelectedActions.svelte";
  import NewProductModal from "src/lib/NewProductModal.svelte";

  /* Stores */
  import { user } from "src/stores/auth.js";
  import {
    selectedItems as selectedItemsObj,
    selectedType,
    showTable,
    showRightPanel,
    groupBy,
    groupByDirection,
    sortBy,
    sortByDirection,
    tableLeft,
    settingDisplayShowUnusedTypes,
    refreshItemList,
  } from "src/stores/ui.js";
  import { profile } from "src/stores/auth.js";

  export let group;
  export let types;
  export let items;
  export let collections;

  export let columns;
  export let standardColumns;
  export let customColumns;
  export let disabled = false;

  const dt = new DimText();
  const supplier = getContext("supplier");

  let confirmRemoveModal;
  let confirmRemoveTypeModal;
  let cannotRemoveModal;
  let itemsToDelete = [];
  let typesToDelete = [];
  let undeletableTypes = [];
  let selectedItemIndices = [];
  let vpContainer;
  let width;
  let height;
  let newProductModal;

  $: gb = $group?.project_type === "product" ? "category" : $groupBy;
  $: gbd = $group?.project_type === "product" ? "asc" : $groupByDirection;
  $: groupedItems = groupItems(items, gb, $group);
  $: typeObj = objectify(types);
  $: orderedCategory = orderCategory(
    gb,
    groupedItems,
    types,
    collections,
    $supplier?.product_categories,
    $settingDisplayShowUnusedTypes,
    gbd,
  );
  $: categoryCounts = countCategories(orderedCategory, groupedItems);

  $: itemList = flattenGroups(groupedItems, orderedCategory);
  $: disabledCells = cellStates($group, itemList);
  $: allComments = bucketArrayMultiple($group.comments, "item_id", "type_id");
  $: allAttachments = bucketArrayMultiple($group.attachments, "item_id", "type_id");
  $: attachments = allAttachments.none;
  $: checkSelectedItemObj($group.items, $selectedItemsObj);
  $: selectedItem = findSelectedItem($group.items, $selectedItemsObj, allAttachments, allComments);
  $: selectedItems = itemList.filter((i) => $selectedItemsObj[i.id]);
  $: selectedItemIndex = itemList.indexOf(selectedItems[0]);
  $: findSelectedIndices(itemList, $selectedItemsObj);
  $: multiItem = makeMultiItem($group, selectedItems, customColumns);
  $: prevItem = selectedItems.length === 1 && itemList[selectedItemIndex - 1];
  $: nextItem = selectedItems.length === 1 && itemList[selectedItemIndex + 1];
  $: st = getSelectedType($selectedType, allAttachments, allComments, $groupBy);
  $: warningCells = checkMarks(columns, itemList);
  $: collectionItems = bucketArray(items, "collection_id");
  $: groupKey = updateGroupKey($group);
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };

  function cellStates(grp, il) {
    if (grp.project_type === "product") {
      return il.reduce((o, i, r) => {
        const type = grp.types[i.type_id];
        set(o, [`${r}.type_id`, "disabled"], true);
        if (type?.item_template_mask) {
          Object.entries(type.item_template_mask).forEach(([k, v]) => {
            if (v) set(o, [`${r}.${k}`, "disabled"], true);
          });
        }

        return o;
      }, {});
    } else {
      return il.reduce((o, i, r) => {
        if (i.shape.type === "free") {
          set(o, [`${r}.width`, "disabled"], true);
          set(o, [`${r}.height`, "disabled"], true);
        }

        return o;
      }, {});
    }
  }

  function updateGroupKey(g) {
    return crypto.randomUUID();
  }

  function findSelectedItem(items, si, allAttachments, allComments) {
    const s = Object.keys(si)[0];
    const i = items[s];

    if (!i) return null;

    return {
      ...i,
      attachments: allAttachments[i.id] || [],
      comments: allComments[i.id] || [],
    };
  }

  function findSelectedIndices(itemList, sio) {
    const l = itemList.map((i, index) => index).filter((index) => sio[itemList[index].id]);

    if (!isEqual(l, selectedItemIndices)) {
      selectedItemIndices = l;
    }
  }

  function checkSelectedItemObj(items, sio) {
    if (!selectedItem) selectedItemsObj;

    const deselect = Object.keys(sio).filter((id) => !items[id]);
    if (deselect.length > 0) {
      selectedItemsObj.deselect(...deselect);
    }
  }

  function checkMarks(columns, list) {
    const usedMarks = {};
    const dupMarks = {};
    const markCol = columns.findIndex((c) => c.prop === "mark");
    list.forEach((item, index) => {
      if (item.shape.type !== "none") {
        if (usedMarks[item.mark] !== undefined) {
          dupMarks[index] = true;
          dupMarks[usedMarks[item.mark]] = true;
        } else {
          usedMarks[item.mark] = index;
        }
      }
    });

    return Object.keys(dupMarks).map((index) => [index, markCol, index, markCol]);
  }

  function getSelectedType(s, allAttachments, allComments, gb) {
    if (s === null) return null;

    if (gb === "type_id") {
      const typeId = orderedCategory[s];
      const type = $group.types[typeId];
      const attachments = allAttachments[typeId] || [];
      const comments = allComments[typeId] || [];

      return {
        ...type,
        attachments,
        comments,
      };
    } else if (gb === "collection_id") {
      const openingId = orderedCategory[s];
      const opening = $group.items[openingId];
      const attachments = allAttachments[openingId] || [];

      return {
        ...opening,
        attachments,
      };
    } else {
      return null;
    }
  }

  function moveColumns(evt) {
    const { columns: cols, target } = evt.detail;

    const prevOrder = columns.map((c) => c.prop);
    const l = cols.findIndex((c) => c > target);
    const shiftedTarget = l === -1 ? target - cols.length : target - l;
    const movedProps = cols.map((index) => prevOrder[index]);
    const chash = hashify(movedProps);
    const newOrder = prevOrder.filter((prop) => !chash[prop]);
    newOrder.splice(shiftedTarget, 0, ...movedProps);

    group.updateProp("data.column_order", newOrder);
  }

  async function addDefaultItem(property) {
    if (gb === "category") {
      newProductModal.open(property);
    } else {
      const item = defaultItem($group.id, $user.id, items, types, property);
      group.addItem(item);

      await tick();

      if (!selectedItems.length) {
        selectedItemsObj.selectOnly(item.id);
      }
    }
  }

  async function addProduct(e) {
    const { type_id, product } = e.detail;
    if (type_id) {
      const item = items.find((i) => i.type_id === type_id);
      if (item) {
        selectedItemsObj.selectOnly(item.id);
        group.updateItem(item.id, "quantity", (item.quantity || 0) + 1);
        return;
      }
    }

    await group.addType(product);
    const item = {
      ...defaultItem($group.id, $user.id, items, types, { type_id: product.id }),
      ...product.item_template,
    };
    if (item.shape.type === "none") {
      item.mark = null;
    }
    group.addItem(item);
  }

  function addItem(evt) {
    const newItem = {
      ...defaultItem($group.id, $user.id, items, types),
      ...evt.detail,
    };

    group.addItem(newItem);
    selectedItemsObj.selectOnly(newItem.id);
  }

  function addColumn(evt) {
    const name = evt.detail;

    const data = cloneDeep($group.data);
    const cc = get(data, "custom_columns") || sortableList([]);

    addToSortableList(cc, {
      id: crypto.randomUUID(),
      name,
      type: "text",
    });

    data.custom_columns = cc;
    group.updateProp("data", data);
  }

  function renameColumn(evt) {
    const { name, index } = evt.detail;
    const col = columns[index];

    const data = cloneDeep($group.data);
    set(data, `custom_columns.${col.id}.name`, name);

    group.updateProp("data", data);
  }

  function deleteColumn(evt) {
    const index = evt.detail;
    const col = columns[index];

    const data = cloneDeep($group.data);
    removeFromSortableList(data.custom_columns, col.id);

    // Delete data from each item
    const itemUpdates = items.map((item) => {
      const ccd = cloneDeep(item.custom_column_data) || {};
      if (ccd[col.id] !== undefined) {
        delete ccd[col.id];
      }

      return { type: "item", id: item.id, prop: "custom_column_data", value: ccd };
    });

    group.update([...itemUpdates, { type: "group", id: $group.id, prop: "data", value: data }]);
  }

  function updateItem(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateItem(id, prop, value, diff);
  }

  function updatedWidth(item, offset) {
    const pf = new Polyface(item, $group.data.fabrications);
    const bbox = pf.bbox;
    const w = bbox.width - offset.toNumber("inches");
    return dt.parse(w.toString()).value;
  }

  function updatedHeight(item, offset) {
    const pf = new Polyface(item, $group.data.fabrications);
    const bbox = pf.bbox;
    const h = bbox.height - offset.toNumber("inches");
    return dt.parse(h.toString()).value;
  }

  function updateDgValue(evt) {
    const { updates } = evt.detail;

    // Group updates for each item
    const iobj = updates.reduce((u, update) => {
      const id = itemList[update.row]?.id;
      if (id) {
        const item = itemList[update.row];
        if (u[id]) {
          u[id][update.prop] = update.value;
        } else {
          u[id] = {
            [update.prop]: update.value,
          };
        }

        if (update.prop === "width_offset" && item.shape.type === "free") {
          u[id].width = updatedWidth(item, update.value);
        }

        if (update.prop === "height_offset" && item.shape.type === "free") {
          u[id].height = updatedHeight(item, update.value);
        }
      }
      return u;
    }, {});

    const u = Object.keys(iobj).map((id) => ({ type: "item", id, diff: iobj[id] }));
    group.update(u);
  }

  function updateMultiItem(evt) {
    const { prop, value, diff } = evt.detail;
    const ids = selectedItems.map((i) => i.id);
    group.updateMultipleItems(ids, prop, value, diff);
  }

  async function resort(evt) {
    const selectId = evt.detail;
    group.react();

    if (selectId) {
      selectedItemsObj.selectOnly(selectId);
    }
  }

  function removeItems() {
    $selectedItemsObj = {};
    group.removeItem(...itemsToDelete);
  }

  async function selectItem(evt) {
    const { selected } = evt.detail;

    selectedItemsObj.selectOnly(...selected);
    if (selected.length === 1) {
      eb.dispatch("scrollto", selected[0]);
    }
  }

  async function updateSelection(evt) {
    const { selectedRows, selectedCols, selected } = evt.detail;
    if (selectedCols.length > 0) {
      selectedItemsObj.select(...itemList.map((i) => i.id));
    } else if (selectedRows.length > 0) {
      const indices = hashifyRanges(selectedRows);
      const ids = Object.keys(indices)
        .filter((i) => itemList[i])
        .map((i) => itemList[i].id);
      selectedItemsObj.selectOnly(...ids);

      if (ids.length === 1) {
        eb.dispatch("scrollto", ids[0]);
      }
    } else {
      const rows = selected.map((s) => [s[0], s[2]]);
      const indices = hashifyRanges(rows);
      const ids = Object.keys(indices)
        .filter((i) => itemList[i])
        .map((i) => itemList[i].id);
      selectedItemsObj.selectOnly(...ids);

      if (ids.length === 1) {
        eb.dispatch("scrollto", ids[0]);
      }
    }
  }

  function confirmRemove() {
    itemsToDelete = selectedItems.map((i) => i.id);
    confirmRemoveModal.open();
  }

  function confirmRemoveType() {
    typesToDelete = [st?.id].filter((id) => $group.types[id]);
    if (!typesToDelete.length) return;
    const ts = typesToDelete.map((id) => $group.types[id]);

    const usedTypes = items.reduce((u, i) => {
      u[i.type_id] = true;
      return u;
    }, {});

    undeletableTypes = ts.filter((t) => !!usedTypes[t.id]);

    if (undeletableTypes.length > 0) {
      cannotRemoveModal.open();
    } else {
      confirmRemoveTypeModal.open();
    }
  }

  function removeTypes() {
    $selectedType = null;
    const toDelete = [...typesToDelete];
    typesToDelete = [];
    group.removeType(...toDelete);
  }

  function selectNone() {
    $selectedType = null;
    selectItem({ detail: { selected: [] } });
  }

  function updateType(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateType(id, prop, value, diff);
  }

  function gotoPrev() {
    if (prevItem) selectedItemsObj.selectOnly(prevItem.id);
  }

  function gotoNext() {
    if (nextItem) selectedItemsObj.selectOnly(nextItem.id);
  }

  async function cloneItems(itemsToClone) {
    const marks = items.map((i) => i.mark);

    const cloned = itemsToClone.map((i) => ({
      ...i,
      id: crypto.randomUUID(),
      created_by: $profile.id,
      created_at: new Date(),
      mark: incrementMarkCopy(i.mark, marks),
    }));

    await group.addItem(...cloned);

    selectedItemsObj.selectOnly(...cloned.map((i) => i.id));
  }

  async function cloneType(typeId) {
    const type = $group.types[typeId];
    if (!type) return;

    const id = crypto.randomUUID();

    await group.addType({
      ...type,
      id,
      created_at: new Date(),
      updated_at: new Date(),
      mark: incrementMarkCopy(
        type.mark,
        types.map((t) => t.mark),
      ),
    });
  }

  function toggleSubcolVis(e) {
    const { id, value } = e.detail;
    group.updateProp(`data.settings.hide_${id}_offset`, value);
  }
</script>

<div class="w-full h-full" bind:offsetWidth={width} bind:offsetHeight={height}>
  <ResizablePanes
    startLeft={850}
    startBottom={300}
    showPane={$showTable && width >= 640 && height >= 480}
    direction={$tableLeft ? "horizontal" : "vertical"}>
    <div slot="content" class="bg-gray-100 w-full h-full flex">
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div class="flex-grow relative" on:click={selectNone} bind:this={vpContainer} on:keydown={() => null}>
        {#if $selectedType !== null}
          <SelectedActions
            cloneable={!disabled}
            deletable={!disabled}
            selected={1}
            on:delete={confirmRemoveType}
            on:clone={() => cloneType(st.id)} />
        {:else if selectedItems.length > 0}
          <SelectedActions
            cloneable={!disabled && $group?.project_type !== "product"}
            deletable={!disabled}
            selected={selectedItems.length}
            on:delete={confirmRemove}
            on:clone={() => cloneItems(selectedItems)} />
        {/if}
        <div class="absolute w-full flex items-center justify-end pr-6 pt-4 z-20">
          <SortOptions
            {group}
            {customColumns}
            {standardColumns}
            {groupBy}
            {groupByDirection}
            {sortBy}
            {sortByDirection} />
        </div>
        <div class="h-full w-full overflow-auto pt-6">
          {#key $refreshItemList}
            <ItemViewgrid
              {group}
              {items}
              {itemList}
              {orderedCategory}
              {groupedItems}
              {disabled}
              container={vpContainer}
              itemAttachments={allAttachments}
              itemComments={allComments}
              {typeObj}
              groupBy={gb}
              on:newItem={(evt) => addDefaultItem(evt.detail)} />
          {/key}
        </div>
      </div>
      {#if $showRightPanel && width >= 640}
        <Sidebar>
          <svelte:fragment slot="header">
            {#if selectedItems.length === 1 && selectedItem}
              <PrevNext
                prev={prevItem}
                next={nextItem}
                on:gotoprev={gotoPrev}
                on:gotonext={gotoNext}
                sticky
                title={selectedItem.mark} />
            {/if}
          </svelte:fragment>
          <svelte:fragment slot="content">
            <!-- A type is selected -->
            {#if $selectedType !== null}
              {#if $groupBy === "type_id"}
                {#key st.id}
                  <SidebarTitle title={`${st.mark}${st.name && ` - ${st.name}`}`} />
                  <TypeProperties
                    {disabled}
                    type={st}
                    on:updateType={updateType}
                    on:updateSupplier
                    settings={typeSettings} />
                  {#if st?.data?.layers}
                    <LayerProperties type={st} {group} {disabled} />
                  {/if}
                  <LocationThumbnailViewer
                    recordid={st.id}
                    {group}
                    record={st}
                    {disabled}
                    documents={$group.data.documents} />
                  <Attachments attachments={st.attachments} {group} typeid={st.id} {disabled} />
                  <History
                    updatedBy={st.updater}
                    updatedOn={st.updated_at}
                    approvedBy={st.approver}
                    approvalStatus={st.approval_status}
                    approvedOn={st.approval_status_updated_at} />
                {/key}
              {:else if $groupBy === "collection_id"}
                {#key st.id}
                  <SidebarTitle title={st.mark} />
                  <ItemProperties
                    {group}
                    itemid={st.id}
                    {disabled}
                    item={st}
                    {collections}
                    {types}
                    {standardColumns}
                    settings={$group.data.settings}
                    isCollection
                    on:updateItem={updateItem} />
                  <CollectionItemList
                    {group}
                    {items}
                    collectionId={st.id}
                    collectionItems={collectionItems[st.id]}
                    {disabled} />
                  <Attachments attachments={st.attachments || []} {group} itemid={st.id} {disabled} />
                  <History
                    updatedBy={st.updater}
                    updatedOn={st.updated_at}
                    approvedBy={st.approver}
                    approvalStatus={st.approval_status}
                    approvedOn={st.approval_status_updated_at} />
                {/key}
              {/if}

              <!-- No items selected, show Group properties -->
            {:else if selectedItems.length === 0}
              <SidebarTitle title="Job Properties" />
              <JobProperties {disabled} {group} />
              <Attachments {attachments} {group} {disabled} />
              <History updatedBy={$group.updater} updatedOn={$group.updated_at} />

              <!-- One item selected, show that item's properties -->
            {:else if selectedItems.length === 1 && selectedItem}
              {#key selectedItem.id}
                <ItemProperties
                  {group}
                  itemid={selectedItem.id}
                  {disabled}
                  item={selectedItem}
                  {collections}
                  {customColumns}
                  {types}
                  {standardColumns}
                  settings={$group.data.settings}
                  on:updateItem={updateItem} />
                <LocationThumbnailViewer
                  recordid={selectedItem.id}
                  {group}
                  record={selectedItem}
                  {disabled}
                  documents={$group.data.documents} />
                <Attachments
                  attachments={selectedItem.attachments}
                  {group}
                  itemid={selectedItem.id}
                  {disabled} />
                <History
                  updatedBy={selectedItem.updater}
                  updatedOn={selectedItem.updated_at}
                  approvedBy={selectedItem.approver}
                  approvalStatus={selectedItem.approval_status}
                  approvedOn={selectedItem.approval_status_updated_at} />
              {/key}

              <!-- Multiple selected, allow multi-edit -->
            {:else}
              <SidebarTitle title="Multiple Items Selected" />
              <ItemProperties
                {group}
                {disabled}
                item={multiItem}
                {collections}
                {customColumns}
                {types}
                {standardColumns}
                settings={$group.data.settings}
                multiple
                on:updateItem={updateMultiItem} />
            {/if}
          </svelte:fragment>
        </Sidebar>
      {/if}
    </div>

    <div slot="pane" class="bg-white w-full h-full flex flex-col" class:px-6={$tableLeft}>
      <div class="flex-none">
        <button class="p-1 hover:bg-gray-100 rounded" on:click={() => ($tableLeft = !$tableLeft)}>
          <FlipIcon />
        </button>
      </div>
      <Datagrid
        data={itemList}
        cells={disabledCells}
        delegate
        reorderable={!disabled}
        {columns}
        divisions={categoryCounts}
        selectedRows={selectedItemIndices}
        {warningCells}
        emptyRow={$group.project_type !== "product"}
        columnAddable={!disabled}
        darkCols
        on:moveColumns={moveColumns}
        on:toggleSubcolVis={toggleSubcolVis}
        on:updateSelection={updateSelection}
        on:updateValues={updateDgValue}
        on:addRow={addItem}
        on:addColumn={addColumn}
        on:renameColumn={renameColumn}
        on:deleteColumn={deleteColumn} />
    </div>
  </ResizablePanes>
</div>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeItems}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Items</div>
  <div slot="content">
    Are you sure you want to delete {itemsToDelete.length}
    item{itemsToDelete.length > 1 ? "s" : ""}?
  </div>
</Modal>

<Modal
  bind:this={confirmRemoveTypeModal}
  on:confirm={removeTypes}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Types</div>
  <div slot="content">
    <div class="mb-2">Are you sure you want to delete this type?</div>
    {#each typesToDelete as id}
      {@const type = $group.types[id]}
      <div>{type.mark}{type.name && ` - ${type.name}`}</div>
    {/each}
  </div>
</Modal>

<Modal bind:this={cannotRemoveModal} buttons={[{ label: "Cancel", type: "cancel" }]}>
  <div slot="title">Delete Types</div>
  <div slot="content">
    <div class="mb-2">
      The following item(s) are currently assigned to this type. Please assign items a different type before
      deleting:
    </div>
    {#each groupedItems?.[st.id] as item}
      <div>{item.mark}</div>
    {/each}
  </div>
</Modal>

<NewProductModal bind:this={newProductModal} {group} {types} on:updateSupplier on:addProduct={addProduct} />
