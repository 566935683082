<svelte:options strictprops={false} />

<script>
  import cloneDeep from "lodash/cloneDeep";
  import get from "lodash/get";
  import range from "lodash/range";
  import uniq from "lodash/uniq";
  import { add, subtract, multiply, midpoint, distSq } from "vector";
  import { onMount, tick } from "svelte";
  import { Drawing } from "drawing";
  import { EventBus } from "overline";
  import { OpenSeadragon } from "docviewer";
  import { Link, navigate } from "svelte-routing";

  import CaretLeftIcon from "../assets/icons/caret-left.svg";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import SelectedActions from "src/lib/SelectedActions.svelte";

  import { Modal } from "svelte-utilities";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import DocumentProperties from "src/lib/sidebar/DocumentProperties.svelte";
  import LocationProperties from "src/lib/sidebar/LocationProperties.svelte";
  import DocLocationProperties from "src/lib/sidebar/DocLocationProperties.svelte";
  import {
    showRightPanel,
    currentTool,
    selectedLocations,
    selectedAnnotations,
    currentDocid,
    clipboard,
  } from "src/stores/ui.js";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { user } from "src/stores/auth.js";
  import markColor from "@local/extensions/drawing/mark-color.js";
  import clipRectangle from "@local/extensions/drawing/clip-rectangle.js";

  import checkDocumentForUpdates from "src/extensions/check-document-for-updates.js";
  import { createItem as defaultItem, createType as defaultType, itemFromVertices } from "@local/lamina-core";
  import copyToClipboard from "src/extensions/copy-to-clipboard.js";
  import drawingSymbol from "@local/extensions/drawing/drawing-symbol.js";
  import eb from "src/extensions/event-bus.js";
  import nearColors from "src/extensions/near-colors.js";
  import { objectify, bucketArray } from "overline";
  import { nextMark as nextMarkFromList } from "@local/extensions/identifiers/mark.js";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import imgClipToBlob from "src/extensions/dom/img-clip-to-blob.js";
  import {
    sheetScaleFactor,
    transformSheetPoint,
  } from "@local/extensions/geometry/transform-sheet-points.js";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import { api } from "src/api";
  import { TRIOPS_URL as triopsUrl } from "src/env";

  export let group;
  export let docid;
  export let items;
  export let collections;
  export let types;
  export let disabled;
  export let customColumns;
  export let customColColumns;
  export let standardColumns;
  export let queryParams;

  const CLIP_THRESHOLD = 600;
  const controller = new EventBus();
  const dpi = window.devicePixelRatio;
  let loaded = false;

  const markShapes = {
    item: "hexagon",
    collection: "rectangle",
    type: "circle",
  };

  const pdfUnits = {
    "'": "feet",
    '"': "inches",
    "''": "inches",
    ft: "feet",
    in: "inches",
    cm: "centimeters",
    m: "meters",
    mm: "millimeters",
  };

  let viewer;
  let adding = null;
  let confirmRemoveModal;
  let locationsToDelete = [];
  let tileList = [];
  let annotations = [];
  let hoveredFile = null;
  let hoveredAnnotation = null;

  let poller;

  let width;
  let height;

  let showItems = true;
  let showCollections = true;
  let showTypes = true;
  let hitHashes = {};
  let currentHb = null;
  let locationPreview = null;
  let pannable = $currentTool === "pan";
  let tempTool = null;
  let dragStart = null;
  let dragging = null;
  let selecting = null;
  let clipping = null;
  let hoveredLocations = {};
  let selectedPage = null;
  let cursor = "default";

  $: doc = $group.data.documents[docid];
  $: initialPage = queryParams?.["doc-page"];
  $: {
    doc;
    setup();
  }
  $: pageIndices = getPageIndices(doc);
  $: filePages = getFilePages(doc);
  $: pages = doc.files.reduce((p, f) => p + (f.pages || 0), 0);
  $: progress = getProgress(doc);
  $: sidebarPosition = getSidebarPosition(width, height);
  $: locations = doc?.locations || [];
  $: documents = orderedList($group.data.documents);
  $: docIndex = documents.findIndex((f) => f.id === docid);
  $: nextDoc = documents[docIndex + 1];
  $: prevDoc = documents[docIndex - 1];
  $: locationsObj = objectify(locations);
  $: annosObj = objectify(annotations);
  $: checkSelected(docid);
  $: indexedLocations = indexLocations(locations, $group, items);
  $: locationsByRecord = bucketArray(locations, "record_id");
  $: collectionItems = bucketArray(items, "collection_id");
  $: nextMark = getNextMark(adding, $group, items, collections, types);
  $: selectedLocationList = Object.keys($selectedLocations)
    .map((id) => locationsObj[id])
    .filter((loc) => loc);
  $: selectedApprovalStatus = getSelectedApprovalStatus(selectedLocationList, $group);
  $: checkCurrentTool($currentTool);
  $: overlayDwgs =
    loaded &&
    makeOverlayDwgs(
      indexedLocations,
      $selectedLocations,
      $selectedAnnotations,
      hoveredLocations,
      hoveredAnnotation,
      locationPreview,
      dragging,
      annosObj,
    );
  $: marquee = makeMarqueeDwg(selecting, clipping);
  $: draggedMark = makeDraggedMark(dragging);
  $: {
    docid;
    overlayDwgs;
    marquee;
    draggedMark;
    hoveredFile;
    hoveredAnnotation;
    if (viewer && viewer.redraw) {
      viewer.redraw();
    }
  }

  async function setupPoll(document) {
    if (poller) {
      clearInterval(poller);
    }

    if (!document) return;
    if (document.ready) return;

    const { update, document: d } = await checkDocumentForUpdates(document);
    if (update) {
      const docs = cloneDeep($group.data.documents);
      docs[document.id] = d;
      group.updateProp("data.documents", docs);
      if (d.ready) return;
    }

    if (poller) clearInterval(poller);
    poller = setInterval(poll, 5000);
  }

  function getPageIndices(doc) {
    const indices = {};

    let page = 0;
    doc.files.forEach((file) => {
      if (file.pages) {
        const f = {};

        for (let i = 0; i < file.pages; i++) {
          f[i] = page;
          page++;
        }

        indices[file.object_id] = f;
      }
    });

    return indices;
  }

  function getFilePages(doc) {
    const idx = {};

    let page = 0;
    doc.files.forEach((file) => {
      if (file.pages) {
        for (let i = 0; i < file.pages; i++) {
          idx[page] = {
            file_path: file.object_id,
            file_page: i,
          };

          page++;
        }
      }
    });

    return idx;
  }

  function clipVertex(clip, index) {
    if (index === "0") return { x: clip.x, y: clip.y + clip.height };
    if (index === "1") return { x: clip.x + clip.width, y: clip.y + clip.height };
    if (index === "2") return { x: clip.x + clip.width, y: clip.y };
    return { x: clip.x, y: clip.y };
  }

  function draggedClip(clip, index, to) {
    const vertices = [
      { x: clip.x, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y },
      { x: clip.x, y: clip.y },
    ];
    const i = parseInt(index);

    const opp = vertices[(i + 2) % 4];
    const minX = Math.min(to.x, opp.x);
    const minY = Math.min(to.y, opp.y);
    const maxX = Math.max(to.x, opp.x);
    const maxY = Math.max(to.y, opp.y);

    return {
      x: minX,
      y: minY,
      width: Math.max(maxX - minX, 10),
      height: Math.max(maxY - minY, 10),
    };
  }

  async function updateTileList() {
    const tiles = doc.files.reduce((list, file, fileIndex) => {
      if (file.content_type === "application/pdf" && file.pages) {
        const filePages = range(0, file.pages).map((index) => {
          const nme = nameMinusExtension(file.object_id);
          const path = encodeURIComponent(nme);
          const url = `${triopsUrl}/iiif/2/drawings/${path}`;
          return {
            type: "iiif",
            state: file.status[index]?.state === "completed" ? "completed" : "loading",
            url,
            page: index,
            fileIndex,
            fileId: file.id,
            filePath: file.object_id,
          };
        });
        list.push(...filePages);
      } else if (["image/jpeg", "image/png", "image/gif"].includes(file.content_type)) {
        list.push(
          api.storage
            .from("documents")
            .createSignedUrl(file.object_id, 60 * 60)
            .then(({ data }) => {
              return {
                type: "image",
                url: data?.signedUrl,
                dimensions: file.dimensions,
                state: "completed",
                filePath: file.object_id,
                fileId: file.id,
                fileIndex,
                page: 0,
              };
            }),
        );
      }

      return list;
    }, []);

    tileList = await Promise.all(tiles);
  }

  async function updateAnnotations() {
    if (!doc) {
      annotations = [];
      return;
    }

    const pdfs = doc.files.filter((f) => f.content_type === "application/pdf" && f.pages);
    const pdfIds = pdfs.map((f) => f.object_id);
    const pi = pdfs.reduce((obj, file) => {
      const indices = range(0, file.pages).filter((i) => file.status[i]?.state === "completed");
      obj[file.object_id] = indices;
      return obj;
    }, {});

    const polys = await api
      .from("dng_polygon_notes")
      .select("*,sheet:dng_sheets(limits::jsonb,rotation)")
      .eq("document_bucket_id", "documents")
      .in("document_name", pdfIds);

    if (polys.error) {
      annotations = [];
      return;
    }

    annotations = polys.data
      .filter((a) => a.type === "/PolygonDimension")
      .filter((a) => a.measure_scale !== null)
      .filter((a) => {
        const indices = pi[a.document_name];
        return indices?.includes(a.sheet_idx);
      })
      .map((a) => ({
        ...a,
        page: pageIndices[a.document_name]?.[a.sheet_idx],
        item: itemFromVertices(a.scaled_vertices, pdfUnits[a.measure_unit], a.sheet?.rotation),
      }))
      .sort((a, b) => {
        if (a.page !== b.page) {
          return a.page - b.page;
        }
        return a.id - b.id;
      });
  }

  async function setup() {
    await setupPoll(doc);
    await updateTileList();
    await updateAnnotations();
  }

  async function finish() {
    loaded = true;
  }

  function checkSelected(docid) {
    selectedPage = null;

    if ($currentDocid && $currentDocid !== docid) {
      $selectedLocations = {};
      $selectedAnnotations = {};
    }

    if (docid) {
      $currentDocid = docid;
    }

    const toDeselect = Object.keys($selectedLocations).filter((id) => !locRecord(locationsObj[id]));
    if (toDeselect.length) {
      selectedLocations.deselect(...toDeselect);
    }
  }

  function getProgress(document) {
    return (progress = document.files.reduce((p, f) => {
      const max = 1 / document.files.length;
      if (f.ready) {
        return p + max;
      }

      if (f.pages) {
        const r = range(0, f.pages);
        const completed = r.filter((i) => f.status[i]?.state === "completed");
        const q = completed.length / r.length;
        return p + max * q;
      }

      return p;
    }, 0));
  }

  async function poll() {
    try {
      const { update, document } = await checkDocumentForUpdates(doc);

      if (update) {
        const docs = cloneDeep($group.data.documents);
        docs[document.id] = document;
        group.updateProp("data.documents", docs);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function getSelectedApprovalStatus(list, grp) {
    return list.reduce((status, i) => {
      let record;
      if (i.record_type === "type") {
        record = grp.types[i.record_id];
      } else {
        record = grp.items[i.record_id];
      }

      if (!record) return status;
      if (status === null) return record.approval_status;
      if (record.approval_status === status) return status;
      return "Mixed";
    }, null);
  }

  function getNextMark(adding, grp, items, collections, types) {
    if (!adding?.clone) return null;
    let record;
    let marks = [];

    if (adding.record_type === "type") {
      record = grp.types[adding.record_id];
      marks = types.map((t) => t.mark);
    } else if (adding.record_type === "item") {
      record = grp.items[adding.record_id];
      marks = items.map((i) => i.mark);
    } else if (adding.record_type === "collection") {
      record = grp.items[adding.record_id];
      marks = collections.map((c) => c.mark);
    }

    if (!record) return null;

    return incrementMarkCopy(record.mark, marks);
  }

  function getSidebarPosition(width, height) {
    if (width >= 640) {
      return "right";
    }

    return height > width ? "bottom" : "right";
  }

  function checkCurrentTool(ct) {
    if (!["location-item", "location-type", "location-collection"].includes(ct) && !tempTool) {
      adding = null;
      locationPreview = null;
    }

    pannable = ct === "pan";
  }

  function indexLocations(locations, grp, items) {
    const indexed = range(0, pages).reduce((o, i) => {
      o[i] = [];
      return o;
    }, {});

    locations.forEach((location) => {
      const index = pageIndices[location.position.file_path]?.[location.position.file_page];
      if (indexed[index]) {
        if (location.record_type === "type" && grp.types[location.record_id] && showTypes) {
          indexed[index].push(location);
        } else if (location.record_type === "item" && grp.items[location.record_id] && showItems) {
          indexed[index].push(location);
        } else if (
          location.record_type === "collection" &&
          grp.items[location.record_id] &&
          showCollections
        ) {
          indexed[index].push(location);
        }
      }
    });

    return indexed;
  }

  function recordMark(location, record) {
    if (location.record_type !== "type") {
      const labelBy = record.is_collection
        ? $group.data.settings.collections_label_by
        : $group.data.settings.label_by;

      return labelBy ? get(record, labelBy) : record.mark;
    } else {
      return record.mark;
    }
  }

  function locRecord(location) {
    if (!location) return null;
    return location.record_type === "type"
      ? $group.types[location.record_id]
      : $group.items[location.record_id];
  }

  function annotationMark(annotation, style, name = null) {
    const pt = annotation.own_label_point;
    const tile = viewer.tile(annotation.page);
    if (!tile) return null;
    const size = tile.getContentSize();

    const initPt = {
      x: pt[0] - annotation.sheet.limits[0][0],
      y: pt[1] - annotation.sheet.limits[0][1],
    };

    const ssf = sheetScaleFactor(annotation.sheet.limits, annotation.sheet.rotation, size);
    const pos = transformSheetPoint(initPt, annotation.sheet.rotation, ssf, size);
    const p = { x: pos.x, y: -pos.y };

    const mk = new Drawing().mark(p, "✓", { shape: "hexagon" }).style(style);
    if (name) {
      return mk.name(name);
    }

    return mk;
  }

  function makeOverlayDwgs(indexedLocations, sl, sa, hl, ha, lp, dragging, annosObj) {
    return Object.entries(indexedLocations).reduce((dwgs, [index, locations]) => {
      const marks = locations
        .filter((loc) => loc)
        .map((loc) => {
          const record = locRecord(loc);
          const highlighted = hl?.[loc.id] || sl?.[loc.id];
          const pos = { x: loc.position.x, y: -loc.position.y };
          let color;
          if (dragging && dragging.selected?.[loc.id]) {
            color = { fill: "#FFFFFF", textColor: "#FFFFFF", stroke: "#AAAAAA" };
          } else {
            color = markColor(record, { highlighted, emphasized: true });
          }

          const mark = recordMark(loc, record);

          if (sl[loc.id]) {
            if (dragging?.type === "clip-outline") {
              const lindex = pageIndices[loc.position.file_path]?.[loc.position.file_page];
              const tc = viewer.tileCoords(subtract(dragging.end, dragging.delta), lindex);

              const minX = loc.position.x - loc.clip.x;
              const minY = loc.position.y - loc.clip.y;
              const maxX = tc.width - (loc.clip.x + loc.clip.width - loc.position.x);
              const maxY = tc.height - (loc.clip.y + loc.clip.height - loc.position.y);
              const x = Math.max(minX, Math.min(tc.x, maxX));
              const y = Math.max(minY, Math.min(tc.y, maxY));
              const delta = subtract({ x, y }, loc.position);

              let npos;
              let clip;

              npos = { x, y: -y };
              clip = clipRectangle(
                {
                  x: loc.clip.x + delta.x,
                  y: loc.clip.y + delta.y,
                  width: loc.clip.width,
                  height: loc.clip.height,
                },
                loc.id,
              );

              const mk = new Drawing()
                .mark(npos, mark, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              return new Drawing().add(clip, mk);
            } else if (dragging?.type === "clip-vertex") {
              const mk = new Drawing()
                .mark(pos, mark, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              let clip;
              const npos = subtract(dragging.end, dragging.delta);
              const lindex = pageIndices[loc.position.file_path]?.[loc.position.file_page];
              const tc = viewer.tileCoords(npos, lindex);
              const x = Math.max(0, Math.min(tc.x, tc.width));
              const y = Math.max(0, Math.min(tc.y, tc.height));
              const to = { x, y };
              const d = draggedClip(loc.clip, dragging.index, to);
              clip = clipRectangle(d, loc.id);

              return new Drawing().add(clip, mk);
            } else {
              const mk = new Drawing()
                .mark(pos, mark, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              let clip;
              let dwgBtn;

              if (loc.clip && loc.record_type !== "type") {
                clip = clipRectangle(loc.clip, loc.id);
                const h = record.drawing && record.drawing.attachment_id === loc.id;
                dwgBtn = drawingSymbol(
                  loc.id,
                  {
                    x: loc.clip.x + loc.clip.width,
                    y: -loc.clip.y - loc.clip.height,
                  },
                  h,
                );
              }

              return new Drawing().add(clip, mk, dwgBtn);
            }
          } else {
            return new Drawing()
              .mark(pos, mark, {
                shape: markShapes[loc.record_type],
              })
              .style({ ...color, lineWidth: 2 })
              .name(`location_${loc.id}`);
          }
        });

      const samarks = Object.keys(sa)
        .map((id) => annosObj[id])
        .filter((a) => a && a.page === parseInt(index))
        .map((a) => annotationMark(a, { fill: "#F7F7B7", lineWidth: 2 }, `annotation_${a.id}`));

      let lpm;
      if (lp?.index === parseInt(index)) {
        let mark = "";
        let style;
        if (adding?.record_id) {
          const record = locRecord(adding);

          if (adding.clone) {
            const labelBy = record?.is_collection
              ? $group.data.settings.collections_label_by
              : $group.data.settings.label_by;

            if (!labelBy || labelBy === "mark") {
              mark = nextMark;
            } else {
              mark = get(record, labelBy);
            }
          } else {
            mark = record ? recordMark(adding, record) : "";
          }
          style = { ...markColor(record, { emphasized: true }), lineWidth: 2 };
        } else {
          if (adding?.record_type === "type") {
            mark = nextMarkFromList(types);
          } else if (adding?.record_type === "collection") {
            mark = nextMarkFromList(collections);
          } else if (adding?.record_type === "item") {
            mark = nextMarkFromList(items);
          }

          style = { fill: "#f59e0b", lineWidth: 1 };
        }
        const pos = { x: lp.position.x, y: -lp.position.y };

        lpm = new Drawing().mark(pos, mark, { shape: markShapes[lp.type] }).style(style);
      }

      let ham;
      if (ha) {
        const annotation = annosObj[ha];
        if (annotation.page === parseInt(index)) {
          ham = annotationMark(
            annotation,
            { fill: "#000000", lineWidth: 2, textColor: "#FFFFFF" },
            `annotation_${ha}`,
          );
        }
      }

      const dwg = new Drawing().add(...marks, ...samarks, lpm, ham);

      dwgs[index] = dwg;
      return dwgs;
    }, {});
  }

  function makeMarqueeDwg(selecting, clipping) {
    let start;
    let end;
    let style;
    if (selecting) {
      start = selecting.start;
      end = selecting.end;
      style = { fill: "transparent", stroke: "black" };
    } else if (clipping) {
      start = clipping.start;
      end = clipping.end;
      style = { stroke: "#0066E5", fill: "transparent", lineWidth: 2 };
    } else {
      return null;
    }

    const x = Math.min(start.x, end.x) * dpi;
    const y = Math.min(start.y, end.y) * dpi;
    const w = Math.abs(start.x - end.x) * dpi;
    const h = Math.abs(start.y - end.y) * dpi;

    return new Drawing().rectangle(x, -y, w, -h).style(style);
  }

  function makeDraggedMark(dragging) {
    if (dragging?.type !== "location") return null;
    if (!dragging.location) return null;
    const { start, end, selected } = dragging;

    const marks = Object.keys(selected)
      .filter((locid) => locationsObj[locid])
      .map((locid) => {
        const loc = locationsObj[locid];
        const record = locRecord(loc);
        const mark = recordMark(loc, record);
        const index = pageIndices[loc.position.file_path]?.[loc.position.file_page];
        const pos = viewer.pixelCoords({ ...loc.position, index });
        if (!pos) return null;
        const delta = subtract(end, start);
        const newPos = add(pos, delta);
        const newTc = viewer.tileCoords(newPos);
        const isViable = newTc.index >= 0;
        const color = isViable
          ? markColor(record, { emphasized: true })
          : { fill: "#FFFFFF", textColor: "#FFFFFF", stroke: "#AAAAAA" };

        const scaledPos = multiply(newPos, { x: dpi, y: -dpi });
        return new Drawing().mark(scaledPos, mark, { shape: markShapes[loc.record_type] }).style(color);
      });

    return new Drawing().add(...marks);
  }

  function zoomIn() {
    controller.dispatch("zoom-in");
  }

  function zoomOut() {
    controller.dispatch("zoom-out");
  }

  function zoomToFit() {
    controller.dispatch("home");
  }

  function tileOverlay(options) {
    const dwg = overlayDwgs?.[options.index];
    if (dwg) {
      dwg.render({ ctx: options.context, annoScale: 1 / options.zoom });
      hitHashes[options.index] = dwg.renderHitbox({ ctx: options.hitContext, annoScale: 1 / options.zoom });
    } else {
      hitHashes[options.index] = null;
    }

    const t = tileList[options.index];
    if (!t || !viewer) return;
    if (options.index === selectedPage?.index || hoveredFile === t.fileId) {
      const tile = viewer.tile(options.index);
      const size = tile.getContentSize();
      const dwg = new Drawing()
        .rectangle(0, 0, size.x, -size.y)
        .style({ stroke: "#0066E5", fill: "transparent", lineWidth: 2 });
      dwg.render({ ctx: options.context, annoScale: 1 / options.zoom });
    }
  }

  function overlay(options) {
    if (marquee) {
      marquee.render({ ctx: options.context });
    }

    if (draggedMark) {
      draggedMark.render({ ctx: options.context, annoScale: dpi });
    }
  }

  function dataWithLocation(location) {
    const data = cloneDeep($group.data);
    const doc = data.documents[docid];
    doc.locations.push(location);
    return data;
  }

  function isLoadedPage(index) {
    const page = tileList[index];
    return page.state === "completed";
  }

  async function toggleLocationDrawing(locid) {
    const loc = locationsObj[locid];
    const record = locRecord(loc);
    const file = doc.files.find((f) => f.object_id === loc.position.file_path);
    if (!file || !record || !loc) return;

    if (record.drawing && record.drawing.attachment_id === loc.id) {
      group.updateItem(record.id, "drawing", null);
      return;
    }

    if (file.extension === "pdf") {
      const path = `${nameMinusExtension(file.object_id)}.${loc.position.file_page}.tiff`;

      const drawing = {
        attachment_id: loc.id,
        id: file.object_id.split("/")[0],
        name: file.name,
        type: "iiif",
        clip: loc.clip,
        path,
        extension: file.extension,
        properties: {
          width: loc.clip.width,
          height: loc.clip.height,
        },
      };

      group.updateItem(record.id, "drawing", drawing);
    } else {
      const drawing = await imageDrawingFromClip(loc, file);
      group.updateItem(record.id, "drawing", drawing);
    }
  }

  async function handleCanvasClick(e) {
    dragStart = null;
    dragging = null;
    selecting = null;
    clipping = null;

    const { tilePosition } = e;

    const position = {
      file_path: tileList[tilePosition.index]?.filePath,
      file_page: tileList[tilePosition.index]?.page,
      x: tilePosition.x,
      y: tilePosition.y,
    };

    if ($currentTool === "select") {
      const hitbox = eventObj(e);
      if (hitbox) {
        selectedPage = null;
        const [type, id] = hitbox.name.split("_");
        if (type === "location") {
          if (e.shift) {
            selectedLocations.select(id);
          } else if (e.originalEvent.metaKey) {
            if ($selectedLocations[id]) {
              selectedLocations.deselect(id);
            } else {
              selectedLocations.select(id);
            }
          } else {
            selectedLocations.selectOnly(id);
          }
        } else if (type === "annotation") {
          selectedAnnotations.deselect(id);
          if (hoveredAnnotation === id) {
            hoveredAnnotation = null;
          }
        } else if (type === "dwgbtn") {
          toggleLocationDrawing(id);
        }
      } else {
        if (tilePosition.index >= 0 && isLoadedPage(tilePosition.index)) {
          selectedPage = {
            index: tilePosition.index,
          };
        } else {
          selectedPage = null;
        }

        if (!e.shift) {
          $selectedLocations = {};
        }
        hoveredLocations = {};
      }
    } else if (
      ["location-item", "location-collection"].includes($currentTool) &&
      tilePosition.index >= 0 &&
      isLoadedPage(tilePosition.index) &&
      adding
    ) {
      if (adding.clone) {
        const record_type = adding.record_type;
        const record =
          record_type === "type"
            ? cloneDeep($group.types[adding.record_id])
            : cloneDeep($group.items[adding.record_id]);

        record.id = crypto.randomUUID();
        record.mark = nextMark;
        const id = crypto.randomUUID();

        const data = dataWithLocation({
          id,
          type: "pin",
          position,
          record_type,
          record_id: record.id,
        });

        group.addItemsWithGroupUpdate([record], { data });
        selectedLocations.select(id);
      } else if (adding.record_id) {
        const id = crypto.randomUUID();
        const data = dataWithLocation({
          id,
          type: "pin",
          position,
          record_type: adding.record_type,
          record_id: adding.record_id,
        });
        group.updateProp("data", data);
        selectedLocations.select(id);
      } else {
        const record_type = adding.record_type;
        const p = record_type === "collection" ? { is_collection: true } : null;
        const records = record_type === "collection" ? collections : items;
        const item = defaultItem($group.id, $user.id, records, types, p);

        const id = crypto.randomUUID();
        const data = dataWithLocation({
          id,
          type: "pin",
          position,
          record_type,
          record_id: item.id,
        });

        group.addItemsWithGroupUpdate([item], { data });
        selectedLocations.select(id);
      }
    } else if ($currentTool === "location-type" && tilePosition.index >= 0) {
      if (!adding.record_id) {
        const type = defaultType($group.id, types);
        const id = crypto.randomUUID();

        const data = dataWithLocation({
          id,
          type: "pin",
          position,
          record_type: "type",
          record_id: type.id,
        });

        group.addTypesWithGroupUpdate([type], { data });
        selectedLocations.select(id);
        return;
      } else if (adding?.clone) {
        const record = cloneDeep($group.types[adding.record_id]);
        record.id = crypto.randomUUID();
        record.mark = nextMark;

        const id = crypto.randomUUID();
        const data = dataWithLocation({
          id,
          type: "pin",
          position,
          record_type: "type",
          record_id: record.id,
        });

        group.addTypesWithGroupUpdate([record], { data });
        selectedLocations.select(id);
        return;
      }

      const id = crypto.randomUUID();
      const data = dataWithLocation({
        id,
        type: "pin",
        position,
        record_type: "type",
        record_id: adding.record_id,
      });
      group.updateProp("data", data);
      selectedLocations.select(id);
      await tick();

      adding = null;
      $currentTool = "select";
    } else {
      $currentTool = "select";
      adding = null;
    }
  }

  function handleCanvasDoubleClick(e) {
    if ($currentTool === "select") {
      const { tilePosition } = e;
      if (tilePosition.index >= 0) {
        controller.dispatch("zoom-to-page", tilePosition.index);
      }
    }
  }

  function eventObj(e) {
    if (!e.hitContext) return null;
    const x = e.position.x * dpi;
    const y = e.position.y * dpi;
    const p = e.hitContext.getImageData(x, y, 1, 1).data;
    if (p[0] === 0 && p[1] === 0 && p[2] === 0) {
      return null;
    }

    const color = `rgb(${p[0]},${p[1]},${p[2]})`;
    const hash = hitHashes[e.tilePosition.index];
    if (!hash) return null;
    if (hash[color]) return hash[color];

    const near = nearColors(p[0], p[1], p[2], 1);
    const match = near.find((c) => hash[c]);
    if (match) return hash[match];

    return null;
  }

  function hitboxChange(current, hitbox) {
    if (!current && !hitbox) return false;
    if (!current && hitbox) return true;
    if (current && !hitbox) return true;
    if (current && hitbox) {
      return current.name !== hitbox.name;
    }
  }

  async function handleMousemove(e) {
    const { tilePosition } = e;
    if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
      locationPreview = {
        position: {
          x: tilePosition.x,
          y: tilePosition.y,
        },
        type: adding.record_type,
        index: tilePosition.index,
      };
    } else {
      locationPreview = null;
    }

    const hitbox = eventObj(e);
    if (hitboxChange(currentHb, hitbox)) {
      currentHb = hitbox;
      if (hitbox) {
        const [type, id, index] = hitbox.name.split("_");

        if ($currentTool === "select") {
          if (type === "dwgbtn") {
            cursor = "pointer";
          } else if (type === "clip") {
            if (["0", "2"].includes(index)) {
              cursor = "nesw-resize";
            } else if (["1", "3"].includes(index)) {
              cursor = "nwse-resize";
            } else if (index === "outline") {
              cursor = "move";
            }
          } else if (type === "location") {
            cursor = "pointer";
          } else if (type === "annotation") {
            cursor = "pointer";
          } else {
            cursor = "default";
          }
        }

        if (type === "location") {
          hoveredLocations = { [id]: true };
          hoveredAnnotation = null;
        } else if (type === "annotation") {
          hoveredAnnotation = id;
          hoveredLocations = {};
        } else {
          hoveredLocations = {};
          hoveredAnnotation = null;
        }
      } else {
        hoveredLocations = {};
        hoveredAnnotation = null;
        cursor = "default";
      }
    }
  }

  function handleLeftDown(e) {
    if (!viewer) return;
    const { position, tilePosition } = e;
    if (["location-item", "location-collection", "location-type"].includes($currentTool)) {
      if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
        const tpPos = { x: tilePosition.x, y: tilePosition.y };
        clipping = { start: position, end: position, tpStart: tpPos, tpEnd: tpPos };
        locationPreview = {
          position: tpPos,
          type: adding.record_type,
          index: tilePosition.index,
        };
      }
    }

    if ($currentTool !== "select") return;
    const hitbox = eventObj(e);
    if (hitbox && !disabled && !e.shift) {
      const [type, id, index] = hitbox.name.split("_");

      if (type === "location") {
        let selected;
        if ($selectedLocations[id]) {
          selected = cloneDeep($selectedLocations);
        } else {
          // $selectedLocations = {};
          selectedPage = null;
          selected = { [id]: true };
        }

        const location = locationsObj[id];
        const pageIndex = pageIndices[location.position.file_path]?.[location.position.file_page];
        if (pageIndex === undefined) return;
        const p = viewer.pixelCoords({ ...location.position, index: pageIndex });
        if (p) {
          dragStart = {
            type: "location",
            start: position,
            end: position,
            delta: subtract(position, p),
            hitbox,
            location,
            selected,
          };
        }
      } else if (type === "clip") {
        const location = locationsObj[id];
        const pageIndex = pageIndices[location.position.file_path]?.[location.position.file_page];
        if (pageIndex === undefined) return;
        if (index === "outline") {
          const p = viewer.pixelCoords({ ...location.position, index: pageIndex });
          if (p) {
            dragStart = {
              type: "clip-outline",
              start: position,
              end: position,
              delta: subtract(position, p),
              hitbox,
              location,
              selected: { [id]: true },
            };
          }
        } else {
          const vertex = clipVertex(location.clip, index);
          const p = viewer.pixelCoords({ ...vertex, index: pageIndex });
          if (p) {
            dragStart = {
              type: "clip-vertex",
              index,
              start: position,
              end: position,
              delta: subtract(position, p),
              hitbox,
              location,
            };
          }
        }
      }
    } else {
      selecting = { start: position, end: position, hitbox };
    }
  }

  function handleCanvasDrag(e) {
    if (!viewer) return;
    const { position, tilePosition } = e;

    if (["location-item", "location-collection", "location-type"].includes($currentTool)) {
      if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
        if (clipping) {
          const tpPos = { x: tilePosition.x, y: tilePosition.y };
          clipping.end = position;
          clipping.tpEnd = tpPos;
          locationPreview = {
            position: midpoint(clipping.tpStart, clipping.tpEnd),
            type: adding.record_type,
            index: tilePosition.index,
          };
        }
      } else {
        clipping = null;
        locationPreview = null;
      }
    }

    if ($currentTool !== "select") return;

    if (selecting) {
      selecting.end = position;
      return;
    }

    if (dragStart) {
      if (!dragging) {
        dragging = {
          ...dragStart,
          end: position,
        };
      }

      dragging.end = position;
      return;
    }
  }

  async function imageDrawingFromClip(location, file) {
    const f = await api.storage.from("documents").download(file.object_id);
    if (f.error) return;

    const url = URL.createObjectURL(f.data);
    const blob = await imgClipToBlob(url, location.clip);
    const clipId = crypto.randomUUID();
    const object_id = `${file.object_id}/${clipId}.png`;

    const stored = await api.storage.from("drawings").upload(object_id, blob, {
      contentType: "image/png",
    });
    if (stored.error) return;

    return {
      attachment_id: location.id,
      id: clipId,
      name: file.name,
      type: "image",
      extension: "png",
      object_id,
      preview_object_id: object_id,
      properties: {
        width: location.clip.width,
        height: location.clip.height,
      },
    };
  }

  async function storeNewClipItem(item, location, file) {
    const drawing = await imageDrawingFromClip(location, file);
    if (!drawing) return;
    item.drawing = drawing;
    const data = dataWithLocation(location);
    group.addItemsWithGroupUpdate([item], { data });
    selectedLocations.select(location.id);
  }

  async function updateClipItem(item, groupData, location, file) {
    const drawing = await imageDrawingFromClip(location, file);
    if (!drawing) return;

    const updates = [
      { type: "group", prop: "data", value: groupData },
      { type: "item", id: item.id, prop: "drawing", value: drawing },
    ];
    group.update(updates);
  }

  async function handleCanvasDragEnd(e) {
    if (!viewer) return;

    // Handle selecting finish
    if (selecting) {
      const minX = Math.min(selecting.start.x, selecting.end.x);
      const minY = Math.min(selecting.start.y, selecting.end.y);
      const maxX = Math.max(selecting.start.x, selecting.end.x);
      const maxY = Math.max(selecting.start.y, selecting.end.y);

      const selected = locations.filter((loc) => {
        const record = locRecord(loc);
        if (!record) return false;
        const index = pageIndices[loc.position.file_path]?.[loc.position.file_page];
        const p = viewer.pixelCoords({ ...loc.position, index });
        return p && p.x > minX && p.x < maxX && p.y > minY && p.y < maxY;
      });

      const ids = selected.map((l) => l.id);

      const hitbox = eventObj(e);
      if (e.shift) {
        if (hitbox && hitbox.name === selecting.hitbox?.name) {
          if (hitbox.name.startsWith("location")) {
            const m = hitbox.name.match(/location_(.+)/);
            selectedLocations.select(m[1]);
          }
        } else {
          selectedLocations.select(...ids);
        }
      } else {
        selectedLocations.selectOnly(...ids);
      }
      selectedPage = null;
    }

    // Handle clipping finish
    else if (adding && clipping) {
      const { tilePosition } = e;
      const d = distSq(clipping.end, clipping.start);

      const minX = Math.min(clipping.tpStart.x, tilePosition.x);
      const minY = Math.min(clipping.tpStart.y, tilePosition.y);
      const maxX = Math.max(clipping.tpStart.x, tilePosition.x);
      const maxY = Math.max(clipping.tpStart.y, tilePosition.y);

      const pos = midpoint(clipping.tpStart, tilePosition);

      const position = {
        file_path: tileList[tilePosition.index]?.filePath,
        file_page: tileList[tilePosition.index]?.page,
        x: pos.x,
        y: pos.y,
      };

      const clip =
        d > CLIP_THRESHOLD
          ? { x: minX, y: minY, width: Math.max(maxX - minX, 10), height: Math.max(maxY - minY, 10) }
          : null;

      const dd = Math.sqrt(d);

      // A bit of a fudge: for small drags, OpenSeadragon will emit both a "click" AND a "dragend" event.
      // To keep from adding two locations (one for each event), we check whether the distance dragged
      // is larger than the default clickDistThreshold property (5).
      if (tilePosition.index >= 0 && isLoadedPage(tilePosition.index) && dd > 5) {
        if (adding.clone) {
          const record_type = adding.record_type;
          const record =
            record_type === "type"
              ? cloneDeep($group.types[adding.record_id])
              : cloneDeep($group.items[adding.record_id]);

          record.id = crypto.randomUUID();
          record.mark = nextMark;
          const id = crypto.randomUUID();

          const data = dataWithLocation({
            id,
            type: "pin",
            position,
            record_type,
            record_id: record.id,
            clip,
          });

          group.addItemsWithGroupUpdate([record], { data });
          selectedLocations.select(id);
        } else if (adding.record_id) {
          const id = crypto.randomUUID();
          const data = dataWithLocation({
            id,
            type: "pin",
            position,
            record_type: adding.record_type,
            record_id: adding.record_id,
            clip,
          });
          group.updateProp("data", data);
          selectedLocations.select(id);
        } else {
          const record_type = adding.record_type;
          const p = record_type === "collection" ? { is_collection: true } : null;
          const records = record_type === "collection" ? collections : items;
          const item = defaultItem($group.id, $user.id, records, types, p);

          const id = crypto.randomUUID();
          const loc = {
            id,
            type: "pin",
            position,
            record_type,
            record_id: item.id,
            clip,
          };

          const fp = filePages[tilePosition.index];
          const file = doc.files.find((f) => f.object_id === fp.file_path);

          if (clip && file.extension === "pdf") {
            const path = `${nameMinusExtension(file.object_id)}.${position.file_page}.tiff`;

            item.drawing = {
              attachment_id: id,
              id: file.object_id.split("/")[0],
              name: file.name,
              type: "iiif",
              clip,
              path,
              extension: file.extension,
              properties: {
                width: clip.width,
                height: clip.height,
              },
            };

            const data = dataWithLocation(loc);
            group.addItemsWithGroupUpdate([item], { data });
            selectedLocations.select(id);
          } else if (clip) {
            storeNewClipItem(item, loc, file);
          } else {
            const data = dataWithLocation(loc);
            group.addItemsWithGroupUpdate([item], { data });
            selectedLocations.select(id);
          }
        }
      }
    }

    // Handle dragging finish
    else if (dragging) {
      if (dragging.type === "location") {
        const { start, end, delta, selected } = dragging;
        const dropPos = subtract(end, delta);
        const ldelta = subtract(end, start);
        const dropTc = viewer.tileCoords(dropPos);
        const isViableDropLocation = dropTc.index >= 0;
        const data = cloneDeep($group.data);
        const doc = data.documents[docid];
        const dataLocs = objectify(doc.locations);

        if (isViableDropLocation) {
          Object.keys(selected)
            .filter((locid) => dataLocs[locid])
            .forEach((locid) => {
              const loc = dataLocs[locid];
              const index = pageIndices[loc.position.file_path]?.[loc.position.file_page];
              const pos = viewer.pixelCoords({ ...loc.position, index });

              if (pos) {
                const newPos = add(pos, ldelta);
                const newTc = viewer.tileCoords(newPos);

                if (newTc.index >= 0) {
                  loc.position = {
                    file_path: tileList[newTc.index]?.filePath,
                    file_page: tileList[newTc.index]?.page,
                    x: newTc.x,
                    y: newTc.y,
                  };
                }
              }
            });

          group.updateProp("data", data);
        }
      } else if (dragging.type === "clip-outline") {
        const { location, end, delta } = dragging;
        const pos = subtract(end, delta);
        const lindex = pageIndices[location.position.file_path]?.[location.position.file_page];
        const tc = viewer.tileCoords(pos, lindex);

        const minX = location.position.x - location.clip.x;
        const minY = location.position.y - location.clip.y;
        const maxX = tc.width - (location.clip.x + location.clip.width - location.position.x);
        const maxY = tc.height - (location.clip.y + location.clip.height - location.position.y);
        const x = Math.max(minX, Math.min(tc.x, maxX));
        const y = Math.max(minY, Math.min(tc.y, maxY));
        const td = subtract({ x, y }, location.position);

        const data = cloneDeep($group.data);
        const doc = data.documents[docid];
        const loc = doc.locations.find((l) => l.id === location.id);
        const d = {
          x: loc.clip.x + td.x,
          y: loc.clip.y + td.y,
          width: loc.clip.width,
          height: loc.clip.height,
        };
        loc.clip = d;
        loc.position = {
          file_path: tileList[tc.index]?.filePath,
          file_page: tileList[tc.index]?.page,
          x,
          y,
        };

        const item = loc.record_type === "item" && $group.items[loc.record_id];
        if (item?.drawing?.attachment_id === loc.id) {
          const drawing = cloneDeep(item.drawing);
          drawing.clip = d;

          const updates = [
            { type: "group", prop: "data", value: data },
            { type: "item", id: item.id, prop: "drawing", value: drawing },
          ];
          group.update(updates);
        } else {
          group.updateProp("data", data);
        }
      } else if (dragging.type === "clip-vertex") {
        const { location, index, end, delta } = dragging;
        const pos = subtract(end, delta);
        const lindex = pageIndices[location.position.file_path]?.[location.position.file_page];
        const tc = viewer.tileCoords(pos, lindex);

        const x = Math.max(0, Math.min(tc.x, tc.width));
        const y = Math.max(0, Math.min(tc.y, tc.height));
        const to = { x, y };

        const data = cloneDeep($group.data);
        const doc = data.documents[docid];
        const loc = doc.locations.find((l) => l.id === location.id);
        const d = draggedClip(loc.clip, index, to);
        loc.clip = d;

        const item = loc.record_type === "item" && $group.items[loc.record_id];
        if (item?.drawing?.attachment_id === loc.id) {
          const fp = filePages[lindex];
          const file = doc.files.find((f) => f.object_id === fp.file_path);

          if (file.extension === "pdf") {
            const drawing = cloneDeep(item.drawing);
            drawing.clip = d;
            drawing.properties.width = d.width;
            drawing.properties.height = d.height;

            const updates = [
              { type: "group", prop: "data", value: data },
              { type: "item", id: item.id, prop: "drawing", value: drawing },
            ];
            group.update(updates);
          } else {
            await updateClipItem(item, data, loc, file);
          }
        } else {
          group.updateProp("data", data);
        }
      }
    }

    dragStart = null;
    dragging = null;
    selecting = null;
    clipping = null;
  }

  function gotoNext() {
    // slide = "left";
    navigate(`./${nextDoc.id}`);
  }

  function gotoPrev() {
    // slide = "right";
    navigate(`./${prevDoc.id}`);
  }

  function beginAdding(e) {
    const { record_type, record_id } = e.detail;
    $currentTool = `location-${record_type}`;
    adding = {
      record_type,
      record_id,
    };
  }

  function beginCloning(e) {
    const { record_type, record_id } = e.detail;
    $currentTool = `location-${record_type}`;
    adding = {
      record_type,
      record_id,
      clone: true,
    };
  }

  function handleLocationToolClick() {
    adding = {
      record_type: $currentTool.split("-")[1],
      record_id: null,
    };
  }

  function confirmRemoveLocations() {
    locationsToDelete = selectedLocationList.map((l) => l.id);
    confirmRemoveModal.open();
  }

  function removeLocations() {
    const data = cloneDeep($group.data);
    const doc = data.documents[docid];
    doc.locations = doc.locations.filter((l) => !locationsToDelete.includes(l.id));
    group.updateProp("data", data);
  }

  function handleRightDown() {
    tempTool = $currentTool;
    $currentTool = "pan";
  }

  function handleRightUp() {
    if (tempTool) {
      $currentTool = tempTool;
      tempTool = null;
    }
  }

  function handleLocationClick(e) {
    const { locations } = e.detail;
    if (locations.length > 0) {
      const indices = locations
        .map((l) => pageIndices[l.position.file_path]?.[l.position.file_page])
        .filter((l) => l !== undefined);
      const pages = uniq(indices);
      controller.dispatch("zoom-to-page", pages);
    }
  }

  function handleLocationDblClick(e) {
    const { locations } = e.detail;
    if (locations.length > 0) {
      const indices = locations
        .map((l) => pageIndices[l.position.file_path]?.[l.position.file_page])
        .filter((l) => l !== undefined);
      const pages = uniq(indices);
      controller.dispatch("zoom-to-page", pages);
      selectedLocations.selectOnly(...locations.map((l) => l.id));
    }
  }

  function updateRecordStatus(e) {
    const value = e.detail;
    const itemUpdates = selectedLocationList
      .filter((l) => ["item", "collection"].includes(l.record_type))
      .map((l) => ({ type: "item", id: l.record_id, prop: "approval_status", value }));
    const typeUpdates = selectedLocationList
      .filter((l) => l.record_type === "type")
      .map((l) => ({ type: "type", id: l.record_id, prop: "approval_status", value }));

    const updates = [...itemUpdates, ...typeUpdates];

    if (updates.length > 0) {
      group.update(updates);
    }
  }

  function copyLocationsToClipboard() {
    copyToClipboard({
      type: "locations",
      data: {
        locations: cloneDeep(selectedLocationList),
        document_id: docid,
      },
    });
  }

  function pasteLocationsFromClipboard() {
    if ($clipboard?.type === "locations") {
      const p = selectedPage?.index || 0;
      const locs = $clipboard.data.locations;
      const pdoc = $group.data.documents[$clipboard.data.document_id];
      const pdocIndices = getPageIndices(pdoc);
      let minIndex = 0;
      const indices = locs
        .map((l) => pdocIndices[l.position.file_path]?.[l.position.file_page])
        .filter((l) => l !== undefined);
      if (indices.length) {
        minIndex = Math.min(...indices);
      }

      const newLocs = locs
        .map((l) => {
          const index = pdocIndices[l.position.file_path]?.[l.position.file_page];
          const newIndex = index - minIndex + p;
          const f = filePages[newIndex];

          if (index === undefined || newIndex === undefined || f === undefined) return null;

          return {
            ...l,
            id: crypto.randomUUID(),
            position: {
              ...l.position,
              file_path: f.file_path,
              file_page: f.file_page,
            },
          };
        })
        .filter((l) => l && locRecord(l));

      if (newLocs.length) {
        const data = cloneDeep($group.data);
        const doc = data.documents[docid];
        doc.locations.push(...newLocs);
        group.updateProp("data", data);
      }
    }
  }

  onMount(() => {
    eb.on("zoom-in", zoomIn);
    eb.on("zoom-out", zoomOut);
    eb.on("zoom-to-fit", zoomToFit);
    eb.on("location-item", handleLocationToolClick);
    eb.on("location-type", handleLocationToolClick);
    eb.on("location-collection", handleLocationToolClick);
    eb.on("copy-to-clipboard", copyLocationsToClipboard);
    eb.on("paste-from-clipboard", pasteLocationsFromClipboard);
    controller.on("canvas-click", handleCanvasClick);
    controller.on("canvas-double-click", handleCanvasDoubleClick);
    controller.on("canvas-press", handleLeftDown);
    controller.on("canvas-drag", handleCanvasDrag);
    controller.on("canvas-drag-end", handleCanvasDragEnd);
    controller.on("canvas-mousemove", handleMousemove);
    controller.on("canvas-nonprimary-press", handleRightDown);
    controller.on("canvas-nonprimary-release", handleRightUp);
    controller.on("open", finish);

    return () => {
      eb.unsubscribe("zoom-in", zoomIn);
      eb.unsubscribe("zoom-out", zoomOut);
      eb.unsubscribe("zoom-to-fit", zoomToFit);
      eb.unsubscribe("location-item", handleLocationToolClick);
      eb.unsubscribe("location-type", handleLocationToolClick);
      eb.unsubscribe("location-collection", handleLocationToolClick);
      eb.unsubscribe("copy-to-clipboard", copyLocationsToClipboard);
      eb.unsubscribe("paste-from-clipboard", pasteLocationsFromClipboard);
      controller.subscriptions = {};

      if (poller) {
        clearInterval(poller);
      }
    };
  });
</script>

<div class="p-4 absolute z-10 space-y-2">
  <div class="text-blue-500 text-sm">
    <Link to={"documents"} class="flex items-center space-x-1">
      <CaretLeftIcon />
      <div>All Documents</div>
    </Link>
  </div>
</div>

{#if doc}
  <div
    class="w-full h-full flex relative"
    bind:offsetWidth={width}
    bind:offsetHeight={height}
    class:flex-col={sidebarPosition === "bottom"}>
    <div class="grow relative">
      <div class="absolute w-full h-full overflow-hidden">
        {#if selectedLocationList.length > 0}
          <SelectedActions
            deletable={!disabled}
            cloneable
            selected={selectedLocationList.length}
            on:delete={confirmRemoveLocations}
            on:clone={copyLocationsToClipboard} />
        {/if}

        {#if adding}
          {@const addingItem = locRecord(adding)}
          {@const l = locationsByRecord[adding.record_id] || []}
          {@const record = adding.record_type === "collection" ? "opening" : adding.record_type}
          <div class="absolute top-0 right-0 p-4 text-sm italic z-20">
            {#if addingItem}
              Adding location {l.length + 1}
              {#if adding.record_type === "item"}
                / {addingItem.quantity}
              {/if}
              for {record}
              {addingItem.mark}
            {:else}
              Click to add new {record}s
            {/if}
          </div>
        {/if}
        {#key docid}
          <div class="absolute w-full h-full" style="cursor: {cursor};">
            {#await setup() then _}
              <OpenSeadragon
                bind:this={viewer}
                {controller}
                {tileList}
                {pannable}
                rightMousePan
                options={{
                  viewportMargins: {
                    left: 30,
                    top: 30,
                    right: 30,
                    bottom: 30,
                  },
                }}
                {initialPage}
                {tileOverlay}
                {overlay} />
            {/await}
            {#if progress < 0.99999}
              <div class="absolute bottom-0 right-0">
                <div class="text-sm p-2">
                  {Math.round(progress * 100)}% complete
                </div>
              </div>
            {/if}
          </div>
        {/key}
      </div>
    </div>
    {#if $showRightPanel}
      <Sidebar position={sidebarPosition}>
        <svelte:fragment slot="header">
          <PrevNext
            prev={prevDoc}
            next={nextDoc}
            title={doc?.name}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky />
        </svelte:fragment>
        <svelte:fragment slot="content">
          {#if selectedLocationList.length > 0}
            <LocationProperties
              locations={selectedLocationList}
              {group}
              {types}
              {items}
              {collections}
              {disabled}
              {customColumns}
              {customColColumns}
              {collectionItems}
              {standardColumns}
              on:add-location={beginAdding}
              on:clone-location={beginCloning}
              on:updateSupplier />
          {:else if selectedLocationList.length === 0 && items}
            <div class="h-full flex-col space-y-4" class:gap-4={sidebarPosition === "bottom"}>
              <DocumentProperties
                document={doc}
                {group}
                {disabled}
                {annotations}
                {items}
                {viewer}
                bind:hoveredFile
                bind:hoveredAnnotation
                on:zoom-to-page={(e) => controller.dispatch("zoom-to-page", e.detail.page)} />
              <DocLocationProperties
                document={doc}
                {group}
                {items}
                {types}
                {collections}
                {adding}
                bind:hoveredLocations
                on:add-location={beginAdding}
                on:clone-location={beginCloning}
                on:click-locations={handleLocationClick}
                on:dblclick-locations={handleLocationDblClick} />
            </div>
          {/if}
        </svelte:fragment>
      </Sidebar>
    {/if}
  </div>
{/if}

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeLocations}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Documents</div>
  <div slot="content" class="space-y-2">
    <div class="space-y-2">
      <p>Are you sure you want to delete {locationsToDelete.length} location marker(s)?</p>
    </div>
  </div>
</Modal>
