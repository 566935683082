<script>
  import { getContext, createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import { Dimension, LinearDisplayFormat } from "dimtext";

  import Viewport from "src/lib/drawing/ViewportFixed.svelte";
  import QuantityInput from "src/lib/standard-inputs/QuantityInput.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import TrashIcon from "src/assets/icons/trash.svg";
  import getTotalThickness from "@local/extensions/formatters/get-total-thickness.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";
  import { typeSurfaces } from "@local/lamina-core";
  import imageUrl from "src/extensions/image-url.js";

  export let group;
  export let type;
  export let items;
  export let index = 1;
  export let typeIndex;
  export let job;
  export let sent;

  const dispatch = createEventDispatcher();

  const priceEntries = getContext("priceEntries");
  const supplier = getContext("supplier");

  $: du = $group.data.settings.display_unit;
  $: productGroup = $group.project_type === "product";
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };
  $: ds = dimSettings($group.data.settings);
  $: thickness = type && getTotalThickness(type, typeSettings);
  $: surfaces = type && typeSurfaces(type);
  $: quantity = items.reduce((q, i) => q + i.quantity, 0);
  $: size = getDimensions(type, items, ds);
  $: area = typeArea(items);
  $: weight = typeWeight(type, items);
  $: areaFormatted = du === "millimeters" ? `${(area * 0.092903).toFixed(2)} m²` : `${area.toFixed(2)} sf`;
  $: undefinedItems = items.filter((i) => !i.width || !i.height).length;
  $: priceEach = getPriceEach(type, $priceEntries, job);
  $: priceTotal = priceEach * quantity;

  function formatThickness(val) {
    const sdf = "FRACTIONAL";

    if (!val) return "Thickness TBD";

    const v = val.format(LinearDisplayFormat[sdf], sdf === "DECIMAL" ? 3 : 5, {
      displayUnit: "inches",
    });

    return `${v}"`;
  }

  function getDimensions(type, items) {
    const first = items[0];
    if (!first) return null;
    if (first.width && first.height) {
      const fmt = {
        DECIMAL: LinearDisplayFormat.DECIMAL,
        FRACTIONAL: LinearDisplayFormat.FRACTIONAL,
      }[ds.dimFormat];
      const unit = ds.displayUnit === "millimeters" ? "mm" : '"';
      const wdim = new Dimension(first.width);
      const hdim = new Dimension(first.height);

      const wtext = wdim.format(fmt, ds.dimPrecision, {
        displayUnit: ds.displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      const htext = hdim.format(fmt, ds.dimPrecision, {
        displayUnit: ds.displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      return `${wtext}${unit} x ${htext}${unit}`;
    }
  }

  function typeArea(items) {
    return items
      .filter((i) => i.width && i.height)
      .reduce((acc, item) => {
        const a = (item.cache.width / 12) * (item.cache.height / 12);
        return acc + a * item.quantity;
      }, 0);
  }

  function typeWeight(type, items) {
    if (type?.data.layers) {
      const val = items
        .filter((i) => i.cache?.weight)
        .reduce((acc, item) => {
          return acc + item.cache.weight * item.quantity;
        }, 0);

      return `${val.toFixed(0)} lbs`;
    } else {
      return "";
    }
  }

  function undefinedItemText(u) {
    if (!u) return "";
    if (u === 1) return " (1 undefined item)";
    return ` (${u} undefined items)`;
  }

  function getPriceEach(type, priceEntries, job) {
    if (!type || !priceEntries || !job?.product_list_id) return 0;
    const priceEntry = priceEntries[type?.product_id]?.[job?.product_list_id];

    // TODO: support alternate pricing units
    if (priceEntry && priceEntry.unit === "item") {
      return priceEntry.unit_price;
    } else {
      return 0;
    }
  }
</script>

<tbody>
  <tr>
    <td>
      {typeIndex + 1}
    </td>
    <td>
      <div class="w-full">
        <div class="font-bold">{type.name}</div>
        <div class="flex gap-2">
          <div class="w-24 h-24 relative flex-none">
            {#if !type.data.layers && type.image}
              <img src={imageUrl(type.image)} alt={type.name} class="text-xs w-full h-full object-cover" />
            {:else}
              <Viewport padding={1} drawing={typeDrawing(type)} cacheKey={type.id} width={96} height={96} />
            {/if}
          </div>
          <div>
            <table class="type-info">
              {#if type?.data.layers}
                {#if size}
                  <tr>
                    <td class="label">Size</td>
                    <td>
                      {size}
                    </td>
                  </tr>
                {/if}
                <tr>
                  <td class="label">Thickness</td>
                  <td>
                    {thickness || "TBD"}
                  </td>
                </tr>
                <tr>
                  <td class="label">Makeup</td>
                  <td>
                    {#each type.data.layers as layer, i}
                      <div>
                        <span>
                          {upperFirst(layer.type)}:&nbsp;
                        </span>
                        <span>
                          {formatThickness(layer.material?.data?.thickness)}
                        </span>
                        {#if layer.material?.name}
                          <span>, {layer.material.name}</span>
                        {/if}
                        {#if layer.outboard_surface?.name}
                          <span>, #{surfaces[i]} {layer.outboard_surface.name}</span>
                        {/if}
                        {#if layer.inboard_surface?.name}
                          <span>, #{surfaces[i] + 1} {layer.inboard_surface.name}</span>
                        {/if}
                      </div>
                    {/each}
                  </td>
                </tr>
                <tr>
                  <td class="label">Area</td>
                  <td>
                    {areaFormatted}{undefinedItemText(undefinedItems)}
                  </td>
                </tr>
              {/if}
            </table>
          </div>
        </div>
      </div>
    </td>
    <td class="numeric">
      {#if type?.data.layers}
        {areaFormatted}{undefinedItemText(undefinedItems)}
      {/if}
    </td>
    <td class="numeric">
      {weight}
    </td>
    <td class="numeric">{priceEach?.toFixed(2) || ""}</td>
    <td class="numeric">
      {#if !sent}
        <QuantityInput
          {quantity}
          on:input={(e) => dispatch("update-quantity", { quantity: e.detail.value, type })} />
      {:else}
        {quantity}
      {/if}
    </td>
    <td class="numeric">{priceTotal?.toFixed(2) || ""}</td>
    {#if !sent}
      <td>
        <button class="text-gray-400 hover:text-black" on:click={() => dispatch("delete-type", { type })}>
          <TrashIcon />
        </button>
      </td>
    {/if}
  </tr>
</tbody>

<style lang="scss">
  td {
    @apply border-gray-300 align-top p-1;

    &.numeric {
      @apply text-right whitespace-nowrap;
    }
  }

  table.type-info {
    td {
      padding: 0rem 0.25rem 0rem 0.25rem;
    }

    td.label {
      @apply text-gray-400 text-right;
    }
  }
</style>
