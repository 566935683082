<script>
  import { createEventDispatcher } from "svelte";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let placeholder = "";
  export let inputOnKeydown = false;
  export let outerBorder = true;

  export let formatter = (v) => v;
  export let validator = (v) => v;
  export let parser = (v) => v;
  export const focus = () => focusInput();

  const dispatch = createEventDispatcher();

  let input;
  let localValue = value;
  let displayValue = value === "Mixed" ? value : formatter(value);
  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: refresh(value, formatter);

  function refresh(v, formatter) {
    localValue = v;
    displayValue = v === "Mixed" ? v : formatter(v);
  }

  function parseInput(val) {
    try {
      val = parser(val);
      val = validator(val);
      localValue = val;
      displayValue = formatter(localValue);
      if (localValue !== value) {
        value = localValue;
        dispatch("input", { value: localValue });
      }
    } catch (err) {
      displayValue = localValue === "Mixed" ? localValue : formatter(localValue);
      dispatch("error", err);
    }
  }

  function focusInput() {
    input.focus();
  }

  function handleFocus(evt) {
    focused = true;
    dispatch("focus", evt);
    input.select();
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();

      if (!inputOnKeydown) {
        parseInput(evt.target.value);
      }

      dispatch("enter", { value: localValue });
    }
  }

  function handleInput(evt) {
    if (inputOnKeydown) {
      parseInput(evt.target.value);
    }
  }

  function handleBlur(evt) {
    focused = false;
    dispatch("blur", evt);
    parseInput(evt.target.value);
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={readonly || disabled}
  class:visible-border={border}
  on:click|stopPropagation={focusInput}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if readonly}
    <div bind:this={input} class="input">{displayValue}</div>
  {:else}
    <input
      size="1"
      bind:this={input}
      class="input"
      {placeholder}
      {disabled}
      bind:value={displayValue}
      on:focus={handleFocus}
      on:keydown={handleKeydown}
      on:input={handleInput}
      on:blur={handleBlur} />
  {/if}
</div>

<style lang="scss">
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply w-full flex items-center rounded bg-white;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .input {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }
  }
</style>
