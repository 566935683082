<script>
  import { navigate } from "svelte-routing";
  import ShapeIcon from "src/assets/icons/shape-md.svg";
  import ShapeIconSm from "src/assets/icons/shape-sm.svg";
  import TypeIcon from "src/assets/icons/type-md.svg";
  import TypeIconSm from "src/assets/icons/type-sm.svg";
  import ArrowRightIcon from "src/assets/icons/arrow-right.svg";
  import DownloadIcon from "src/assets/icons/download.svg";
  import eb from "src/extensions/event-bus.js";

  export let page = "rfqs"; // "quotes" | "rfqs"
  export let selected;
  export let selectedRecordType;

  $: isEditable = isRecordEditable(page, selected);
  $: isEmpty = isNewRfqWithNoItems(page, selected);
  $: canSend = canSendQuote(page, selected);
  $: canSeeDetails = showTypesButton(page, selected);

  function isRecordEditable(page, selected) {
    if (!selected) return false;
    if (page === "quotes") return selectedRecordType === "job";
    return !selected.sent_at && selected.status !== "canceled";
  }

  function isNewRfqWithNoItems(page, selected) {
    if (!selected) return false;
    if (page === "quotes" && selectedRecordType === "response") return false;

    const len = selected.items_count;
    if (len === 0) return true;
    return false;
  }

  function canSendQuote(page, selected) {
    if (!selected) return false;
    if (page !== "quotes") return false;
    if (selectedRecordType === "job") return false;
    const canceled = selected.request_status === "canceled";
    const archived = selected.archived_at;
    return !canceled && (!selected.sent_at || selected.status === "canceled") && !archived;
  }

  function showTypesButton(page, selected) {
    if (!selected) return false;
    if (selected.project_type === "product") return false;
    return true;
  }

  function clickItems() {
    if (page === "rfqs") {
      if (selected?.sent_at) {
        navigate(`/versions/${selected.group_id}`);
      } else {
        navigate(`/jobs/${selected.job_id}/current`);
      }
    } else {
      if (selectedRecordType === "job") {
        navigate(`/jobs/${selected.id}/current`);
      } else {
        navigate(`/versions/${selected.request_group_id}`);
      }
    }
  }

  function clickTypes() {
    if (page === "rfqs") {
      if (selected?.sent_at) {
        navigate(`/versions/${selected.group_id}/types`);
      } else {
        navigate(`/jobs/${selected.job_id}/current/types`);
      }
    } else {
      if (selectedRecordType === "job") {
        navigate(`/jobs/${selected.id}/current/types`);
      } else {
        navigate(`/versions/${selected.request_group_id}/types`);
      }
    }
  }

  async function sendRfq() {
    if (selected.sent_at) {
      eb.dispatch("open-recipient-modal");
    } else {
      eb.dispatch("open-rfq-modal");
    }
  }

  async function download() {
    eb.dispatch("download-quote-files");
  }

  function sendQuote() {
    eb.dispatch("prep-open-quote");
  }

  function shareDraftJob() {
    eb.dispatch("open-share-draft-modal");
  }
</script>

<div class="flex gap-2">
  {#if canSeeDetails}
    <button class="action-button" disabled={!selected} on:click={clickItems}>
      <div class="mobile">
        <ShapeIconSm />
      </div>
      <div class="standard">
        <ShapeIcon />
        <div>
          {#if isEditable && isEmpty}Add{:else if isEditable}Edit{:else}View{/if} Items
        </div>
      </div>
    </button>

    <button class="action-button" disabled={!selected} on:click={clickTypes}>
      <div class="mobile">
        <TypeIconSm />
      </div>
      <div class="standard">
        <TypeIcon />
        <div>
          {#if isEditable && isEmpty}Add{:else if isEditable}Edit{:else}View{/if} Types
        </div>
      </div>
    </button>
  {/if}
  {#if page === "rfqs" && selected && selected?.status !== "canceled" && !selected?.sent_at}
    <button class="action-button primary" on:click={sendRfq}>
      <div class="mobile">
        <ArrowRightIcon />
      </div>
      <div class="standard">Finish</div>
    </button>
  {:else if page === "rfqs" && selected?.project_type === "product" && selected?.sent_at && selected?.quotes_count > 0}
    <button class="action-button primary" on:click={download}>
      <div class="mobile">
        <DownloadIcon />
      </div>
      <div class="standard">Download</div>
    </button>
  {:else if page === "quotes" && canSend}
    <button class="action-button primary" on:click={sendQuote}>
      <div class="mobile">
        <ArrowRightIcon />
      </div>
      <div class="standard">Finish</div>
    </button>
  {:else if page === "quotes" && selectedRecordType === "job"}
    <button class="action-button primary" on:click={shareDraftJob}>
      <div class="mobile">
        <ArrowRightIcon />
      </div>
      <div class="standard">Share</div>
    </button>
  {/if}
</div>

<style lang="scss">
  .action-button {
    @apply px-2 py-1 rounded-md bg-white border border-gray-500 text-xs;

    &:hover {
      @apply border-black bg-gray-100;
    }

    div.mobile {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      @media (min-width: 768px) {
        display: none;
      }
    }

    div.standard {
      display: none;

      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    &.primary {
      @apply bg-amber-300 border-gray-800 font-bold;

      &:hover {
        @apply border-black bg-amber-400;
      }
    }

    @media (min-width: 768px) {
      &.primary {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    &:disabled {
      @apply opacity-30 cursor-not-allowed;
    }
  }
</style>
