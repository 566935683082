<script>
  import * as env from "src/env";
  import KVListing from "src/lib/KVListing.svelte";
</script>

<div class="wrapper">
  <KVListing kv={env}/>
</div>

<style>
  .wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
    overflow: scroll;
  }
</style>
