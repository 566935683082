<script>
  export let type = "flex";
  export let position = "right";
</script>

<div
  class="flex-none relative h-full bg-white border-l flex flex-col justify-between"
  class:relative={type === "flex"}
  class:flex-none={type === "flex"}
  class:absolute={type === "absolute"}
  class:w-80={position === "right"}
  class:h-full={position === "right"}
  class:right-0={type === "absolute" && position === "right"}
  class:top-0={type === "absolute" && position === "right"}
  class:bottom-0={position === "bottom"}
  class:w-full={position === "bottom"}
  class:left-0={position === "bottom"}
  class:h-56={position === "bottom"}
  on:mousedown|stopPropagation
  on:click|stopPropagation>
  {#if $$slots.header}
    <div class="empty:hidden -mb-4">
      <slot name="header" />
    </div>
  {/if}
  <div class="grow overflow-auto space-y-4 py-4">
    <slot name="content" />
  </div>
  {#if $$slots.footer}
    <div class="px-2 mb-2 sm:mb-6 pt-2 sm:pt-6 empty:hidden border-t">
      <slot name="footer" />
    </div>
  {/if}
</div>
