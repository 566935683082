<script>
  import { Link, navigate } from "svelte-routing";
  import NavbarBase from "src/lib/NavbarBase.svelte";
</script>

<NavbarBase>
  <div slot="center" class="text-gray-400">
    <div class="hidden lg:block text-sm">
      Accurate shapes in, accurate glass out. See errors before they cost you money.
    </div>
  </div>
  <div slot="right" class="flex items-center gap-4">
    <Link to="/">Log In</Link>
    <button class="btn btn-primary-alt" on:click={() => navigate("/signup")}>Sign Up</button>
  </div>
</NavbarBase>
