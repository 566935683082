<script>
  import get from "lodash/get";
  import set from "lodash/set";
  import { createEventDispatcher } from "svelte";
  import { Popover } from "svelte-utilities";
  import SearchIcon from "src/assets/icons/search.svg";
  import CancelIcon from "src/assets/icons/x.svg";
  import ErrorMessage from "src/lib/standard-inputs/ErrorMessage.svelte";

  export let border = false;
  export let placeholder = "Placeholder";
  export let label = null;
  export let labelWidth = "";
  export let disabled = false;
  export let value = "";
  export let selection = null;
  export let results = [];
  export let pendingData = [];
  export let showSelectedDetails = false;
  export let noResultsMsg = "No matching results found.";
  export let validate = null;
  export let update = null;
  export let confirm = null;
  export let searchIcon = true;
  export let outerBorder = true;
  export let confirmPending = async (v) => ({ success: true, message: null });

  const dispatch = createEventDispatcher();

  let focused = false;
  let text = value;
  let input;
  let inputContainer;
  let arrowCounter = -1;
  let errorMessage = null;

  export const focus = () => {
    input.focus();
  };

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: findNewResults(text);
  $: updateText(selection);
  $: selected = selection && !selection.pending;
  $: pending = selection?.pending;

  async function findNewResults(t) {
    value = text;
    dispatch("search", { value: text });
  }

  function updateText(selection) {
    if (selection) {
      text = selection.name;
    }
  }

  async function handleFocus() {
    dispatch("focus", { value: text });
    focused = true;
  }

  function handleBlur() {
    if (value) {
      const result = results.find((r) => r.id === value);
      if (result) text = result.name;
    }
    focused = false;
  }

  function handleKeydown(evt) {
    if (evt.key === "ArrowDown") handleDownArrow();
    if (evt.key === "ArrowUp") handleUpArrow();
    if (evt.key === "Enter") handleEnter();
    if (evt.key === "Escape") handleEsc();
  }

  function handleDownArrow() {
    if (arrowCounter < results.length - 1) {
      arrowCounter += 1;
    }
  }

  function handleUpArrow() {
    if (arrowCounter > 0) {
      arrowCounter -= 1;
    }
  }

  function handleEnter() {
    const result = results[arrowCounter];
    if (result) {
      select(result);
      input.blur();
    }
  }

  function handleEsc() {
    input.blur();
  }

  function select(result) {
    if (result.invalid) return;
    dispatch("select", { result });
    selection = result;
    text = result.name;
    if (input) input.blur();
  }

  function cancelSelection() {
    selection = null;
    text = "";
    dispatch("deselect");
  }

  function updateSelectionProp(prop, val) {
    if (val === "null") val = null;
    set(selection?.values, prop, val);
    if (update) update(selection, prop, val);
    selection = selection;
  }

  async function confirmSelection() {
    errorMessage = null;
    if (confirm) confirm(selection);

    const { success, message } = await confirmPending(selection);

    if (success) {
      selection.pending = false;
      select(selection);
    } else {
      errorMessage = message;
    }
  }
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:selected
  class:pending={selection?.pending}
  class:visible-border={border}>
  <div class="flex items-center">
    {#if searchIcon}
      <div class="text-gray-400">
        <div class="py-1">
          <SearchIcon />
        </div>
      </div>
    {/if}
    {#if label}
      <div style={lw}>
        <div class="label">
          <slot name="label">
            {label}
          </slot>
        </div>
      </div>
    {/if}
    <div class="grow ml-2">
      <div class="relative flex items-center input-div" bind:this={inputContainer}>
        {#if !text}
          <div class="absolute pointer-events-none">
            <div class="placeholder">
              <slot name="placeholder">
                {placeholder}
              </slot>
            </div>
          </div>
        {/if}
        {#if selected && showSelectedDetails}
          <div class="grow">
            <div class="flex items-end gap-2">
              <div class="truncate">{selection.name}</div>
              {#if selection.description}
                <div class="text-xs text-gray-600 truncate">{selection.description}</div>
              {/if}
            </div>
            {#if selection.cta}
              <div class="text-xs text-gray-600 truncate">
                {selection.cta}
              </div>
            {/if}
          </div>
        {:else}
          <input
            type="text"
            class="input"
            bind:this={input}
            disabled={disabled || !!selection}
            bind:value={text}
            on:focus={handleFocus}
            on:blur={handleBlur}
            on:keydown={handleKeydown} />
        {/if}
        {#if selected}
          <button class="cancel-button" on:click={cancelSelection}>
            <CancelIcon />
          </button>
        {:else if pending}
          <div
            class="text-amber-600 p-1 rounded hover:bg-amber-200 cursor-pointer"
            on:click={cancelSelection}>
            <CancelIcon />
          </div>
        {/if}
        {#if focused}
          <Popover container={inputContainer} fit>
            <ul class="w-full z-40 overflow-hidden text-sm">
              {#if results.length > 0}
                {#each results as result, index}
                  <li
                    class="px-2 py-1"
                    class:keyed={index === arrowCounter}
                    on:mousedown={() => select(result)}>
                    <div class="flex items-end gap-2">
                      <div class="truncate">{result.name}</div>
                      {#if result.description}
                        <div class="text-xs small-text truncate">{result.description}</div>
                      {/if}
                    </div>
                    {#if result.cta}
                      <div class="text-xs small-text truncate">
                        {result.cta}
                      </div>
                    {/if}
                  </li>
                {/each}
              {:else}
                <li class="px-2 py-1 text-gray-500">{noResultsMsg}</li>
              {/if}
            </ul>
          </Popover>
        {/if}
      </div>
    </div>
  </div>
  {#if pending}
    {#if errorMessage}
      <div class="text-xs mt-4">
        <ErrorMessage message={errorMessage} on:close={() => (errorMessage = null)} padding={false} />
      </div>
    {/if}
    <div class="flex py-2 pr-6">
      <div class="opacity-0 flex-none">
        <div class="py-1">
          <SearchIcon />
        </div>
      </div>
      <div class="grow ml-2 space-y-1 text-xs">
        {#each pendingData as input}
          <div class="flex gap-2 items-center">
            <div class="text-xs text-gray-500 w-24 flex-none">{input.label}</div>
            {#if input.type === "select"}
              <select
                class="grow min-w-0 max-w-full bg-amber-50 border border-amber-300 rounded p-1"
                value={get(selection?.values, input.prop)}
                on:change={(e) => updateSelectionProp(input.prop, e.target.value)}>
                {#each input.options as option}
                  <option value={option.value}>{option.label}</option>
                {/each}
              </select>
            {:else}
              <input
                type="text"
                class="grow bg-amber-50 border border-amber-300 rounded p-1"
                value={get(selection?.values, input.prop)}
                on:input={(e) => updateSelectionProp(input.prop, e.target.value)} />
            {/if}
          </div>
        {/each}
        <div class="text-right">
          <button
            class="btn btn-primary-alt btn-compact"
            on:click={confirmSelection}
            disabled={validate && !validate(selection)}>
            OK
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply w-full rounded relative bg-white;
    outline: none;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.selected):not(.pending):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }
      &.focused,
      &:focus {
        @apply ring-2 ring-inset ring-blue-500;
      }

      &.selected {
        @apply ring-2 ring-inset ring-blue-500 bg-blue-100;
      }

      .cancel-button {
        @apply text-blue-600 rounded cursor-pointer p-1;

        &:hover {
          @apply bg-blue-200;
        }
      }
    }

    &.has-inner-border {
      .input-div {
        @apply p-1 rounded border border-transparent;

        &:hover {
          @apply border-gray-300;
        }
      }
    }

    &.pending {
      @apply ring-2 ring-inset ring-amber-500 bg-amber-100;
    }

    .placeholder {
      @apply truncate text-gray-400;
    }

    .input {
      @apply min-w-0 grow w-full bg-transparent shrink truncate;
    }
  }

  .small-text {
    @apply text-gray-400;
  }

  li:hover,
  .keyed {
    @apply bg-blue-500 text-white;

    .small-text {
      @apply text-gray-200;
    }
  }
</style>
