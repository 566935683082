<script>
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";

  export let datum;

  $: status = getStatus(datum);

  function getStatus(datum) {
    if (datum.status === "canceled") return "canceled";
    if (!datum.sent_at) return "unsent";

    return null;
  }

  function items(count) {
    if (!count) return "No items";
    if (count === 0) return "No items";
    if (count === 1) return "1 item";
    return `${count} items`;
  }
</script>

<td>
  <div class="flex truncate items-center gap-2">
    <div class="truncate font-bold text-md">
      {datum.job_name}
    </div>
    <!-- <div class="text-gray-500 text-xs flex-none">
      {#if datum.group?.version}
        #{datum.group?.version}
      {/if}
    </div> -->
  </div>
</td>

<td>
  {datum.job_location || ""}
</td>

<td>
  {datum.seller_reference || ""}
</td>

<td>
  {items(datum.items_count)}
</td>

<td>
  {datum.sent_at ? formatHumanTime(datum.sent_at) : ""}
</td>

<td>
  <div class="italic">
    {#if status === "canceled"}
      Canceled
    {:else if datum.sent_at}
      Sent
    {:else}
      Unsent
    {/if}
  </div>
</td>

<style lang="scss">
  td {
    @apply text-sm px-2 py-2.5 truncate;
  }
</style>
