<script>
  import { createEventDispatcher } from "svelte";

  export let quantity = 1;

  const dispatch = createEventDispatcher();

  let value = quantity;

  $: update(quantity);

  function update(quantity) {
    value = quantity;
  }

  function updateQuantity(e) {
    const newValue = parseInt(e.target.value, 10);
    if (isNaN(newValue)) {
      e.target.value = value;
      return;
    }

    if (newValue < 0) {
      e.target.value = 0;
      return;
    }

    value = newValue;
  }

  function notify() {
    dispatch("input", { value });
  }

  function increment() {
    value += 1;
    dispatch("input", { value });
  }

  function decrement() {
    value -= 1;
    dispatch("input", { value });
  }
</script>

<div class="flex gap-1 items-center justify-end">
  <button on:click={decrement}> - </button>
  <div>
    <input type="text" size="1" bind:value on:input={updateQuantity} on:blur={notify} />
  </div>
  <button on:click={increment}> + </button>
</div>

<style lang="scss">
  input {
    background-color: none;
    outline: none;
    min-width: 0;
    width: 1.5rem;
    text-align: center;
    @apply border border-gray-300;
  }

  button {
    @apply rounded border border-transparent;
    padding: 0rem 0.25rem 0rem 0.25rem;

    &:hover {
      @apply border-gray-400;
    }
  }
</style>
