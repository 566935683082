import { writable, derived } from "svelte/store";
import { api } from "src/api";

import { storedWritable } from "./ui.js";
import createRecordList from "./record-list.js";
import {
  selected,
  sortList,
} from "@local/extensions/collections/sortable-list.js";
import { profile } from "./auth.js";
import { myRfqsOnly } from "./ui.js";

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

const ls = JSON.parse(localStorage.getItem("rfqs")) || {};
const stored = {
  selected_rfq_ids: [],
  ...ls,
};
localStorage.setItem("rfqs", JSON.stringify(stored));

export const rfqStr = `
  *,
  job:job_id(*,current_group:current_group_id(*,items!items_group_id_fkey(count))),
  assigned_to:assigned_to_id(*),
  notifications!notifications_quote_request_id_fkey(*),
  quotes!quotes_quote_request_id_fkey(*,superseded_quotes(*,sender:sender_id(*))),
  group:group_id(*,items!items_group_id_fkey(count),types!types_group_id_fkey(count))
`;

function createRfqs() {
  const { subscribe, load, loadOne, insert, unload, sort, updateOne } =
    createRecordList();

  let $rfqs;
  subscribe((value) => {
    $rfqs = value;
  });

  const rfqUpdate = async (payload) => {
    if (payload.eventType === "DELETE") {
      if ($rfqs[payload.old.id]) unload(payload.old.id);
    } else if (payload.eventType === "UPDATE") {
      if ($rfqs[payload.new.id]) {
        if (payload.new.archived_at) {
          unload(payload.new.id);
        } else {
          const { data } = await api
            .from("request_list_view")
            .select("*")
            .eq("id", payload.new.id)
            .eq("organization_id", $profile.organization_id)
            .single();

          if (data) loadOne(data);
        }
      } else if (
        payload.new.organization_id === $profile.organization_id &&
        !payload.new.archived_at
      ) {
        const { data } = await api
          .from("request_list_view")
          .select("*")
          .eq("id", payload.new.id)
          .eq("organization_id", $profile.organization_id)
          .single();

        if (data && !data.archived_at) loadOne(data);
      }
    } else if (
      payload.eventType === "INSERT" &&
      payload.new.organization_id === $profile.organization_id
    ) {
      const { data } = await api
        .from("request_list_view")
        .select("*")
        .eq("id", payload.new.id)
        .eq("organization_id", $profile.organization_id)
        .single();

      if (data) loadOne(data);
    }
  };

  let rfqSubscription;

  const notificationUpdate = async (payload) => {
    if ($rfqs[payload.new.quote_request_id]) {
      const { data } = await api
        .from("request_list_view")
        .select("*")
        .eq("id", payload.new.quote_request_id)
        .eq("organization_id", $profile.organization_id)
        .single();

      if (data) loadOne(data);
    }
  };

  let notificationSubscription;

  return {
    subscribe,
    load,
    loadOne,
    unload,
    sort,

    // Subscribe to DB changes
    async subscribeToDbUpdates() {
      if (rfqSubscription) rfqSubscription.unsubscribe();
      if (notificationSubscription) notificationSubscription.unsubscribe();

      rfqSubscription = api
        .channel("quote_request-changes")
        .on(
          "postgres_changes",
          {
            event: "DELETE",
            schema: "public",
            table: "quote_requests",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          rfqUpdate,
        )
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "quote_requests",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          rfqUpdate,
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "quote_requests",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          rfqUpdate,
        )
        .subscribe();

      notificationSubscription = api
        .channel("notification-changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "notifications",
            filter: `organization_id=eq.${$profile.organization_id}`,
          },
          notificationUpdate,
        )
        .subscribe();
    },

    // Retrieves records from DB
    async fetch() {
      try {
        let query = api
          .from("request_list_view")
          .select("*")
          .eq("organization_id", $profile.organization_id)
          .is("archived_at", null)
          .order("sent_at", { ascending: false, nullsFirst: true })
          .order("created_at", { ascending: false });

        if ($profile?.user_role === "product_user") {
          query = query.eq("project_type", "product");
        }

        const { data, error } = await query;

        if (error) throw error;
        load(data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchById(...ids) {
      try {
        const { data, error } = await api
          .from("request_list_view")
          .select("*")
          .in("id", ids);

        if (error) throw error;
        insert(data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchOne(rfqid, sortPosition) {
      try {
        const { data, error } = await api
          .from("request_list_view")
          .select("*")
          .eq("id", rfqid)
          .single();
        if (error) throw error;

        loadOne(data, sortPosition);
      } catch (error) {
        console.log(error);
      }
    },

    async add(rfq, sortPosition) {
      try {
        const { data, error } = await api
          .from("quote_requests")
          .insert(rfq)
          .select("*")
          .single();

        if (error) throw error;

        const { data: rfqData, error: re } = await api
          .from("request_list_view")
          .select("*")
          .eq("id", data.id)
          .single();

        if (re) throw re;
        loadOne(rfqData, sortPosition);
        return rfqData;
      } catch (error) {
        console.log(error);
      }
    },

    async remove(rfqid) {
      try {
        const { error } = await api
          .from("quote_requests")
          .delete()
          .eq("id", rfqid);

        if (error) throw error;
        unload(rfqid);
      } catch (error) {
        console.log(error);
      }
    },

    async archive(...ids) {
      try {
        const { error } = await api
          .from("quote_requests")
          .update({ archived_at: new Date() })
          .in("id", ids);

        if (error) throw error;

        unload(...ids);
      } catch (error) {
        console.log(error);
      }
    },

    async unarchive(rfqid) {
      try {
        const { error } = await api
          .from("quote_requests")
          .update({ archived_at: null })
          .eq("id", rfqid);

        if (error) throw error;

        const { data: rfqData, error: re } = await api
          .from("request_list_view")
          .select("*")
          .eq("id", rfqid)
          .single();

        if (re) throw re;
        loadOne(rfqData);
      } catch (error) {
        console.log(error);
      }
    },

    async spin(rfqid) {
      try {
        updateOne(rfqid, { spinning: true });
      } catch (error) {
        console.log(error);
      }
    },
  };
}

export const rfqs = createRfqs();

export async function fetchRfq(rfqid) {
  const { data: rfq, error } = await api
    .from("quote_requests")
    .select(
      `
      *,
      job:job_id(*),
      notifications!notifications_quote_request_id_fkey(*),
      quotes!quotes_quote_request_id_fkey(*,organization:organization_id(*),sender:sender_id(*),superseded_quotes(*,sender:sender_id(*))),
      group:group_id(*,items!items_group_id_fkey(*),types!types_group_id_fkey(*))
      `,
    )
    .eq("id", rfqid)
    .single();

  if (error) throw error;

  if (rfq.data.saved_recipients) {
    const orgs = rfq.data.saved_recipients
      .filter((recipient) => recipient.result?.type === "org")
      .map((recipient) => recipient.result.id);

    if (orgs.length) {
      const { data: recipientOrgs, error: re } = await api
        .from("organizations")
        .select("id,name,address,slug")
        .in("id", orgs);

      if (re) throw re;

      const orgMap = recipientOrgs.reduce((all, o) => {
        all[o.id] = o;
        return all;
      }, {});

      rfq.data.saved_recipients.forEach((recipient) => {
        if (recipient.result?.type === "org") {
          recipient.result.organization = orgMap[recipient.result.id];
        }
      });
    }
  }

  return rfq;
}

export const rfqFilter = writable("");
export const rfqSortBy = writable("sent_at");
export const rfqSortDirection = writable("desc");

function sortCategory(rfq) {
  if (rfq.status === "canceled") return 2;
  if (rfq.sent_at) return 3;
  return 1;
}

function mostRecent(quotes) {
  return quotes.reduce((r, quote) => {
    if (!r) return quote;
    return new Date(quote.sent_at) > new Date(r.sent_at) ? quote : r;
  }, null);
}

export function createFilteredRfqs(rfqs, profileId) {
  return derived(
    [rfqs, rfqFilter, rfqSortBy, rfqSortDirection, myRfqsOnly],
    ([$list, $filter, $sortBy, $sortDirection, $mineOnly]) => {
      const ids = $list?.order.filter((id) => {
        const rfq = $list[id];
        if (
          $mineOnly &&
          rfq.assigned_to_id &&
          rfq.assigned_to_id !== profileId
        ) {
          return false;
        }
        const str = $filter.toLowerCase();
        const name = (rfq.job_name || "").toLowerCase();
        const location = (rfq.job_location || "").toLowerCase();
        if (name.includes(str)) return true;
        if (location.includes(str)) return true;
        return false;
      });
      const f = selected($list, ids);

      sortList(f, {
        sortFunction: (a, b) => {
          const aCat = sortCategory(a);
          const bCat = sortCategory(b);

          if (aCat !== bCat) return aCat - bCat;

          if (aCat === 1 && bCat === 1) {
            return new Date(b.created_at) - new Date(a.created_at);
          }

          const aQuote = mostRecent((a.quotes || []).filter((q) => q.sent_at));
          const bQuote = mostRecent((b.quotes || []).filter((q) => q.sent_at));

          if (aQuote && bQuote)
            return new Date(bQuote.sent_at) - new Date(aQuote.sent_at);
          if (aQuote) return -1;
          if (bQuote) return 1;

          return new Date(b.sent_at) - new Date(a.sent_at);
        },
      });
      return f;
    },
  );
}

export const filteredRfqs = derived(
  [rfqs, rfqFilter, rfqSortBy, rfqSortDirection, myRfqsOnly, profile],
  ([$list, $filter, $sortBy, $sortDirection, $mineOnly, $profile]) => {
    const ids = $list?.order.filter((id) => {
      const rfq = $list[id];
      if (
        $mineOnly &&
        rfq.assigned_to_id &&
        rfq.assigned_to_id !== $profile.id
      ) {
        return false;
      }
      const str = $filter.toLowerCase();
      const name = (rfq.job_name || "").toLowerCase();
      const location = (rfq.job_location || "").toLowerCase();
      const seller_reference = (rfq.seller_reference || "").toLowerCase();
      if (name.includes(str)) return true;
      if (location.includes(str)) return true;
      if (seller_reference.includes(str)) return true;
      return false;
    });
    const f = selected($list, ids);

    sortList(f, {
      sortFunction: (a, b) => {
        const aCat = sortCategory(a);
        const bCat = sortCategory(b);

        if (aCat !== bCat) return aCat - bCat;

        if (aCat === 1 && bCat === 1) {
          return new Date(b.created_at) - new Date(a.created_at);
        }

        const aQuote = mostRecent((a.quotes || []).filter((q) => q.sent_at));
        const bQuote = mostRecent((b.quotes || []).filter((q) => q.sent_at));

        if (aQuote && bQuote)
          return new Date(bQuote.sent_at) - new Date(aQuote.sent_at);
        if (aQuote) return -1;
        if (bQuote) return 1;

        return new Date(b.sent_at) - new Date(a.sent_at);
      },
    });
    return f;
  },
);

export function draftList(filteredRfqs) {
  return derived(filteredRfqs, ($list) =>
    $list.order.filter((id) => !$list[id].sent_at).map((id) => $list[id]),
  );
}

export function sentList(filteredRfqs) {
  return derived(filteredRfqs, ($list) =>
    $list.order.filter((id) => $list[id].sent_at).map((id) => $list[id]),
  );
}

export function createRfqArray(filteredRfqs) {
  return derived(filteredRfqs, ($list) => $list.order.map((id) => $list[id]));
}

export const selectedRfqIds = storedWritable(
  "selected_rfq_ids",
  stored.selected_rfq_ids,
  "rfqs",
);
