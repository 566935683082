<svelte:options strictprops={false} />

<script>
  import { Link, navigate } from "svelte-routing";
  import { DimText, Dimension, Quantity } from "dimtext";
  import { tick, onMount, getContext } from "svelte";
  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";
  import get from "lodash/get";
  import set from "lodash/set";
  import merge from "lodash/merge";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import isEmpty from "lodash/isEmpty";
  import Mousetrap from "mousetrap";
  import { distSq, equal, subtract, add } from "vector";
  import { loadImage } from "isomorphs/canvas";

  import Viewport from "src/lib/drawing/Viewport.svelte";
  import DimtextInput from "src/lib/drawing/DimtextInput.svelte";
  import TextInput from "src/lib/drawing/TextInput.svelte";
  import SelectInput from "src/lib/drawing/SelectInput.svelte";
  import Attachments from "src/lib/sidebar/Attachments.svelte";
  import LocationThumbnailViewer from "src/lib/sidebar/LocationThumbnailViewer.svelte";
  import CaretLeftIcon from "src/assets/icons/caret-left.svg";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import EdgeProperties from "src/lib/sidebar/EdgeProperties.svelte";
  import VertexProperties from "src/lib/sidebar/VertexProperties.svelte";
  import HoleProperties from "src/lib/sidebar/HoleProperties.svelte";
  import BugProperties from "src/lib/sidebar/BugProperties.svelte";
  import RefPlaneProperties from "src/lib/sidebar/RefPlaneProperties.svelte";
  import FabricationInstanceProperties from "src/lib/sidebar/FabricationInstanceProperties.svelte";
  import NewHoleProperties from "src/lib/sidebar/NewHoleProperties.svelte";
  import CollectionItemList from "src/lib/sidebar/CollectionItemList.svelte";
  import NewFabricationInstanceProperties from "src/lib/sidebar/NewFabricationInstanceProperties.svelte";
  import GenericFeatureProperties from "src/lib/sidebar/GenericFeatureProperties.svelte";
  import History from "src/lib/sidebar/History.svelte";
  import ItemProperties from "src/lib/sidebar/ItemProperties.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";

  import {
    groupBy,
    groupCollectionsBy,
    currentTool,
    selectedItems,
    tempFeatureHole,
    tempFeatureEdgeFabrication,
    tempFeatureCornerFabrication,
    showRightPanel,
    locationContext,
    settingDisplayShowSpanningDims,
  } from "src/stores/ui.js";

  import { drawingSrc as getDrawingSrc } from "src/api";
  import liteDrawing from "@local/extensions/drawing/lite-drawing-detailed.js";
  import contiguousRanges from "@local/extensions/ranges/contiguous-ranges.js";
  import { findFlatIndex, findNestedIndex } from "@local/extensions/collections/find-flat-index.js";
  import orderCategory from "@local/extensions/collections/order-category.js";
  import groupItems from "@local/extensions/collections/group-items.js";
  import flattenGroups from "@local/extensions/collections/flatten-groups.js";
  import { updateItemCache, createFreeShape as freeShape } from "@local/lamina-core";
  import { snap, snapOrtho, snapEdge, snapEdgeOrtho } from "@local/extensions/geometry/snap.js";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import roundPt from "@local/extensions/parsers/round-point.js";
  import { roundValue } from "@local/extensions/parsers/round-value.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";
  import isRectangle from "@local/extensions/geometry/is-rectangle.js";
  import { nearestPointOnSegment } from "@local/extensions/geometry/nearest-point-on-line.js";
  import polyfaceContainsPoint from "@local/extensions/geometry/polyface-contains-point.js";
  import {
    nearestEdgeOffsetReference,
    edgeReference,
    nearestEdgeReference,
  } from "@local/extensions/geometry/edge-reference.js";
  import { nearestCornerReference } from "@local/extensions/geometry/corner-reference.js";
  import { isPlumb } from "@local/extensions/geometry/is-right-angle.js";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { edgeOffsetCenter, edgePoint } from "@local/extensions/geometry/edge-offset-center.js";

  export let group;
  export let types;
  export let items;
  export let collections;
  export let itemid;

  export let customColumns;
  export let customColColumns;
  export let standardColumns;
  export let isCollection = false;

  export let disabled = false;

  const SNAP_THRESHOLD = 20;
  const typeColors = getContext("typeColors");
  const supplier = getContext("supplier");
  const dt = new DimText();

  const featureProps = {
    vertex: VertexProperties,
    edge: EdgeProperties,
    void: HoleProperties,
    efab: FabricationInstanceProperties,
    cfab: FabricationInstanceProperties,
    bug: BugProperties,
    refplane: RefPlaneProperties,
    mixed: GenericFeatureProperties,
  };

  const newFeatureProps = {
    efab: NewFabricationInstanceProperties,
    cfab: NewFabricationInstanceProperties,
    void: NewHoleProperties,
  };

  const featureNames = {
    "feature-hole": "Hole",
    "feature-edge-fabrication": "Edge Fabrication",
  };

  let width;
  let height;
  let slide = "none";
  let selectedFeatures = [];
  let hoveredFeatures = [];
  let activeInput = null;
  let uploadedDrawing;
  let prevUploadedDrawing = uploadedDrawing;
  let refreshKey = crypto.randomUUID();
  let previd = itemid;

  // Necessary data for vertex-dragging
  let tempItem;
  let dragStart = null;
  let dragging = false;

  // Necessary data for vertex-adding
  let tempPt = null;
  let addingPt = null;

  // Necessary data for feature-adding
  let currentFeature = null;

  $: customCols = isCollection ? customColColumns : customColumns;
  $: gb = item?.is_collection ? groupCollectionsBy : groupBy;
  $: paddingX = width >= 640 ? 180 : 160;
  $: paddingY = width >= 640 ? 150 : 120;
  $: showingActiveTool = ["feature-hole", "feature-edge-fabrication", "feature-corner-fabrication"].includes(
    $currentTool,
  );
  $: sidebarPosition = getSidebarPosition(width, height);
  $: fabrications = orderedList($group.data.fabrications);
  $: item = $group.items[itemid];
  $: productProject = $group.project_type === "product";
  $: groupedItems = groupItems(item?.is_collection ? collections : items, $gb, $group);
  $: orderedCategory = orderCategory($gb, groupedItems, types, collections, $supplier?.product_categories);
  $: itemList = flattenGroups(groupedItems, orderedCategory);
  $: setSelected(itemid);
  $: redirect(item);
  $: updateTempItem(item);
  $: polyface = tempItem && new Polyface(tempItem, $group.data.fabrications);
  $: type = tempItem && $group.types[item.type_id];
  $: typeColor = $typeColors[item.type_id];
  $: comments = $group.comments.filter((c) => c.item_id === itemid);
  $: attachments = $group.attachments.filter((a) => a.item_id === itemid);
  $: itemIndex = itemList.findIndex((i) => i.id === itemid);
  $: nextItem = itemList[itemIndex + 1];
  $: prevItem = itemList[itemIndex - 1];
  $: selectedFeatureObj = makeFeatureObj(selectedFeatures);
  $: hoveredFeatureObj = makeFeatureObj(hoveredFeatures);
  $: selectedFeature = makeSelectedFeature(tempItem, selectedFeatures);
  $: highlights = tempItem && getHighlights(polyface, selectedFeatureObj, hoveredFeatureObj);
  $: ds = dimSettings($group.data.settings);
  $: sddp = $group.data.settings.decimal_precision;
  $: mmp = $group.data.settings.mm_precision;
  $: precision = ds.displayUnit === "millimeters" ? mmp : sddp;
  $: getUploadedDrawing(item);
  $: refresh(itemid, previd, uploadedDrawing, prevUploadedDrawing);
  $: dwgOptions = {
    ...ds,
    typeColor,
    context: $locationContext,
    highlights,
    tempPt: tempPt && tempPt.pt,
    showSpanningDims: $settingDisplayShowSpanningDims,
    showTickDims: true,
    labelBy: isCollection ? $group.data.settings.collections_label_by : $group.data.settings.label_by,
    currentFeature,
    drawing: uploadedDrawing,
    interactive: !disabled && !productProject,
    isCollection,
  };
  $: drawing = tempItem && liteDrawing(tempItem, type, $group.data.fabrications, dwgOptions);
  $: tempFeature = setTempFeature($currentTool);
  $: cornerFabs = polyface?.cornerFabs;
  $: childItems = items.filter((i) => i.collection_id === itemid);

  // TODO: support import of DXF corner fabrications

  function getSidebarPosition(width, height) {
    if (width >= 640) {
      return "right";
    }

    return height > width ? "bottom" : "right";
  }

  function refresh(itemid, previd, ud, pud) {
    if (itemid !== previd || ud !== pud) {
      refreshKey = crypto.randomUUID();
    }
  }

  function setSelected(itemid) {
    selectedItems.selectOnly(itemid);

    if ($locationContext?.record_id && $locationContext.record_id !== itemid) {
      locationContext.set(null);
    }
  }

  function setTempFeature(ct) {
    if (ct === "feature-hole") {
      return {
        type: "void",
        props: $tempFeatureHole,
      };
    } else if (ct === "feature-edge-fabrication") {
      return {
        type: "efab",
        props: $tempFeatureEdgeFabrication,
      };
    } else if (ct === "feature-corner-fabrication") {
      return {
        type: "cfab",
        props: $tempFeatureCornerFabrication,
      };
    }
  }

  function updateTempItem(item) {
    if (!item) return;
    tempItem = cloneDeep(item);
    tempItem.shape = freeShape(tempItem);
  }

  async function getUploadedDrawing(item) {
    prevUploadedDrawing = uploadedDrawing;
    if (!item.drawing) {
      uploadedDrawing = null;
      return;
    }

    const url = getDrawingSrc(item.drawing, { full: true });
    if (!url) {
      uploadedDrawing = null;
      return;
    }

    try {
      const img = await loadImage(url);
      uploadedDrawing = {
        image: img,
        width: img.width,
        height: img.height,
      };
    } catch (e) {
      uploadedDrawing = null;
    }
  }

  function makeFeatureObj(featureList) {
    return featureList.reduce(
      (obj, feat) => {
        if (["void", "efab", "cfab", "bug"].includes(feat.type)) {
          obj[feat.type][feat.index] = feat;
        } else if (feat.type === "refplane") {
          set(obj, `${feat.type}.${feat.class}.${feat.id}`, feat);
        } else {
          set(obj, `${feat.type}.${feat.loop}.${feat.index}`, feat);
        }

        return obj;
      },
      { edge: {}, vertex: {}, void: {}, efab: {}, cfab: {}, bug: {}, refplane: {} },
    );
  }

  function redirect(item) {
    if (!item) navigate(`../items`);
  }

  function cornerFabConstraint(fabs, loop, loopIndex, prevIndex, nextIndex) {
    if (fabs[loopIndex]) return "both";
    if (fabs[prevIndex] && fabs[nextIndex]) return "both";

    if (fabs[prevIndex]) {
      return loop[prevIndex].x === loop[loopIndex].x ? "vertical" : "horizontal";
    } else if (fabs[nextIndex]) {
      return loop[loopIndex].x === loop[nextIndex].x ? "vertical" : "horizontal";
    }

    return null;
  }

  function adjacentVertexConstrained(fabs, loop, loopIndex, prevIndex, nextIndex) {
    if (fabs[loopIndex]) return true;

    if (fabs[prevIndex]) {
      const corner = loop[prevIndex];
      const toDelete = loop[loopIndex];
      const opposite = loop[nextIndex];
      if (corner.y === toDelete.y && corner.y !== opposite.y) return true;
      if (corner.x === toDelete.x && corner.x !== opposite.x) return true;
    }

    if (fabs[nextIndex]) {
      const corner = loop[nextIndex];
      const toDelete = loop[loopIndex];
      const opposite = loop[prevIndex];
      if (corner.y === toDelete.y && corner.y !== opposite.y) return true;
      if (corner.x === toDelete.x && corner.x !== opposite.x) return true;
    }

    return false;
  }

  function makeSelectedFeature(item, selectedFeatures) {
    if (selectedFeatures.length === 0) return null;

    if (selectedFeatures.length === 1) {
      const feat = selectedFeatures[0];

      if (feat.type === "edge") {
        return {
          ...feat,
          title: `Edge ${feat.index + 1}`,
          treatment: item.data.fabrications?.edge_treatment?.[feat.id] || null,
        };
      } else if (feat.type === "vertex") {
        return {
          ...feat,
          title: `Vertex ${feat.index + 1}`,
          pt: polyface.shape[feat.loop][feat.index],
        };
      } else if (feat.type === "void") {
        return {
          ...feat,
          title: `Hole ${feat.index + 1}`,
          props: item.data.fabrications?.voids?.[feat.id] || null,
        };
      } else if (feat.type === "bug") {
        return {
          ...feat,
          title: "Tempered Stamp",
          props: item.data.fabrications?.bug || null,
        };
      } else if (feat.type === "efab") {
        return {
          ...feat,
          title: `Edge Fabrication ${feat.index + 1}`,
          props: item.data.fabrications?.edge?.[feat.id] || null,
        };
      } else if (feat.type === "cfab") {
        return {
          ...feat,
          title: `Corner Fabrication ${feat.index + 1}`,
          props: item.data.fabrications?.corner?.[feat.id] || null,
        };
      } else if (feat.type === "refplane") {
        return {
          ...feat,
          title: "Reference Plane",
          props: item.data.reference_planes[feat.id],
        };
      }
    }

    const indices = selectedFeatures.map((f) => findFlatIndex(polyface.shape, f.loop, f.index));
    const r = contiguousRanges(indices)
      .map((range) => {
        if (range[0] === range[1]) return `${range[0] + 1}`;
        return `${range[0] + 1}-${range[1] + 1}`;
      })
      .join(", ");

    const type = selectedFeatures[0].type;
    const similar = selectedFeatures.every((feat) => feat.type === type);
    if (similar) {
      if (type === "edge") {
        const first = indices[0];
        const rest = indices.slice(1);

        const treatment = rest.reduce(
          (t, edge) => {
            const e = get(item.data, ["fabrications", "edge_treatment", edge]) || null;
            if (e !== t) return "Mixed";
            return t;
          },
          get(item.data, ["fabrications", "edge_treatment", first]) || null,
        );

        return {
          type: "edge",
          title: `Edges ${r}`,
          index: indices,
          treatment,
        };
      } else if (type === "vertex") {
        const first = indices[0];
        const rest = indices.slice(1);
        const feat = findNestedIndex(polyface.shape, first);
        const pt = cloneDeep(polyface.shape[feat.loop][feat.index]);

        rest.forEach((i) => {
          const f = findNestedIndex(polyface.shape, i);
          const fpt = polyface.shape[f.loop][f.index];
          if (pt.x !== fpt.x) pt.x = "Mixed";
          if (pt.y !== fpt.y) pt.y = "Mixed";
          if (pt.fillet !== fpt.fillet) pt.fillet = "Mixed";
        });

        return {
          type: "vertex",
          title: `Vertices ${r}`,
          index: indices,
          pt,
        };
      } else if (type === "void") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.voids[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.voids[index];
          if (f.type !== p.type) {
            p.type = "Mixed";
          }

          if (!isEqual(p.diameter, f.diameter)) p.diameter = "Mixed";
          if (!isEqual(p.width, f.width)) p.width = "Mixed";
          if (!isEqual(p.height, f.height)) p.height = "Mixed";
          if (!isEqual(p.radius, f.radius)) p.radius = "Mixed";
          if (!isEqual(p.reference.length, f.reference.length)) p.reference.length = "Mixed";
          if (!isEqual(p.reference.offset, f.reference.offset)) p.reference.offset = "Mixed";

          return p;
        }, p);

        return {
          type: "void",
          title: `Holes ${r}`,
          index: indices,
          props,
        };
      } else if (type === "efab") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.edge[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.edge[index];

          if (p.fab_id !== f.fab_id) p.fab_id = "Mixed";
          if (!isEqual(p.reference.length, f.reference.length)) p.reference.length = "Mixed";

          return p;
        }, p);

        return {
          type: "efab",
          title: `Edge Fabrications ${r}`,
          index: indices,
          props,
        };
      } else if (type === "cfab") {
        const first = indices[0];
        const rest = indices.slice(1);
        const p = cloneDeep(item.data.fabrications.corner[first]);

        const props = rest.reduce((p, index) => {
          const f = item.data.fabrications.corner[index];

          if (p.fab_id !== f.type_id) p.fab_id = "Mixed";

          return p;
        }, p);

        return {
          type: "cfab",
          title: `Corner Fabrications ${r}`,
          index: indices,
          props,
        };
      } else if (type === "bug") {
        return {
          type: "bug",
          title: "Tempered Stamp",
          index: indices,
          props: {},
        };
      } else if (type === "refplane") {
        const ids = selectedFeatures.map((f) => f.id);

        return {
          type: "refplane",
          title: "Reference Planes",
          id: selectedFeatures.map((f) => f.id),
          props: {},
        };
      }
    } else {
      return {
        type: "mixed",
        title: "Mixed Features",
        index: indices,
      };
    }
  }

  function getHighlights(polyface, sfo, hfo) {
    const edges = polyface.edges;

    const edgeHighlights = edges.map((loop, l) => {
      return loop
        .map((e, index) => index)
        .filter((index) => {
          if (sfo.edge[l]?.[index]) return true;
          if (hfo.edge[l]?.[index]) return true;
          return false;
        });
    });

    const vertexHighlights = polyface.shape.map((loop, l) => {
      return loop
        .map((v, index) => index)
        .filter((index) => {
          if (sfo.vertex[l]?.[index]) return true;
          if (hfo.vertex[l]?.[index]) return true;
          return false;
        });
    });

    const voids = Object.keys({
      ...sfo.void,
      ...hfo.void,
    })
      .map((i) => ({ ...polyface.voids[i], id: i }))
      .filter((i) => polyface.voids[i.id]);

    const efabs = Object.keys({
      ...sfo.efab,
      ...hfo.efab,
    })
      .map((i) => ({ ...polyface.edgeFabrications[i], id: i }))
      .filter((i) => polyface.edgeFabrications[i.id]);

    const cfabs = Object.keys({
      ...sfo.cfab,
      ...hfo.cfab,
    })
      .map((i) => ({ ...polyface.cornerFabrications[i], id: i }))
      .filter((i) => polyface.cornerFabrications[i.id]);

    const refplanes =
      (!isEmpty(sfo.refplane) || !isEmpty(hfo.refplane)) && merge({}, sfo.refplane, hfo.refplane);

    const bug = (!isEmpty(sfo.bug) || !isEmpty(hfo.bug)) && polyface.surfaceFabrications[0];

    return { edges: edgeHighlights, vertices: vertexHighlights, voids, efabs, cfabs, refplanes, bug };
  }

  function updateItem(prop, value, diff) {
    const id = item.id;
    group.updateItem(id, prop, value, diff);
  }

  function gotoNext() {
    slide = "left";
    navigate(`./${nextItem.id}`);
  }

  function gotoPrev() {
    slide = "right";
    navigate(`./${prevItem.id}`);
  }

  function slideAnimation(node, options) {
    if (slide === "none") return null;

    if (options.type === "in") {
      return fly(node, {
        x: slide === "right" ? -50 : 50,
        delay: 100,
        easing: linear,
        duration: 100,
      });
    }

    return fly(node, {
      x: slide === "right" ? 50 : -50,
      easing: linear,
      duration: 100,
    });
  }

  function makeTypeOptionMap(types) {
    return types.reduce((o, t) => {
      o[t.id] = `${t.mark}${t.name && ` - ${t.name}`}`;
      return o;
    }, {});
  }

  function makeTypeIds(types) {
    return types.map((t) => t.id);
  }

  function mouseoverEdges(e) {
    const edges = e.detail;
    const indices = polyface.shape.map((loop, l) => loop.map((edge, e) => ({ l, e }))).flat();
    hoveredFeatures = edges.map((i) => ({ type: "edge", index: indices[i].e, loop: indices[i].l }));
  }

  function mouseoutEdges(e) {
    hoveredFeatures = [];
  }

  function deselectFeatures() {
    selectedFeatures = [];
    hoveredFeatures = [];
    activeInput = null;
  }

  function updateSize(i, deleteShape = false) {
    const pf = new Polyface(i, $group.data.fabrications);
    const bbox = pf.bbox;
    const w = bbox.width - item.cache.width_offset_in;
    const h = bbox.height - item.cache.height_offset_in;

    i.width = dt.parse(w.toString()).value;
    i.height = dt.parse(h.toString()).value;

    // If, after dragging points, we have a rectangle, we can
    // delete the vertices and just rely on the width/height
    // values.
    if (isRectangle(i.shape) && deleteShape) {
      i.rectangle_offset = { x: bbox.xmin, y: bbox.ymin };
      i.shape = { type: "rect" };
    }
  }

  // Update feature data to align w/ vertex removal
  function removeEdge(item, vf, pt) {
    const vertices = item.shape.vertices;
    if (item.data.fabrications?.edge_treatment) {
      const flatindex = findFlatIndex(vertices, vf.loop, vf.index);
      item.data.fabrications.edge_treatment.splice(flatindex, 1);
    }

    if (item.data.fabrications?.voids) {
      // Remove voids that were hosted on an edge that will be disappearing
      item.data.fabrications.voids = item.data.fabrications.voids.filter((f) => {
        if (f.reference.edge === vf.index && f.reference.start === "start") return false;
        if (
          f.reference.edge === (vf.index - 1 + vertices[vf.loop].length) % vertices[vf.loop].length &&
          f.reference.start === "end"
        )
          return false;
        return true;
      });

      // Shift void fabrications as necessary
      item.data.fabrications.voids.forEach((f) => {
        if (f.reference.edge >= vf.index) {
          f.reference.edge -= 1;
        }
      });
    }

    if (item.data.fabrications?.edge) {
      // Remove edge fabrications that were hosted on an edge that will be disappearing
      item.data.fabrications.edge = item.data.fabrications.edge.filter((f) => {
        if (f.reference.edge === vf.index && f.reference.start === "start") return false;
        if (
          f.reference.edge === (vf.index - 1 + vertices[vf.loop].length) % vertices[vf.loop].length &&
          f.reference.start === "end"
        )
          return false;
        return true;
      });

      // Shift edge fabrications as necessary
      item.data.fabrications.edge.forEach((f) => {
        if (f.reference.edge >= vf.index) {
          f.reference.edge -= 1;
        }
      });
    }

    item.data.fabrications?.corner?.forEach((f) => {
      if (f.reference.corner > vf.index) {
        f.reference.corner -= 1;
      }
    });
  }

  // Update feature data to align w/ vertex addition
  function addEdge(item, vf, splitPoint) {
    const vertices = item.shape.vertices;

    if (item.data.fabrications?.edge_treatment) {
      const flatindex = findFlatIndex(vertices, vf.loop, vf.index);
      const val = item.data.fabrications.edge_treatment[flatindex];
      item.data.fabrications.edge_treatment.splice(flatindex, 0, val);
    }

    const d = distSq(splitPoint, vertices[vf.loop][vf.index]);
    const edge = {
      start: vertices[vf.loop][vf.index],
      end: vertices[vf.loop][(vf.index + 2) % vertices[vf.loop].length],
    };

    item.data.fabrications?.voids?.forEach((f) => {
      if (f.reference.edge === vf.index) {
        const fpt = edgePoint(edge, f.reference);
        const fd = distSq(fpt, vertices[vf.loop][vf.index]);

        if (fd > d) f.reference.edge += 1;
      } else if (f.reference.edge > vf.index) {
        f.reference.edge += 1;
      }
    });

    item.data.fabrications?.edge?.forEach((f) => {
      if (f.reference.edge === vf.index) {
        const fpt = edgePoint(edge, f.reference);
        const fd = distSq(fpt, vertices[vf.loop][vf.index]);

        if (fd > d) f.reference.edge += 1;
      } else if (f.reference.edge > vf.index) {
        f.reference.edge += 1;
      }
    });

    item.data.fabrications?.corner?.forEach((f) => {
      if (f.reference.corner > vf.index) {
        f.reference.corner += 1;
      }
    });
  }

  async function handleVpClick(e) {
    const { hitbox: hb, shiftKey, pt, dim } = e.detail;

    // handle feature selection
    if ($currentTool === "select") {
      if (!hb) return deselectFeatures();
      await tick();

      const hbMatch = hb.name.match(
        /^(edge|vertex|dim|dimonly|dtdim|mark|type|void|efab|cfab|bug|refplane)_([^|]+)\|?(.+)?$/,
      );
      if (!hbMatch) return deselectFeatures();
      const hitbox = hb.name;
      const type = hbMatch[1];
      const id = hbMatch[2];
      const path = hbMatch[3];

      switch (type) {
        case "vertex":
        case "edge":
        case "void":
        case "efab":
        case "cfab":
        case "bug":
        case "refplane":
          selectFeature(type, id, shiftKey);
          break;
        case "dtdim":
        case "dimonly":
        case "dim":
          activeInput = { hitbox, id, pt, type, dim, path };
          break;
        case "type":
        case "mark":
          activeInput = { hitbox, id, pt, type };
          break;
      }

      // handle point removal
    } else if ($currentTool === "remove-point") {
      if (!hb) return;
      deselectFeatures();
      await tick();
      const feat = featHitbox(hb.name);
      if (feat?.type !== "vertex") return;
      const i = cloneDeep(item);
      const shape = freeShape(i);
      i.shape = shape;
      if (shape.vertices[feat.loop].length <= 3) return;

      // Don't allow point removal if it will make an adjacent corner fab out-of-plumb
      const loop = shape.vertices[feat.loop];
      const prevIndex = (feat.index + loop.length - 1) % loop.length;
      const nextIndex = (feat.index + 1) % loop.length;
      if (adjacentVertexConstrained(cornerFabs[feat.loop], loop, feat.index, prevIndex, nextIndex)) return;

      const pt = loop[feat.index];
      shape.vertices[feat.loop] = shape.vertices[feat.loop].filter((v, i) => feat.index !== i);
      removeEdge(i, feat, pt);
      updateSize(i, true);

      group.updateItem(itemid, {
        rectangle_offset: i.rectangle_offset,
        shape: i.shape,
        width: i.width,
        height: i.height,
        data: i.data,
      });

      // handle edge fabrication addition
    } else if ($currentTool === "feature-edge-fabrication" && currentFeature) {
      const data = cloneDeep(item.data);
      if (!data?.fabrications?.edge) {
        set(data, "fabrications.edge", []);
      }
      data.fabrications.edge.push(currentFeature);
      group.updateItem(itemid, { data });

      // handle corner fabrication addition
    } else if ($currentTool === "feature-corner-fabrication" && currentFeature) {
      const data = cloneDeep(item.data);
      if (!data?.fabrications?.corner) {
        set(data, "fabrications.corner", []);
      }
      data.fabrications.corner.push(currentFeature);
      group.updateItem(itemid, { data });

      // handle hole addition
    } else if ($currentTool === "feature-hole" && currentFeature) {
      const data = cloneDeep(item.data);
      if (!data?.fabrications?.voids) {
        set(data, "fabrications.voids", []);
      }
      data.fabrications.voids.push(currentFeature);
      group.updateItem(itemid, { data });
    }
  }

  function selectFeature(type, id, shiftKey) {
    const match = id.match(/([0-9]+|[A-Za-z]+)_?([0-9]+|[A-Za-z]+)?/);
    let loop;
    let index;
    let cls;
    if (["void", "efab", "cfab"].includes(type)) {
      index = parseInt(match[1]);
    } else if (type === "refplane") {
      cls = match[1];
      id = match[2];
    } else {
      loop = parseInt(match[1]);
      index = parseInt(match[2]);
    }
    const feat = { type, loop, index, class: cls, id };

    if (shiftKey) {
      const isSelected = ["void", "efab", "cfab", "bug"].includes(type)
        ? selectedFeatureObj[type][index]
        : selectedFeatureObj[type][loop]?.[index];

      if (isSelected) {
        selectedFeatures = selectedFeatures.filter(
          (f) => f.type === type && !(f.loop === loop && f.index === index),
        );
      } else {
        selectedFeatures = [feat, ...selectedFeatures].sort((a, b) => {
          if (a.loop < b.loop) return -1;
          if (a.loop > b.loop) return 1;
          return a.index - b.index;
        });
      }
    } else {
      selectedFeatures = [feat];
    }
  }

  function featHitbox(hb) {
    if (typeof hb !== "string") return null;
    if (hb === "shape") return { type: "shape" };
    const feat = hb.match(
      /(edge|vertex|void|efab|cfab|bug|refplane)_(box|cross|[0-9]+)_?([0-9]+|[A-Za-z]+)?/,
    );

    if (!feat) return null;

    if (["void", "efab", "cfab", "bug"].includes(feat[1])) {
      return {
        type: feat[1],
        index: parseInt(feat[2]),
        id: `${feat[2]}`,
      };
    }

    if (feat[1] === "refplane") {
      return {
        type: "refplane",
        class: feat[2],
        id: feat[3],
      };
    }

    return {
      type: feat[1],
      loop: parseInt(feat[2]),
      index: parseInt(feat[3]),
      id: `${feat[2]}_${feat[3]}`,
    };
  }

  function handleVpMouseover(e) {
    const { hitbox, dwgPt } = e.detail;
    if (!hitbox) return;

    const feat = featHitbox(hitbox.name);
    if (!feat) return;

    if ($currentTool === "select") {
      hoveredFeatures = [feat];
    } else if ($currentTool === "add-point") {
      if (feat.type === "edge") {
        const shape = freeShape(item);
        const l = shape.vertices[feat.loop];
        const a = l[feat.index];
        const b = l[(feat.index + 1) % l.length];
        const nearest = nearestPointOnSegment(a, b, dwgPt);
        if (!equal(nearest, a) && !equal(nearest, b)) {
          tempPt = {
            loop: feat.loop,
            edgeIndex: feat.index,
            pt: nearest,
          };
        } else {
          tempPt = null;
        }
      }
    } else if ($currentTool === "remove-point") {
      if (feat.type === "vertex") {
        hoveredFeatures = [feat];
      }
    }
  }

  function handleVpMousemove(e) {
    const { hitbox, dwgPt } = e.detail;

    if (!hitbox) {
      currentFeature = null;
      tempPt = null;
      return;
    }

    const feat = featHitbox(hitbox.name);
    if (!feat) {
      currentFeature = null;
      tempPt = null;
      return;
    }

    if ($currentTool === "feature-edge-fabrication" && feat.type === "edge") {
      const edges = polyface.edges;
      const edge = edges[feat.loop][feat.index];
      if (edge.end.bulge) {
        currentFeature = null;
      } else {
        const reference = edgeReference(feat.loop, feat.index, edges, dwgPt, precision, ds.displayUnit);
        currentFeature = {
          type: "edge-fabrication",
          fab_id: $tempFeatureEdgeFabrication.fab_id,
          reference,
        };
      }
    } else if ($currentTool === "feature-corner-fabrication") {
      const reference = nearestCornerReference(0, polyface.shape, dwgPt);
      const index = reference.corner;
      const loop = polyface.shape[0];
      const pt = loop[index];
      const prev = loop[(index + loop.length - 1) % loop.length];
      const next = loop[(index + 1) % loop.length];

      if (isPlumb(prev, pt, next) && distSq(dwgPt, pt) < 150 && !cornerFabs[0][index]) {
        currentFeature = {
          type: "corner-fabrication",
          fab_id: $tempFeatureCornerFabrication.fab_id,
          reference,
        };
      } else {
        currentFeature = null;
      }
    } else if ($currentTool === "feature-hole" && feat.type === "shape") {
      const reference = nearestEdgeOffsetReference(0, polyface.edges, dwgPt, precision, ds.displayUnit);
      if ($tempFeatureHole?.type === "circular-hole") {
        currentFeature = {
          type: $tempFeatureHole.type,
          diameter: $tempFeatureHole.diameter,
          reference,
        };
      } else if ($tempFeatureHole?.type === "rectangular-hole") {
        currentFeature = {
          type: $tempFeatureHole.type,
          width: $tempFeatureHole.width,
          height: $tempFeatureHole.height,
          radius: $tempFeatureHole.radius,
          alignment: $tempFeatureHole.alignment,
          orientation: $tempFeatureHole.orientation,
          reference,
        };
      }
    } else if ($currentTool === "add-point" && feat.type === "edge") {
      const shape = freeShape(item);
      const l = shape.vertices[feat.loop];
      const a = l[feat.index];
      const b = l[(feat.index + 1) % l.length];
      const nearest = nearestPointOnSegment(a, b, dwgPt);
      if (!equal(nearest, a) && !equal(nearest, b)) {
        tempPt = {
          loop: feat.loop,
          edgeIndex: feat.index,
          pt: nearest,
        };
      } else {
        tempPt = null;
      }
    } else {
      currentFeature = null;
      tempPt = null;
    }
  }

  function isHighlighted(feature) {
    if (feature.type === "edge") {
      return dwgOptions.highlights.edges[feature.loop]?.includes(feature.index);
    } else if (feature.type === "vertex") {
      return dwgOptions.highlights.vertices[feature.loop]?.includes(feature.index);
    } else if (["void", "efab", "cfab", "bug"].includes(feature.type)) {
      return (
        hoveredFeatureObj[feature.type][feature.index] || selectedFeatureObj[feature.type][feature.index]
      );
    } else if (feature.type === "refplane") {
      return hoveredFeatureObj.refplane?.[feature.class]?.[feature.id];
    }
  }

  function handleVpMouseout(e) {
    const { hitbox } = e.detail;
    if (!hitbox) return;

    const feat = featHitbox(hitbox.name);
    if (!feat) return;

    if (isHighlighted(feat)) hoveredFeatures = [];

    if ($currentTool === "add-point") {
      if (feat.type === "edge" && tempPt && feat.loop === tempPt.loop && feat.index === tempPt.edgeIndex) {
        tempPt = null;
      }
    }
  }

  function handleVpDragstart(e) {
    if (disabled) return;
    if (productProject) return;
    const { hitbox, dwgPt } = e.detail;
    const feat = featHitbox(hitbox.name);

    if ($currentTool === "select" && feat) {
      // drag an existing vertex
      if (feat.type === "vertex") {
        dragStart = {
          ...e.detail,
          type: feat.type,
          from: tempItem.shape.vertices[0][feat.index],
        };
      }

      // drag an edge
      else if (feat.type === "edge") {
        dragStart = {
          ...e.detail,
          type: feat.type,
          from: [
            polyface.shape[feat.loop][feat.index],
            polyface.shape[feat.loop][(feat.index + 1) % polyface.shape[feat.loop].length],
          ],
        };
      }

      // drag a hole
      else if (feat.type === "void") {
        const fab = tempItem.data.fabrications.voids[feat.index];
        const vertices = polyface.shape[0];
        const edge = {
          start: vertices[fab.reference.edge],
          end: vertices[(fab.reference.edge + 1) % vertices.length],
        };
        const { center } = edgeOffsetCenter(edge, fab.reference);
        dragStart = {
          ...e.detail,
          type: feat.type,
          from: center,
        };
      }

      // drag an edge fabrication
      else if (feat.type === "efab") {
        const fab = tempItem.data.fabrications.edge[feat.index];
        const vertices = polyface.shape[0];
        const edge = {
          start: vertices[fab.reference.edge],
          end: vertices[(fab.reference.edge + 1) % vertices.length],
        };
        const point = edgePoint(edge, fab.reference);
        dragStart = {
          ...e.detail,
          type: feat.type,
          from: point,
        };
      }

      // drag a corner fabrication
      else if (feat.type === "cfab") {
        dragStart = {
          ...e.detail,
          type: feat.type,
        };
      }

      // drag a reference plane
      else if (feat.type === "refplane") {
        let vertices;

        // Handle "box"-style reference planes
        if (tempItem.data.reference_planes.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          const mx = x1 + (x2 - x1) / 2;
          const my = y1 + (y2 - y1) / 2;

          vertices = [];

          if (feat.id === "top") {
            tempItem.shape.vertices.forEach((loop, li) => {
              loop.forEach((v, vi) => {
                if (v.y >= my) {
                  vertices.push([li, vi]);
                }
              });
            });
          } else if (feat.id === "right") {
            tempItem.shape.vertices.forEach((loop, li) => {
              loop.forEach((v, vi) => {
                if (v.x >= mx) {
                  vertices.push([li, vi]);
                }
              });
            });
          } else if (feat.id === "bottom") {
            tempItem.shape.vertices.forEach((loop, li) => {
              loop.forEach((v, vi) => {
                if (v.y < my) {
                  vertices.push([li, vi]);
                }
              });
            });
          } else if (feat.id === "left") {
            tempItem.shape.vertices.forEach((loop, li) => {
              loop.forEach((v, vi) => {
                if (v.x < mx) {
                  vertices.push([li, vi]);
                }
              });
            });
          }
        }

        dragStart = {
          ...e.detail,
          vertices,
          type: feat.type,
        };
      }

      // drag the bug
      else if (feat.type === "bug") {
        const fab = tempItem.data.fabrications.bug;
        const vertices = polyface.shape[fab.reference.loop];
        const edge = {
          start: vertices[fab.reference.edge],
          end: vertices[(fab.reference.edge + 1) % vertices.length],
        };
        const { center } = edgeOffsetCenter(edge, fab.reference);

        dragStart = {
          ...e.detail,
          type: feat.type,
          from: center,
        };
      }
    }

    // add a new vertex to an edge
    else if ($currentTool === "add-point") {
      tempPt = null;
      if (feat && feat.type === "edge") {
        tempItem.shape = freeShape(tempItem);
        const l = tempItem.shape.vertices[feat.loop];
        const a = l[feat.index];
        const b = l[(feat.index + 1) % l.length];
        const nearest = nearestPointOnSegment(a, b, dwgPt);
        if (!equal(nearest, a) && !equal(nearest, b)) {
          addingPt = {
            edgeIndex: feat.index,
            pt: nearest,
            orig: nearest,
          };

          l.splice(feat.index + 1, 0, nearest);
          addEdge(tempItem, feat, nearest);
          tempItem = tempItem;
        } else {
          addingPt = null;
        }
      }
    }
  }

  function handleVpDrag(e) {
    // drag an existing vertex
    if ($currentTool === "select") {
      if (!dragStart) return;
      const d = distSq(e.detail.screenPt, dragStart.screenPt);
      if (d > 100) dragging = true;

      if (dragging && dragStart.type === "vertex") {
        const feat = featHitbox(dragStart.hitbox.name);
        const shape = tempItem.shape;
        const l = shape.vertices[0];
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        const to = add(dragStart.from, diff);

        let snapped;
        const prevIndex = (feat.index - 1 + l.length) % l.length;
        const nextIndex = (feat.index + 1) % l.length;
        const fc = cornerFabConstraint(cornerFabs[0], l, feat.index, prevIndex, nextIndex);

        const hplanes = [];
        const vplanes = [];
        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        if (e.detail.shiftKey) {
          snapped = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, fc, hplanes, vplanes);
        } else {
          snapped = snap(
            to,
            dragStart.from,
            l[prevIndex],
            l[nextIndex],
            SNAP_THRESHOLD / e.detail.scale,
            fc,
            hplanes,
            vplanes,
          );
        }

        // Ensure we don't delete bulge property when dragging
        shape.vertices[0][feat.index] = {
          ...shape.vertices[0][feat.index],
          ...snapped,
        };
        updateItemCache(tempItem, $group);
        tempItem = tempItem;
      } else if (dragging && dragStart.type === "edge") {
        const feat = featHitbox(dragStart.hitbox.name);
        const shape = tempItem.shape;
        const l = shape.vertices[feat.loop];
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);

        const to = [add(dragStart.from[0], diff), add(dragStart.from[1], diff)];

        const ai = feat.index;
        const pi = (ai - 1 + l.length) % l.length;
        const bi = (ai + 1) % l.length;
        const ni = (ai + 2) % l.length;

        let snapped;
        const fca = cornerFabConstraint(cornerFabs[feat.loop], l, ai, pi, bi);
        const fcb = cornerFabConstraint(cornerFabs[feat.loop], l, bi, ai, ni);

        const hplanes = [];
        const vplanes = [];

        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        if (e.detail.shiftKey) {
          snapped = snapEdgeOrtho(
            to,
            dragStart.from,
            SNAP_THRESHOLD / e.detail.scale,
            fca,
            fcb,
            hplanes,
            vplanes,
          );
        } else {
          snapped = snapEdge(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, fca, fcb, hplanes, vplanes);
        }

        shape.vertices[feat.loop][ai] = {
          ...shape.vertices[feat.loop][ai],
          ...snapped[0],
        };

        shape.vertices[feat.loop][bi] = {
          ...shape.vertices[feat.loop][bi],
          ...snapped[1],
        };
        updateItemCache(tempItem, $group);
        tempItem = tempItem;
      } else if (dragging && dragStart.type === "void") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        let to = add(dragStart.from, diff);

        if (e.detail.shiftKey) {
          to = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, null);
        }

        if (polyfaceContainsPoint(polyface, to)) {
          const reference = nearestEdgeOffsetReference(0, polyface.edges, to, precision, ds.displayUnit);
          tempItem.data.fabrications.voids[feat.index].reference = reference;
        }

        tempItem = tempItem;
      } else if (dragging && dragStart.type === "efab") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        const to = add(dragStart.from, diff);

        const reference = nearestEdgeReference(polyface.edges, to, precision, ds.displayUnit);
        tempItem.data.fabrications.edge[feat.index].reference = reference;
        tempItem = tempItem;
      } else if (dragging && dragStart.type === "cfab") {
        const feat = featHitbox(dragStart.hitbox.name);
        const reference = nearestCornerReference(0, polyface.shape, e.detail.dwgPt);
        tempItem.data.fabrications.corner[feat.index].reference = reference;
        tempItem = tempItem;
      } else if (dragging && dragStart.type === "refplane") {
        const feat = featHitbox(dragStart.hitbox.name);

        if (feat.class === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          const v = ["left", "right"].includes(feat.id) ? e.detail.dwgPt.x : e.detail.dwgPt.y;

          if (feat.id === "top") {
            const dy = v - y2;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].y += dy;
            });
          } else if (feat.id === "right") {
            const dx = v - x2;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].x += dx;
            });
          } else if (feat.id === "bottom") {
            const dy = v - y1;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].y += dy;
            });
          } else if (feat.id === "left") {
            const dx = v - x1;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].x += dx;
            });
          }

          const val = new Dimension(new Quantity(v, "inches"));
          tempItem.data.reference_planes[feat.id].value = val;
        } else if (feat.class === "cross") {
          const v = feat.id === "horizontal" ? e.detail.dwgPt.y : e.detail.dwgPt.x;
          const val = new Dimension(new Quantity(v, "inches"));
          set(tempItem, `data.reference_planes.${feat.id}.value`, val);
          tempItem = tempItem;
        }

        tempItem = tempItem;
      } else if (dragging && dragStart.type === "bug") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        let to = add(dragStart.from, diff);

        if (e.detail.shiftKey) {
          to = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, null);
        }

        if (polyfaceContainsPoint(polyface, to)) {
          const reference = nearestEdgeOffsetReference(0, polyface.edges, to, precision, ds.displayUnit);
          tempItem.data.fabrications.bug.reference = reference;
        }
      }

      // add a new vertex to shape
    } else if ($currentTool === "add-point") {
      if (addingPt) {
        const l = tempItem.shape.vertices[0];
        const prevIndex = addingPt.edgeIndex;
        const currentIndex = (addingPt.edgeIndex + 1) % l.length;
        const nextIndex = (addingPt.edgeIndex + 2) % l.length;
        const a = l[prevIndex];
        const b = l[nextIndex];
        const fc = cornerFabConstraint(cornerFabs[0], l, currentIndex, prevIndex, nextIndex);

        const hplanes = [];
        const vplanes = [];
        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        const snapped = snap(
          e.detail.dwgPt,
          addingPt.orig,
          a,
          b,
          SNAP_THRESHOLD / e.detail.scale,
          fc,
          hplanes,
          vplanes,
        );
        const nearest = nearestPointOnSegment(a, b, e.detail.dwgPt);
        const d = distSq(nearest, e.detail.dwgPt);

        const pt =
          d > SNAP_THRESHOLD / e.detail.scale || equal(nearest, a) || equal(nearest, b) ? snapped : nearest;

        l[addingPt.edgeIndex + 1] = pt;
        addingPt.pt = pt;
        updateItemCache(tempItem, $group);
        tempItem = tempItem;
      }
    }
  }

  function handleVpDragend(e) {
    // Finish dragging a point
    if ($currentTool === "select") {
      if (dragging && dragStart.type === "vertex") {
        const feat = featHitbox(dragStart.hitbox.name);
        const l = tempItem.shape.vertices[0];
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        const to = add(dragStart.from, diff);

        let snapped;
        const prevIndex = (feat.index - 1 + l.length) % l.length;
        const nextIndex = (feat.index + 1) % l.length;
        let fc = cornerFabConstraint(cornerFabs[0], l, feat.index, prevIndex, nextIndex);

        const hplanes = [];
        const vplanes = [];

        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        if (e.detail.shiftKey) {
          snapped = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, fc, hplanes, vplanes);
        } else {
          snapped = snap(
            to,
            dragStart.from,
            l[prevIndex],
            l[nextIndex],
            SNAP_THRESHOLD / e.detail.scale,
            fc,
            hplanes,
            vplanes,
          );
        }

        const pt = { x: snapped.x, y: snapped.y };
        if (to.x === snapped.x) {
          const prev = l[prevIndex];
          const next = l[nextIndex];
          if (to.x !== prev.x && to.x !== next.x) {
            pt.x = roundValue(to.x, sddp);
          }
        }

        if (to.y === snapped.y) {
          const prev = l[prevIndex];
          const next = l[nextIndex];
          if (to.y !== prev.y && to.y !== next.y) {
            pt.y = roundValue(to.y, sddp);
          }
        }

        tempItem.shape.vertices[0][feat.index] = {
          ...tempItem.shape.vertices[0][feat.index],
          ...pt,
        };
        updateSize(tempItem);
        const data = cloneDeep(item.data);
        let shape = tempItem.shape;
        let rectangle_offset = tempItem.rectangle_offset;

        if (isRectangle(shape)) {
          const { xmin, ymin } = polyface.bbox;
          rectangle_offset = { x: xmin, y: ymin };
          shape = { type: "rect" };
        }

        group.updateItem(itemid, {
          rectangle_offset,
          width: tempItem.width,
          height: tempItem.height,
          data,
          shape,
        });
      } else if (dragging && dragStart.type === "edge") {
        const feat = featHitbox(dragStart.hitbox.name);
        const l = tempItem.shape.vertices[feat.loop];
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);

        const to = [add(dragStart.from[0], diff), add(dragStart.from[1], diff)];

        const ai = feat.index;
        const pi = (ai - 1 + l.length) % l.length;
        const bi = (ai + 1) % l.length;
        const ni = (ai + 2) % l.length;

        let snapped;
        const fca = cornerFabConstraint(cornerFabs[feat.loop], l, ai, pi, bi);
        const fcb = cornerFabConstraint(cornerFabs[feat.loop], l, bi, ai, ni);

        const hplanes = [];
        const vplanes = [];

        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        if (e.detail.shiftKey) {
          snapped = snapEdgeOrtho(
            to,
            dragStart.from,
            SNAP_THRESHOLD / e.detail.scale,
            fca,
            fcb,
            hplanes,
            vplanes,
          );
        } else {
          snapped = snapEdge(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, fca, fcb, hplanes, vplanes);
        }

        tempItem.shape.vertices[feat.loop][ai] = {
          ...tempItem.shape.vertices[feat.loop][ai],
          ...snapped[0],
        };

        tempItem.shape.vertices[feat.loop][bi] = {
          ...tempItem.shape.vertices[feat.loop][bi],
          ...snapped[1],
        };
        updateSize(tempItem);
        const data = cloneDeep(item.data);
        let shape = tempItem.shape;
        let rectangle_offset = tempItem.rectangle_offset;

        if (isRectangle(shape)) {
          const { xmin, ymin } = polyface.bbox;
          rectangle_offset = { x: xmin, y: ymin };
          shape = { type: "rect" };
        }

        group.updateItem(itemid, {
          rectangle_offset,
          width: tempItem.width,
          height: tempItem.height,
          data,
          shape,
        });
      } else if (dragging && dragStart.type === "void") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        let to = add(dragStart.from, diff);

        if (e.detail.shiftKey) {
          to = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, null);
        }

        if (polyfaceContainsPoint(polyface, to)) {
          const reference = nearestEdgeOffsetReference(0, polyface.edges, to, precision, ds.displayUnit);
          const data = cloneDeep(item.data);
          data.fabrications.voids[feat.index].reference = reference;
          group.updateItem(itemid, { data });
        }
      } else if (dragging && dragStart.type === "efab") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        const to = add(dragStart.from, diff);

        const reference = nearestEdgeReference(polyface.edges, to, precision, ds.displayUnit);
        const data = cloneDeep(item.data);
        data.fabrications.edge[feat.index].reference = reference;
        group.updateItem(itemid, { data });
      } else if (dragging && dragStart.type === "cfab") {
        const feat = featHitbox(dragStart.hitbox.name);

        const reference = nearestCornerReference(0, polyface.shape, e.detail.dwgPt);
        const data = cloneDeep(item.data);
        data.fabrications.corner[feat.index].reference = reference;
        group.updateItem(itemid, { data });
      } else if (dragging && dragStart.type === "refplane") {
        const feat = featHitbox(dragStart.hitbox.name);

        if (feat.class === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          const v = ["left", "right"].includes(feat.id) ? e.detail.dwgPt.x : e.detail.dwgPt.y;

          const vc = ds.displayUnit === "millimeters" ? v * 25.4 : v;
          const rounded = roundValue(vc, precision);
          const rin = ds.displayUnit === "millimeters" ? rounded / 25.4 : rounded;

          if (feat.id === "top") {
            const dy = rin - y2;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].y += dy;
            });
          } else if (feat.id === "right") {
            const dx = rin - x2;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].x += dx;
            });
          } else if (feat.id === "bottom") {
            const dy = rin - y1;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].y += dy;
            });
          } else if (feat.id === "left") {
            const dx = rin - x1;
            dragStart.vertices.forEach(([li, vi]) => {
              tempItem.shape.vertices[li][vi].x += dx;
            });
          }

          const val = new Dimension(new Quantity(rounded, ds.displayUnit));
          tempItem.data.reference_planes[feat.id].value = val;

          updateSize(tempItem);
          const data = cloneDeep(item.data);
          let shape = tempItem.shape;
          let rectangle_offset = tempItem.rectangle_offset;
          data.reference_planes = tempItem.data.reference_planes;

          if (isRectangle(shape)) {
            const { xmin, ymin } = polyface.bbox;
            rectangle_offset = { x: xmin, y: ymin };
            shape = { type: "rect" };
          }

          group.updateItem(itemid, {
            rectangle_offset,
            width: tempItem.width,
            height: tempItem.height,
            data,
            shape,
          });
        } else if (feat.class === "cross") {
          const v = feat.id === "horizontal" ? e.detail.dwgPt.y : e.detail.dwgPt.x;
          const vc = ds.displayUnit === "millimeters" ? v * 25.4 : v;
          const rounded = roundValue(vc, precision);
          const val = new Dimension(new Quantity(rounded, ds.displayUnit));
          item.data.reference_planes[feat.id].value = val;
          group.updateItem(itemid, { data: item.data });
        }
      } else if (dragging && dragStart.type === "bug") {
        const feat = featHitbox(dragStart.hitbox.name);
        const diff = subtract(e.detail.dwgPt, dragStart.dwgPt);
        let to = add(dragStart.from, diff);

        if (e.detail.shiftKey) {
          to = snapOrtho(to, dragStart.from, SNAP_THRESHOLD / e.detail.scale, null);
        }

        if (polyfaceContainsPoint(polyface, to)) {
          const reference = nearestEdgeOffsetReference(0, polyface.edges, to, precision, ds.displayUnit);
          const data = cloneDeep(item.data);
          data.fabrications.bug.reference = reference;
          group.updateItem(itemid, { data });
        }
      }

      // Finish adding a new point
    } else if ($currentTool === "add-point") {
      if (addingPt) {
        const l = tempItem.shape.vertices[0];
        const prevIndex = addingPt.edgeIndex;
        const currentIndex = (addingPt.edgeIndex + 1) % l.length;
        const nextIndex = (addingPt.edgeIndex + 2) % l.length;
        const a = l[prevIndex];
        const b = l[nextIndex];
        const fc = cornerFabConstraint(cornerFabs[0], l, currentIndex, prevIndex, nextIndex);

        const m = roundPt(e.detail.dwgPt, sddp);

        const hplanes = [];
        const vplanes = [];
        if (tempItem.data.reference_planes?.type === "box") {
          const x1 = tempItem.data.reference_planes.left.value.toNumber("inches");
          const x2 = tempItem.data.reference_planes.right.value.toNumber("inches");
          const y1 = tempItem.data.reference_planes.bottom.value.toNumber("inches");
          const y2 = tempItem.data.reference_planes.top.value.toNumber("inches");

          hplanes.push(y1, y2);
          vplanes.push(x1, x2);
        } else if (tempItem.data.reference_planes?.type === "cross") {
          const hor = tempItem.data.reference_planes.horizontal.value.toNumber("inches");
          const ver = tempItem.data.reference_planes.vertical.value.toNumber("inches");

          hplanes.push(hor);
          vplanes.push(ver);
        }

        const snapped = snap(m, addingPt.orig, a, b, SNAP_THRESHOLD / e.detail.scale, fc, hplanes, vplanes);
        const nearest = nearestPointOnSegment(a, b, e.detail.dwgPt);
        const d = distSq(nearest, e.detail.dwgPt);
        const pt =
          d > SNAP_THRESHOLD / e.detail.scale || equal(nearest, a) || equal(nearest, b) ? snapped : nearest;

        l[addingPt.edgeIndex + 1] = pt;
        updateSize(tempItem);
        const data = cloneDeep(item.data);
        const shape = tempItem.shape;
        data.fabrications = tempItem.data.fabrications;
        group.updateItem(itemid, {
          width: tempItem.width,
          height: tempItem.height,
          data,
          shape,
        });
      }
    }

    dragging = false;
    dragStart = null;
    addingPt = null;
  }

  async function deleteFeature(e) {
    const { features } = e.detail;

    const voids = features.filter((f) => f.type === "void").map((f) => f.index);
    const edge = features.filter((f) => f.type === "efab").map((f) => f.index);
    const corner = features.filter((f) => f.type === "cfab").map((f) => f.index);
    const bug = features.find((f) => f.type === "bug");

    deselectFeatures();
    await tick();

    const data = cloneDeep(item.data);

    if (voids.length > 0) {
      data.fabrications.voids = data.fabrications.voids.filter((v, i) => !voids.includes(i));
    }

    if (edge.length > 0) {
      data.fabrications.edge = data.fabrications.edge.filter((e, i) => !edge.includes(i));
    }

    if (corner.length > 0) {
      data.fabrications.corner = data.fabrications.corner.filter((c, i) => !corner.includes(i));
    }

    if (bug) {
      delete data.fabrications.bug;
    }

    group.updateItem(itemid, { data });
  }

  function stopEditingDim(e) {
    const isFeatureDim = e.detail && e.detail.id && !!e.detail.id.match(/^feature/);

    if (isFeatureDim) {
      activeInput = null;
    } else {
      deselectFeatures();
    }
  }

  function deleteSelected() {
    if (activeInput) return;
    const features = selectedFeatures
      .filter((f) => ["void", "efab", "cfab", "bug"].includes(f.type))
      .map((f) => ({ type: f.type, index: f.index }));
    deleteFeature({ detail: { features } });
  }

  onMount(() => {
    Mousetrap.bind(["del", "backspace"], deleteSelected);

    return () => {
      Mousetrap.unbind(["del", "backspace"]);
    };
  });
</script>

<div class="p-4 absolute z-10 space-y-2">
  <div class="text-blue-500 text-sm">
    {#if isCollection}
      <Link to={"openings"} class="flex items-center space-x-1">
        <CaretLeftIcon />
        <div>All Openings</div>
      </Link>
    {:else}
      <Link to={"items"} class="flex items-center space-x-1">
        <CaretLeftIcon />
        <div>All Items</div>
      </Link>
    {/if}
  </div>
</div>

{#if item}
  <div class="w-full h-full relative" bind:offsetWidth={width} bind:offsetHeight={height}>
    <div class="absolute w-full h-full overflow-hidden">
      {#key itemid}
        <div
          class="absolute w-full h-full"
          in:slideAnimation={{ type: "in" }}
          out:slideAnimation={{ type: "out" }}>
          <Viewport
            key={refreshKey}
            paddingTop={paddingY}
            paddingRight={paddingX + (sidebarPosition === "bottom" ? 0 : 320)}
            paddingBottom={paddingY + (sidebarPosition === "bottom" ? 224 : 0)}
            paddingLeft={paddingX}
            autofit={false}
            pannable
            zoomable
            drawing={drawing.drawing}
            hitboxDrawing={drawing.hitboxDrawing}
            bind:dimension={activeInput}
            on:vpmouseover={handleVpMouseover}
            on:vpmousemove={handleVpMousemove}
            on:vpmouseout={handleVpMouseout}
            on:vpdragstart={handleVpDragstart}
            on:vpdrag={handleVpDrag}
            on:vpdragend={handleVpDragend}
            on:vpclick={handleVpClick} />

          {#if activeInput && !disabled}
            {#key activeInput.id}
              {#if activeInput?.type === "type"}
                <SelectInput
                  job={group}
                  {item}
                  props={activeInput}
                  optionMap={makeTypeOptionMap(types)}
                  options={makeTypeIds(types)}
                  on:stopEditing={deselectFeatures} />
              {:else if ["dim", "dtdim", "dimonly"].includes(activeInput?.type)}
                <DimtextInput
                  job={group}
                  {item}
                  settings={$group.data.settings}
                  props={activeInput}
                  on:stopEditing={stopEditingDim} />
              {:else if activeInput}
                <TextInput job={group} {item} props={activeInput} on:stopEditing={deselectFeatures} />
              {/if}
            {/key}
          {/if}
        </div>
      {/key}
    </div>
    {#if $showRightPanel}
      <Sidebar type="absolute" position={sidebarPosition}>
        <svelte:fragment slot="header">
          {#if !showingActiveTool && !selectedFeatures.length}
            <PrevNext
              prev={prevItem}
              next={nextItem}
              on:gotoprev={gotoPrev}
              on:gotonext={gotoNext}
              sticky
              title={item?.mark} />
          {/if}
        </svelte:fragment>

        <svelte:fragment slot="content">
          {#if showingActiveTool}
            <SidebarTitle title={"New " + featureNames[$currentTool]} />
            <svelte:component
              this={newFeatureProps[tempFeature.type]}
              job={group}
              {item}
              disabled={disabled || productProject}
              {polyface}
              settings={$group.data.settings}
              feature={tempFeature} />
          {:else if selectedFeatures.length > 0}
            <SidebarTitle title={selectedFeature.title} />
            <svelte:component
              this={featureProps[selectedFeature.type]}
              job={group}
              {item}
              disabled={disabled || productProject}
              {polyface}
              settings={$group.data.settings}
              feature={selectedFeature}
              on:deleteFeature={deleteFeature} />
          {:else}
            <ItemProperties
              {disabled}
              {group}
              {item}
              {itemid}
              {types}
              {collections}
              customColumns={customCols}
              {standardColumns}
              {isCollection}
              settings={$group.data.settings}
              on:updateItem={(e) => updateItem(e.detail.prop, e.detail.value, e.detail.diff)}
              on:mouseoverEdges={mouseoverEdges}
              on:mouseoutEdges={mouseoutEdges} />
            {#if item.is_collection}
              <CollectionItemList
                {group}
                {items}
                collectionItems={childItems}
                collectionId={item.id}
                {disabled} />
            {/if}
            {#if !productProject}
              <LocationThumbnailViewer
                recordid={itemid}
                {group}
                record={item}
                {disabled}
                documents={$group.data.documents}
                selectable />
            {/if}
            <Attachments {attachments} {group} {itemid} {disabled} />
            <History
              updatedBy={item.updater}
              updatedOn={item.updated_at}
              approvedBy={item.approver}
              approvalStatus={item.approval_status}
              approvedOn={item.approval_status_updated_at} />
          {/if}
        </svelte:fragment>
      </Sidebar>
    {/if}
  </div>
{/if}
