<script>
  import { createEventDispatcher } from "svelte";
  import { tooltip as ttaction } from "svelte-utilities"

  const dispatch = createEventDispatcher();

  export let type = "enum";
  export let toolid = "select";
  export let state = null;
  export let disabled = false;
  export let tooltip = null;

  $: active = {
    enum: () => toolid === state,
    toggle: () => !!state,
    button: () => toolid === state && state !== null,
  }[type]();

  function clickTool(evt) {
    dispatch("click", { state });

    if (type === "button") {
      return;
    } else if (type === "toggle") {
      state = !state;
    } else {
      state = toolid;
    }
  }
</script>

<button
  class="tool-button"
  class:disabled
  class:active
  {disabled}
  on:click={clickTool}
  use:ttaction={{ text: tooltip }}>
  <slot />
</button>

<style lang="scss">
  .tool-button {
    @apply opacity-50 cursor-pointer p-1 rounded;

    &:hover:not(.disabled) {
      @apply bg-gray-700 opacity-100;
    }

    &.active {
      @apply opacity-100 bg-gray-700;
    }

    &.disabled {
      @apply opacity-20 cursor-not-allowed;
    }
  }
</style>
