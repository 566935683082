import cloneDeep from "lodash/cloneDeep";
import lset from "lodash/set";
import { updateProp, addRecords, removeRecords } from "src/api";
import {
  hydrateItem,
  hydrateType,
  updateItemCache,
  updateTypeCache,
  updateMaterialCache,
} from "@local/lamina-core";

import {
  addToSortableList,
  removeFromSortableList,
} from "@local/extensions/collections/sortable-list.js";

function matDbProps(mat) {
  if (!mat || mat.type === "none") {
    return { type: "none" };
  } else if (!mat.type) {
    return mat;
  } else if (mat.type === "fixed") {
    return mat;
  } else if (mat.type === "query") {
    const { type, value, default: def } = mat;
    return { type, value, default: def };
  } else if (mat.type === "option") {
    const { type, options, default: def } = mat;
    return { type, options, default: def };
  } else if (mat.type === "reference") {
    const { type, value } = mat;
    return { type, value };
  } else {
    return mat;
  }
}

export function removeExtendedMakeupProps(makeup) {
  const { data, cache, expanded_data, category_image, category_name, ...rest } =
    makeup;
  const layers = data?.layers.map((l) => {
    return {
      ...l,
      material: matDbProps(l.material),
      ...(l.inboard_surface
        ? { inboard_surface: matDbProps(l.inboard_surface) }
        : {}),
      ...(l.outboard_surface
        ? { outboard_surface: matDbProps(l.outboard_surface) }
        : {}),
    };
  });
  return {
    ...rest,
    ...(data ? { data: { ...data, layers } } : {}),
  };
}

function doCommit(
  record,
  table,
  update,
  commitId = undefined,
  updater = null,
  updateCache = null,
  strip = null,
) {
  let oldValue = {};
  let newValue = {};

  if (update.diff) {
    oldValue = Object.keys(update.diff).reduce((o, p) => {
      const path = p.split(".");
      const [col] = path;
      o[col] = record[col];
      return o;
    }, {});

    newValue = Object.keys(update.diff).reduce((o, p) => {
      const path = p.split(".");
      const [col, ...rest] = path;

      if (["data", "custom_column_data"].includes(col)) {
        if (rest.length > 0) {
          if (!o[col]) o[col] = cloneDeep(record[col]);
          lset(o[col], rest, update.diff[p]);
        } else {
          o[col] = update.diff[p];
        }
      } else {
        o[p] = update.diff[p];
      }

      return o;
    }, {});
  } else if (update.prop) {
    const path = update.prop.split(".");
    const [col, ...rest] = path;

    if (["data", "custom_column_data"].includes(col) && rest.length > 0) {
      oldValue = { [col]: record[col] };
      const val = cloneDeep(record[col]);
      lset(val, rest, update.value);
      newValue = { [col]: val };
    } else {
      oldValue = { [col]: record[col] };
      newValue = { [col]: update.value };
    }
  }

  Object.entries(newValue).forEach(([key, value]) => {
    record[key] = value;
  });

  if (updater) {
    record.updated_at = new Date().toISOString();
    record.updater = updater;
  }

  if (updateCache) {
    updateCache(record);
  }

  if (strip) {
    updateProp(table, record.id, strip(newValue), commitId);
  } else {
    updateProp(table, record.id, newValue, commitId);
  }

  return oldValue;
}

export function updateCommit(updates, $p = null) {
  const commit = (n, cId) => {
    const undos = updates.map((up) => {
      if (up.type === "group") {
        return doCommit(n, "groups", up, cId);
      } else if (up.type === "organization") {
        return doCommit(n, "organizations", up);
      } else if (up.type === "item") {
        const item = n.items[up.id];
        const updateCache = (item) => updateItemCache(item, n);
        return doCommit(item, "items", up, cId, $p, updateCache);
      } else if (up.type === "type") {
        const type = n.types[up.id];
        return doCommit(type, "types", up, cId, $p, updateTypeCache);
      } else if (up.type === "surface") {
        const surface = n.surfaces[up.id];
        return doCommit(surface, "product_entries", up);
      } else if (up.type === "item_product") {
        const itemProduct = n.item_products[up.id];
        return doCommit(itemProduct, "product_entries", up);
      } else if (up.type === "material") {
        const material = n.materials[up.id];
        return doCommit(
          material,
          "product_entries",
          up,
          undefined,
          null,
          updateMaterialCache,
        );
      } else if (up.type === "makeup") {
        const makeup = n.makeups[up.id];

        if (makeup.application === "makeup") {
          return doCommit(
            makeup,
            "product_entries",
            up,
            undefined,
            null,
            updateTypeCache,
            removeExtendedMakeupProps,
          );
        } else {
          return doCommit(makeup, "product_entries", up);
        }
      }
    });

    return (n) => {
      updates.forEach((update, i) => {
        const undo = undos[i];

        if (update.type === "group") {
          Object.entries(undo).forEach(([key, value]) => {
            n[key] = value;
          });
          updateProp("groups", n.id, undo, cId);
        } else if (update.type === "item") {
          const item = n.items[update.id];
          Object.entries(undo).forEach(([key, value]) => {
            item[key] = value;
          });
          item.updated_at = new Date().toISOString();
          updateItemCache(item, n);
          updateProp("items", update.id, undo, cId);
        } else if (update.type === "type") {
          const type = n.types[update.id];
          Object.entries(undo).forEach(([key, value]) => {
            type[key] = value;
          });
          type.updated_at = new Date().toISOString();
          updateTypeCache(type);
          updateProp("types", update.id, undo, cId);
        } else if (update.type === "surface") {
          const surface = n.surfaces[update.id];
          Object.entries(undo).forEach(([key, value]) => {
            surface[key] = value;
          });
          updateProp("product_entries", update.id, undo);
        } else if (update.type === "material") {
          const material = n.materials[update.id];
          Object.entries(undo).forEach(([key, value]) => {
            material[key] = value;
          });
          updateMaterialCache(material);
          updateProp("product_entries", update.id, undo);
        } else if (update.type === "makeup") {
          const makeup = n.makeups[update.id];
          Object.entries(undo).forEach(([key, value]) => {
            makeup[key] = value;
          });
          updateTypeCache(makeup);
          updateProp(
            "product_entries",
            update.id,
            removeExtendedMakeupProps(undo),
          );
        }
      });
    };
  };

  return commit;
}

export function addCommit(
  recordType,
  table,
  parentTable,
  records,
  hydrate,
  strip,
) {
  const ids = records.map((t) => t.id);

  const commit = async (n, commitId) => {
    const recordList = n[`${recordType}s`];
    const old_data = cloneDeep(n.data);
    old_data[`${recordType}_order`] = cloneDeep(recordList.order);

    if (hydrate) {
      addToSortableList(recordList, ...records.map((t) => hydrate(t)));
    } else {
      addToSortableList(recordList, ...records);
    }
    n.data[`${recordType}_order`] = [...recordList.order];

    await addRecords(
      table,
      strip ? records.map((t) => strip(t)) : records,
      // commitId
    );

    updateProp(parentTable, n.id, "data", n.data);

    return async (n) => {
      removeFromSortableList(n[`${recordType}s`], ...ids);
      await removeRecords(table, ids);
      updateProp(parentTable, n.id, "data", old_data);
    };
  };

  return commit;
}

export function removeCommit(
  recordType,
  table,
  parentTable,
  ids,
  hydrate,
  strip,
) {
  const commit = (n, commitId) => {
    const recordList = n[`${recordType}s`];
    const old_data = cloneDeep(n.data);
    old_data[`${recordType}_order`] = [...recordList.order];

    removeFromSortableList(recordList, ...ids);
    n.data[`${recordType}_order`] = [...recordList.order];

    const res = {};
    removeRecords(table, ids).then((r) => (res[`${recordType}s`] = r));
    updateProp(parentTable, n.id, "data", n.data);

    return (n) => {
      const rl = res[`${recordType}s`];
      if (hydrate) {
        addToSortableList(recordList, ...rl.map((t) => hydrateType(t)));
      } else {
        addToSortableList(recordList, ...rl);
      }
      addRecords(table, strip ? rl.map((t) => strip(t)) : rl);
      updateProp(parentTable, n.id, "data", old_data);
    };
  };

  return commit;
}

export function reorderCommit(recordType, parentTable, order) {
  const commit = (n, commitId) => {
    const oldOrder = n[`${recordType}s`].order;
    const oldData = n.data;

    n[`${recordType}s`].order = order;
    const data = cloneDeep(n.data);
    lset(data, `${recordType}_order`, order);

    updateProp(parentTable, n.id, "data", data);

    return (n) => {
      n[`${recordType}s`].order = oldOrder;
      lset(n.data, oldData);
      updateProp(parentTable, n.id, "data", oldData);
    };
  };

  return commit;
}
