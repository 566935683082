<script>
  export let kv = {};

  const message = Object.entries(kv).reduce((msg, [key, value]) => `${msg}${key}: ${value}\n`, "");
</script>

<pre>{message}</pre>

<style>
  pre {
    position: absolute;
    padding: 1rem;
  }
</style>
