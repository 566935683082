<script>
  import { api } from "src/api";

  /* Subcomponents */
  import Navbar from "src/lib/Navbar.svelte";

  async function init() {
    const { JsonView } = await import("@zerodevx/svelte-json-view");
    const { data, error } = await api.from("products").select("*");
    if (error) throw error;
    return { Viewer: JsonView, data };
  }
</script>

<Navbar />
<div class="grow min-h-0">
  <div class="viewer h-full w-full overflow-y-scroll">
    {#await init()}
      Loading...
    {:then { data: products, Viewer }}
      <svelte:component this={Viewer} json={products} />
    {:catch error}
      Error: {error.message}
    {/await}
  </div>
</div>

<style>
  .viewer {
    font-family: monospace;
    --jsonValColor: blue;
  }
</style>
