<script>
  import { createEventDispatcher, onMount, onDestroy, tick } from "svelte";
  import mapboxgl from "mapbox-gl";
  import { MapboxSearchBox } from "@mapbox/search-js-web";
  import "mapbox-gl/dist/mapbox-gl.css";

  import CheckIcon from "src/assets/icons/check.svg";
  import { clickOutside, Portal } from "svelte-utilities";
  import { MAPBOX_TOKEN } from "src/env.js";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let outerBorder = true;
  export let placeholder = "";
  export let coordinates = null;

  const dispatch = createEventDispatcher();

  let container;
  let focused = false;

  let mbContainer;
  let mapContainer;
  let searchboxContainer;
  let map;
  let searchBox;
  let displayValue = value;
  let initialMarker;
  let cw = 200;
  let ct = 0;
  let cl = 0;
  let zi = 0;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: refresh(value);
  $: updateContainer(container);

  function refresh(value) {
    displayValue = value;
  }

  function getZIndex(el) {
    const z = window.getComputedStyle(el).getPropertyValue("z-index");
    if (el === document.body) return 0;
    if (isNaN(z)) return getZIndex(el.parentNode);
    return Number(z);
  }

  function updateContainer(container) {
    if (!container) return;
    const z = getZIndex(container);
    const { left, top, width } = container.getBoundingClientRect();
    cw = width;
    ct = top;
    cl = left;
    zi = z + 1;
  }

  async function handleFocus() {
    if (disabled) return;
    focused = true;
    await tick();
    searchBox.focus();
  }

  async function getPostalCode(coordinates) {
    const { latitude, longitude } = coordinates;
    const res = await fetch(
      `https://api.mapbox.com/search/geocode/v6/reverse?latitude=${latitude}&longitude=${longitude}&access_token=${MAPBOX_TOKEN}&types=postcode`,
    );
    const data = await res.json();
    const code = data.features[0]?.properties.name;
    return code;
  }

  async function retrieve(e) {
    if (disabled) return;
    if (initialMarker) {
      initialMarker.remove();
    }
    const { features } = e.detail;
    const [feature] = features;
    if (!feature) return;
    value = feature.properties.full_address;
    coordinates = feature.properties.coordinates;
    let postal_code;
    if (feature.properties.context.postcode) {
      postal_code = feature.properties.context.postcode.name;
    } else {
      postal_code = await getPostalCode(coordinates);
    }
    const country_code = feature.properties.context.country.country_code_alpha_3;
    searchBox.placeholder = value;
    dispatch("input", { value, coordinates, postal_code, country_code });
  }

  function outclick(e) {
    if (container && container.contains(e.clickTarget)) return;
    if (mbContainer && mbContainer.contains(e.clickTarget)) return;
    focused = false;
  }

  function close() {
    focused = false;
  }

  function clickSearchbox(e) {
    const target = e.target;
    const pn = target.parentNode;
    if (pn.ariaLabel === "Clear" && !disabled) {
      value = "";
      searchBox.placeholder = "";
      coordinates = null;
      if (initialMarker) {
        initialMarker.remove();
      }
      dispatch("input", { value, coordinates });
    }
  }

  onMount(async () => {
    const center = coordinates ? [coordinates.longitude, coordinates.latitude] : [-74.0, 40.7];

    await tick();
    updateContainer();

    map = new mapboxgl.Map({
      container: mapContainer,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      center,
      zoom: coordinates ? 11 : 5,
    });

    if (coordinates) {
      initialMarker = new mapboxgl.Marker({ color: "#306BEC" }).setLngLat(center).addTo(map);
    }

    searchBox = new MapboxSearchBox();
    searchBox.accessToken = MAPBOX_TOKEN;
    searchBox.options = {
      language: "en",
      country: "us,ca",
    };
    searchBox.mapboxgl = mapboxgl;
    searchBox.marker = true;
    searchBox.bindMap(map);

    searchBox.addEventListener("retrieve", retrieve);
    searchBox.addEventListener("click", clickSearchbox);

    searchboxContainer.appendChild(searchBox);

    if (value) {
      searchBox.placeholder = value;
    }
  });

  onDestroy(() => {
    if (map) {
      map.remove();
    }
    if (searchboxContainer) {
      searchboxContainer.removeChild(searchBox);
    }
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={disabled}
  class:visible-border={border}
  bind:this={container}
  use:clickOutside={[container, "mapbox-search-listbox", "", mbContainer, mapContainer, searchboxContainer]}
  on:click={handleFocus}
  on:outclick={outclick}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if disabled}
    <div class="input">
      {#if displayValue}
        {displayValue}
      {:else}
        &nbsp;
      {/if}
    </div>
  {:else}
    <input size="1" class="input" {placeholder} {disabled} bind:value={displayValue} />
  {/if}
  <Portal>
    <div
      class="rounded bg-white border shadow-lg"
      class:invisible={!focused}
      class:visible={focused}
      bind:this={mbContainer}
      style="
        z-index: {zi};
        width: {cw}px;
        height: 16rem;
        top: {ct}px;
        left: {cl}px;
        overflow: hidden;
        position: absolute;
      ">
      <div class="map" bind:this={mapContainer}></div>
      <div class="searchbox flex gap-2">
        <div class="relative grow">
          <div class="relative" bind:this={searchboxContainer}></div>
        </div>
        <button
          class="flex-none bg-white border border-dotted border-gray-600 rounded shadow-lg p-2"
          on:click={close}>
          <CheckIcon />
        </button>
      </div>
    </div>
  </Portal>
</div>

<style lang="scss">
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply w-full flex items-center rounded bg-white relative;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .input {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }
  }

  .searchbox {
    position: absolute;
    width: 100%;
    padding: 1rem;
  }

  .map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
</style>
