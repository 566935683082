<!-- <svelte:options strictprops={false} /> -->

<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import LoginForm from "src/lib/LoginForm.svelte";
  import NavbarUnauthenticated from "src/lib/NavbarUnauthenticated.svelte";
  import { user, apiError, loading } from "src/stores/auth.js";

  export let path;
  export let isOtpLogin = false;

  let email = "";
  let password = "";
  let otp = "";

  let state = "magiclink"; // ["magiclink", "password", "reset", "otp"]
  let sent = false;

  $: otpValid = !!otp;

  async function login() {
    // If we've already sent the request, we allow logging in with the OTP
    if (sent) {
      await user.verifyOtp(email, otp);
      if ($user && state === "reset") {
        navigate("/passreset");
      }
    } else if (state === "otp") {
      await user.verifyOtp(email, otp);
    } else if (state === "password") {
      await user.login(email, password);
    } else if (state === "reset") {
      await user.resetPassword(email, "passreset");

      if (!$apiError) {
        sent = true;
      }
    } else {
      await user.sendMagicLink(email, path);
      if (!$apiError) {
        sent = true;
      }
    }
  }

  onMount(() => {
    if (isOtpLogin) {
      state = "otp";
    }
  });
</script>

<NavbarUnauthenticated />
<LoginForm action={login}>
  {#if !sent}
    {#if state === "password"}
      <p class="text-sm">Sign in with email and password.</p>
    {:else if state === "reset"}
      <p class="text-sm">Enter email to receive password reset link.</p>
    {:else if state === "otp"}
      <p class="text-sm">Enter email and one-time password.</p>
    {:else}
      <p class="text-sm">Provide email to receive magic link.</p>
    {/if}
    <div>
      <input
        class="border rounded w-full p-2"
        class:loading={$loading}
        type="email"
        autocomplete="username"
        placeholder="name@email.com"
        disabled={$loading}
        bind:value={email} />
    </div>
    {#if state === "password"}
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={$loading}
          type="password"
          autocomplete="current-password"
          placeholder="password"
          disabled={$loading}
          bind:value={password} />
      </div>
    {:else if state === "otp"}
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={$loading}
          autocomplete="one-time-code"
          placeholder="one-time password"
          disabled={$loading}
          bind:value={otp} />
      </div>
    {/if}
    {#if $apiError}
      <div class="text-red-600 text-sm">{$apiError}</div>
    {/if}
    <button type="submit" class="btn btn-lg btn-primary w-full" class:loading={$loading} disabled={$loading}>
      {#if state === "magiclink"}
        Sign In with Email
      {:else if state === "otp"}
        Sign In with One-time Password
      {:else if state === "password"}
        Sign In
      {:else}
        Send Password Reset Link
      {/if}
    </button>
    <div class="flex justify-between text-xs">
      {#if state === "magiclink"}
        <button class="cursor-pointer text-blue-500" on:click={() => (state = "password")}>
          Use username/password
        </button>
      {:else if ["password", "reset"].includes(state)}
        <button class="cursor-pointer text-blue-500" on:click={() => (state = "magiclink")}
          >Use magic link</button>
      {/if}
      {#if state === "password"}
        <button class="text-gray-500 cursor-pointer" on:click={() => (state = "reset")}
          >Reset password</button>
      {:else if state === "reset"}
        <button class="text-gray-500 cursor-pointer" on:click={() => (state = "password")}
          >Enter password</button>
      {/if}
    </div>
  {:else}
    <p class="text-sm mb-4">
      Request sent! Check your email for the
      {#if state === "reset"}
        password reset
      {:else}
        sign-in
      {/if}
      link.
    </p>
    {#if $apiError}
      <div class="text-red-600 text-sm">{$apiError}</div>
    {/if}
    {#if state === "magiclink" || state === "reset"}
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={$loading}
          autocomplete="one-time-code"
          placeholder="Enter one-time password"
          disabled={$loading}
          bind:value={otp} />
      </div>
      <button
        type="submit"
        class="btn btn-lg btn-primary w-full"
        class:loading={$loading}
        disabled={$loading || !otpValid}>
        {#if state === "magiclink"}
          Sign in with One-time Password
        {:else}
          Go to Password Reset
        {/if}
      </button>
    {/if}
  {/if}
</LoginForm>

<style>
  .loading {
    opacity: 0.5;
  }
</style>
