<script>
  import { onMount, createEventDispatcher, tick } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import { Modal } from "svelte-utilities";

  import { createClient } from "@local/lamina-freight/client";

  import SpinnerIcon from "src/assets/icons/spinner.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import EllipsisIcon from "src/assets/icons/ellipsis.svg";
  import ErrorMessage from "../standard-inputs/ErrorMessage.svelte";
  import MapboxInput from "src/lib/sidebar/MapboxInput.svelte";
  import BooleanInput from "src/lib/sidebar/BooleanInput.svelte";

  import { sortableList } from "@local/extensions/collections/sortable-list.js";
  import { currentSuppliers, currentSupplierid } from "src/stores/ui.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials";
  import { itemDimensions } from "@local/lamina-core/reporters";
  import eb from "src/extensions/event-bus.js";
  import { api } from "src/api";
  import { FRATER_URL } from "src/env.js";

  export let rfq;
  export let group;
  export let recipients;
  export let items;
  export let types;
  export let org;

  const dispatch = createEventDispatcher();
  const freight = createClient(FRATER_URL, api);

  let modal;
  let suppliers = {};
  let computingShipping = false;
  let jobkey = crypto.randomUUID();
  let key;
  let destination = rfq?.job.location_postal_code;
  let errorMsg;
  let showAdvancedOptions = false;

  $: supplier = $currentSuppliers[$currentSupplierid];
  $: shipping = recipients[0]?.shipping?.cost;
  $: sendDisabled = !recipients.length || !shipping || computingShipping;
  $: updateKey(items, types);
  $: rfqUpdateKey(rfq);
  $: hasShippingProp = ["liftgate_delivery", "appointment_delivery", "inside_delivery"].some(
    (prop) => rfq.job.data.shipping_options?.[prop],
  );
  $: accessorialCodes = getAccessorialCodes(rfq.job.data.shipping_options);

  function updateKey(items, types) {
    jobkey = crypto.randomUUID();
  }

  function rfqUpdateKey(rfq) {
    if (destination !== rfq.job.location_postal_code) {
      jobkey = crypto.randomUUID();
      destination = rfq.job.location_postal_code;
    }
  }

  function getAccessorialCodes(shippingOptions) {
    if (!shippingOptions) return [];
    const codes = [];

    if (shippingOptions.notification_delivery) {
      codes.push("notification_delivery");
    }

    if (shippingOptions.liftgate_delivery) {
      codes.push("liftgate_delivery");
    }

    if (shippingOptions.appointment_delivery) {
      codes.push("appointment_delivery");
    }

    if (shippingOptions.inside_delivery) {
      codes.push("inside_delivery");
    }

    return codes;
  }

  async function openModal() {
    modal.open();

    if (hasShippingProp) {
      showAdvancedOptions = true;
    }

    if (key !== jobkey) {
      getShipping();
    }

    suppliers = $currentSuppliers;
    const { data, error } = await api
      .from("organizations")
      .select("*, profiles(*)")
      .in(
        "id",
        recipients.map((r) => r.contact_id),
      );

    if (error) {
      console.error(error);
      suppliers = sortableList([]);
    } else {
      suppliers = sortableList(data);
    }
  }

  async function getShipping() {
    if (!supplier) return;

    const data = cloneDeep(rfq.data);
    const recipient = data.saved_recipients?.[0];
    if (!recipient) return;

    computingShipping = true;

    const itemDims = itemDimensions(items);

    const postcode = rfq.job.location_postal_code;

    if (!postcode) return;

    const endpointId = supplier.freight_endpoint ?? "418f0f57-babd-5bef-a3a2-c2bc30f8e0be";
    const referenceId = $group.seller_reference || rfq.id;
    try {
      const response = await freight.rateQuote.query({
        origin: {
          country: supplier.address_country_code?.toLowerCase() ?? "usa",
          postcode: supplier.address_postal_code,
        },
        destination: {
          country: rfq.job.location_country_code?.toLowerCase() ?? "usa",
          postcode,
        },
        referenceId,
        endpointId,
        accessorialCodes,
        endpointParams: {},
        items: itemDims,
      });

      recipient.shipping = response;

      await dispatch("update-rfq", { data });
    } catch (error) {
      console.log(error);
      errorMsg = "Error calculating shipping.";
    }

    computingShipping = false;
    key = jobkey;
  }

  function userInits(p) {
    return getUserInitials(p).toUpperCase();
  }

  async function updateJob(update) {
    await dispatch("update-job", update);
  }

  async function updateJobData(path, value) {
    const data = cloneDeep(rfq.job.data);
    set(data, path, value);
    await dispatch("update-job", { data });
    await tick();
    getShipping();
  }

  async function updateAddress(e) {
    await updateJob({
      location: e.detail.value,
      location_coordinates: e.detail.coordinates,
      location_postal_code: e.detail.postal_code,
      location_country_code: e.detail.country_code,
    });
    await tick();
    getShipping();
  }

  function recipientEmail(recipient) {
    if (recipient.name) {
      return `${recipient.name} (${recipient.email})`;
    } else if (recipient.email) {
      return recipient.email;
    }

    return "";
  }

  onMount(() => {
    eb.on("open-rfq-modal", openModal);

    return () => {
      eb.unsubscribe("open-rfq-modal", openModal);
    };
  });
</script>

<Modal
  bind:this={modal}
  width="32rem"
  closeable
  on:confirm
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Create", type: "confirm", style: "primary", disabled: sendDisabled },
  ]}>
  <div slot="title">
    {#if org.account_type === "free"}
      {#if supplier}
        Create Quote
      {:else}
        Unable to send
      {/if}
    {:else}
      Create Quote
    {/if}
  </div>
  <div slot="content" class="space-y-4 text-sm">
    {#if errorMsg}
      <ErrorMessage padding={false} message={errorMsg} on:close={() => (errorMsg = null)} />
    {/if}
    {#if org.account_type === "free" && !supplier}
      <div>
        Your free account is not sponsored by any suppliers. Upgrade to a paid account in order to send this
        RFQ.
      </div>
    {:else if !recipients.length}
      <div>You must add at least one recipient.</div>
    {:else}
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">Job:</div>
        <div class="text-black">
          <div class="font-bold">{rfq.job.name}</div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">Vendor:</div>
        <div class="space-y-3">
          {#each recipients as recipient}
            {@const rorg = suppliers[recipient.contact_id]}
            {@const prof = rorg?.profiles.find((p) => p.id === recipient.primary_contact_id)}
            <div>
              {#if rorg?.data.logo}
                <img
                  class="block logo-image object-contain h-6"
                  src={rorg.data.logo.base64}
                  alt="Logo Thumbnail" />
              {/if}
              <div class="font-bold text-sm">{recipient.company_name}</div>
              <div class="flex items-center text-xs gap-1">
                {#if prof}
                  <div
                    class="profile-circle relative"
                    style="background-color: {prof.user_color || '#000'}; border-color: {prof.user_color ||
                      '#000'}">
                    {userInits(prof)}
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      </div>
      <div class="flex">
        <div class="flex-none w-20 text-gray-500">Shipping:</div>
        <div class="text-black grow text-xs space-y-1">
          <div class="w-full flex">
            <div class="grow">
              <MapboxInput
                labelWidth="1rem"
                value={rfq.job.location}
                coordinates={rfq.job.location_coordinates}
                outerBorder={false}
                placeholder="Location"
                disabled={rfq.sent_at}
                on:input={updateAddress}>
                <div class="text-black" slot="label">
                  <LocationIcon />
                </div>
              </MapboxInput>
            </div>
            <button
              class="flex-none p-1 rounded hover:bg-gray-200"
              on:click={() => (showAdvancedOptions = !showAdvancedOptions)}>
              <EllipsisIcon />
            </button>
          </div>
          {#if showAdvancedOptions}
            <div class="w-full pl-6">
              <BooleanInput
                label="Liftgate"
                labelOnRight
                outerBorder={false}
                value={rfq.job.data.shipping_options?.liftgate_delivery}
                on:input={(e) => updateJobData("shipping_options.liftgate_delivery", e.detail.value)}
              ></BooleanInput>
              <BooleanInput
                label="Appointment"
                labelOnRight
                outerBorder={false}
                value={rfq.job.data.shipping_options?.appointment_delivery}
                on:input={(e) => updateJobData("shipping_options.appointment_delivery", e.detail.value)}
              ></BooleanInput>
              <BooleanInput
                label="Inside delivery"
                labelOnRight
                outerBorder={false}
                value={rfq.job.data.shipping_options?.inside_delivery}
                on:input={(e) => updateJobData("shipping_options.inside_delivery", e.detail.value)}
              ></BooleanInput>
            </div>
          {/if}
          <div>
            {#if !rfq.job.location_postal_code}
              <div class="italic">
                <div class="text-black text-center w-4">&nbsp;</div>
                <div class="px-1">Choose an address to calculate shipping.</div>
              </div>
            {:else if computingShipping}
              <div class="flex gap-1 items-center italic text-gray-500">
                <div class="relative">
                  <div class="animate-spin">
                    <SpinnerIcon />
                  </div>
                </div>
                <div>Calculating shipping.</div>
              </div>
            {:else}
              <div class="flex gap-2 font-bold">
                <div class="text-black text-center w-4">$</div>
                <div class="px-1">
                  {#if shipping != null}
                    {(shipping / 100).toFixed(2)}
                  {:else}
                    &nbsp;
                  {/if}
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>
</Modal>
