function itemDimensions(items: Array<object>) {
  return items.reduce((acc, item) => {
    const width = item.cache?.width_in ?? 0;
    const height = item.cache?.height_in ?? 0;
    const weight = item.cache?.weight ?? 0;

    if (width && height) {
      if (item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          acc.push({ id: item.id, width, height, weight });
        }
      }
    }

    return acc;
  }, []);
}

export { itemDimensions };
