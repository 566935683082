<script>
  import { getContext } from "svelte";

  import EditIcon from "src/assets/icons/edit.svg";
  import EyeIcon from "src/assets/icons/show.svg";
  import TrashIcon from "src/assets/icons/trash.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import LibrarySection from "src/lib/sidebar/LibrarySection.svelte";
  import MakeupThumbnail from "src/lib/MakeupThumbnail.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";

  const org = getContext("org");

  let addModal;
  let newList = {
    name: "",
    currency: "USD",
  };

  let editModal;
  let editList = {
    id: null,
    name: "",
    currency: "USD",
  };

  let deleteModal;
  let previewModal;
  let library = null;
  let libraryTitle = null;

  export let disabled = false;

  function previewLibrary(org, list) {
    const categories = org.makeups.order
      .filter((id) => (list ? list.price_entries[id] : true))
      .filter((id) => org.makeups[id]?.published)
      .reduce((lib, id) => {
        const makeup = org.makeups[id];
        if (!makeup.category) {
          if (!lib._uncategorized) lib._uncategorized = [];
          lib._uncategorized.push(makeup);
        } else {
          if (!lib[makeup.category]) lib[makeup.category] = [];
          lib[makeup.category].push(makeup);
        }
        return lib;
      }, {});

    const u = categories._uncategorized;
    delete categories._uncategorized;
    if (Object.keys(categories).length === 0) {
      return [{ records: u || [] }];
    }

    const cats = Object.keys(categories).sort((a, b) => a.localeCompare(b));
    const lib = cats.map((cat) => ({
      title: cat,
      records: categories[cat],
    }));

    if (u) {
      lib.push({
        title: "Uncategorized",
        records: u,
      });
    }
    return lib;
  }

  function confirmAddProductList() {
    addModal.open();
  }

  function addProductList() {
    org.addProductList(newList);
  }

  function editProductList(list) {
    const { price_entries, ...listData } = list;
    editList = { ...listData };
    editModal.open();
  }

  function openPreview(list) {
    library = previewLibrary($org, list);
    libraryTitle = list ? list.name : "All Products";
    previewModal.open();
  }

  function confirmRemoveProductList(list) {
    editList = { ...list };
    deleteModal.open();
  }

  function deleteProductList() {
    const id = editList.id;
    org.removeProductList(id);
  }

  function updateProductList() {
    org.updateProductList(editList);
  }
</script>

<div>
  <SidebarTitle title="Product Lists" />
  <div class="px-6 text-xs py-2">
    <div class="space-y-2 mb-2">
      <div class="w-full flex items-center h-6 pl-item">
        <button class="flex gap-2 items-center" on:click={() => openPreview()}>
          <div>All Products</div>
          <div class="flex items-center gap-1">
            <button class="p-0.5 rounded hover:bg-gray-200">
              <EyeIcon />
            </button>
          </div>
        </button>
      </div>
      {#each $org.product_lists as list}
        <div class="w-full flex items-center h-6 justify-between pl-item">
          <button class="flex gap-2 items-center" {disabled} on:click={() => editProductList(list)}>
            <div>
              {list.name}
            </div>
            <div class="text-gray-400">
              {list.currency}
            </div>
            <div class="flex items-center gap-1">
              {#if !disabled}
                <button class="p-0.5 rounded hover:bg-gray-200">
                  <EditIcon />
                </button>
              {/if}
              <button
                class="p-0.5 rounded hover:bg-gray-200"
                on:click|stopPropagation={() => openPreview(list)}>
                <EyeIcon />
              </button>
            </div>
          </button>
          {#if !disabled}
            <button class="pl-buttons" on:click={() => confirmRemoveProductList(list)}>
              <TrashIcon />
            </button>
          {/if}
        </div>
      {/each}
      {#if !disabled}
        <div class="flex justify-end">
          <button class="font-bold" on:click={confirmAddProductList}> + New Product List </button>
        </div>
      {/if}
    </div>
  </div>
</div>

<Modal
  closeable
  on:confirm={() => addProductList()}
  bind:this={addModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Add", type: "confirm", style: "primary", disabled: !newList.name },
  ]}>
  <div slot="title">Add Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <TextInput label="Name" inputOnKeydown border bind:value={newList.name} />
    <SelectInput
      label="Currency"
      border
      bind:value={newList.currency}
      options={[
        { label: "USD", value: "USD" },
        { label: "CAD", value: "CAD" },
      ]} />
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => updateProductList()}
  bind:this={editModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <TextInput label="Name" inputOnKeydown border bind:value={editList.name} />
    <SelectInput
      label="Currency"
      border
      bind:value={editList.currency}
      options={[
        { label: "USD", value: "USD" },
        { label: "CAD", value: "CAD" },
      ]} />
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => deleteProductList()}
  bind:this={deleteModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Remove Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <div>Are you sure you want to remove this product list?</div>
    <div class="flex gap-2 px-4">
      <span class="font-bold">{editList.name}</span><span class="text-gray-500"> {editList.currency}</span>
    </div>
  </div>
</Modal>

<Modal closeable bind:this={previewModal} width="36rem">
  <div slot="title">Product List: {libraryTitle}</div>
  <div slot="content" class="overflow-y-auto">
    {#if !library?.length}
      <div class="italic text-xs">No products in list</div>
    {/if}
    {#each library as section}
      <LibrarySection {section}>
        <MakeupThumbnail slot="thumbnail" let:record {record} />
        <div slot="caption" let:record>
          {record.name}
        </div>
      </LibrarySection>
    {/each}
  </div>
</Modal>

<style lang="scss">
  .pl-buttons {
    @apply p-0.5 rounded;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .pl-item {
    .pl-buttons {
      display: none;
    }

    &:hover {
      .pl-buttons {
        display: block;
      }
    }
  }
</style>
